import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Link, useNavigate } from 'react-router-dom'


import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useDispatch, useSelector } from 'react-redux';
import { CgProfile } from 'react-icons/cg'
import profileIcon from '../../assests/imges/profileImage.svg'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dropdown({ isAuth, setSecure }) {
  const selector = useSelector(state => state?.profile?.profile)
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const handleLogout = (() => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to Logout.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              localStorage.clear()
              navigate('/')
              isAuth && isAuth();
              setSecure && setSecure()
            } catch (error) {

              localStorage.clear()
              navigate('/')
              isAuth && isAuth()
            }
          }
        },
        {
          label: 'No',
        }
      ]
    });

  })


  return (
    <Menu as="div" className="relative inline-block text-left mr-5">
      <div>
        {/* <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Profile
        </Menu.Button> */}
        <Menu.Button className="rounded-full    text-white  hover:bg-gray-50 overflow-hidden">
          <img className='w-[45px]' src={selector?.image ? selector?.image : profileIcon} alt="" />
          {/* <CgProfile /> */}
        </Menu.Button>

      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items style={{ background: "#383838" }} className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={'/profile'}
                  className={classNames(
                    active ? 'bg-dark-light text-white w-100 text-left' : 'text-gray-400',
                    'block px-2 py-2 text-sm w-100 text-left'
                  )}
                >
                  Profile
                </Link>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <Link
                  to={'/my-order'}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-2 py-2 text-sm'
                  )}
                >
                  My Orders
                </Link>
              )}
            </Menu.Item> */}
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? 'bg-dark-light text-white w-100 text-left' : 'text-gray-400',
                    'block px-2 py-2 text-sm w-100 text-left'
                  )}
                  onClick={handleLogout}
                >
                  Logout
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}