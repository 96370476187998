export const PhoneIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
        <circle cx="15" cy="15.1282" r="15" fill="#43B51E" />
        <g filter="url(#filter0_d_445_18)">
            <path d="M18.6154 21.1304C18.0924 21.1304 17.3578 20.9412 16.2578 20.3266C14.9201 19.5765 13.8854 18.8839 12.555 17.557C11.2722 16.275 10.648 15.445 9.77431 13.8552C8.78733 12.0602 8.95557 11.1193 9.14365 10.7172C9.36762 10.2365 9.69823 9.94908 10.1255 9.66375C10.3683 9.50473 10.6251 9.36841 10.8928 9.25652C10.9196 9.245 10.9446 9.23402 10.9668 9.22411C11.0994 9.16436 11.3003 9.07407 11.5549 9.17052C11.7247 9.23429 11.8764 9.36476 12.1137 9.59918C12.6005 10.0793 13.2657 11.1485 13.5111 11.6736C13.6759 12.0275 13.785 12.2612 13.7852 12.5232C13.7852 12.8299 13.6309 13.0665 13.4436 13.3218C13.4085 13.3698 13.3737 13.4156 13.34 13.4601C13.1361 13.728 13.0913 13.8054 13.1208 13.9436C13.1805 14.2215 13.6261 15.0485 14.3583 15.7791C15.0905 16.5097 15.8937 16.9271 16.1726 16.9866C16.3167 17.0174 16.3958 16.9708 16.6722 16.7597C16.7119 16.7294 16.7526 16.698 16.7952 16.6667C17.0808 16.4542 17.3064 16.3039 17.6059 16.3039H17.6075C17.8682 16.3039 18.0914 16.417 18.4611 16.6035C18.9433 16.8467 20.0447 17.5034 20.5278 17.9907C20.7627 18.2276 20.8937 18.3787 20.9578 18.5482C21.0542 18.8036 20.9634 19.0037 20.9042 19.1376C20.8943 19.1599 20.8833 19.1843 20.8718 19.2113C20.759 19.4786 20.6219 19.7349 20.4621 19.977C20.1773 20.403 19.8888 20.7328 19.4071 20.957C19.1597 21.074 18.889 21.1333 18.6154 21.1304Z" fill="#F2F2F2" />
        </g>
        <defs>
            <filter id="filter0_d_445_18" x="7" y="9.12817" width="16" height="16.0023" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_445_18" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_445_18" result="shape" />
            </filter>
        </defs>
    </svg>
}

export const NavigationIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
        <path d="M7.50015 8.46153C7.27913 8.46153 7.06717 8.54933 6.91089 8.70561C6.75461 8.86189 6.66681 9.07385 6.66681 9.29487V12.6282H8.33348V10.1282H10.8335V12.2115L13.7501 9.29487L10.8335 6.3782V8.46153H7.50015ZM10.5893 1.28903L18.8393 9.53903C18.9955 9.69531 19.0833 9.90723 19.0833 10.1282C19.0833 10.3492 18.9955 10.5611 18.8393 10.7174L10.5893 18.9674C10.433 19.1236 10.2211 19.2114 10.0001 19.2114C9.77918 19.2114 9.56725 19.1236 9.41098 18.9674L1.16098 10.7174C1.00476 10.5611 0.916992 10.3492 0.916992 10.1282C0.916992 9.90723 1.00476 9.69531 1.16098 9.53903L9.41098 1.28903C9.56725 1.13281 9.77918 1.04504 10.0001 1.04504C10.2211 1.04504 10.433 1.13281 10.5893 1.28903Z" fill="#699BF7" />
    </svg>
}

export const GloabIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path d="M11.9667 10C12.0333 9.45 12.0833 8.9 12.0833 8.33333C12.0833 7.76667 12.0333 7.21667 11.9667 6.66667H14.7833C14.9167 7.2 15 7.75833 15 8.33333C15 8.90833 14.9167 9.46667 14.7833 10M10.4917 14.6333C10.9917 13.7083 11.375 12.7083 11.6417 11.6667H14.1C13.2926 13.0569 12.0118 14.11 10.4917 14.6333ZM10.2833 10H6.38333C6.3 9.45 6.25 8.9 6.25 8.33333C6.25 7.76667 6.3 7.20833 6.38333 6.66667H10.2833C10.3583 7.20833 10.4167 7.76667 10.4167 8.33333C10.4167 8.9 10.3583 9.45 10.2833 10ZM8.33333 14.9667C7.64167 13.9667 7.08333 12.8583 6.74167 11.6667H9.925C9.58333 12.8583 9.025 13.9667 8.33333 14.9667ZM5 5H2.56667C3.36571 3.60601 4.64566 2.55124 6.16667 2.03333C5.66667 2.95833 5.29167 3.95833 5 5ZM2.56667 11.6667H5C5.29167 12.7083 5.66667 13.7083 6.16667 14.6333C4.64885 14.1097 3.37071 13.0564 2.56667 11.6667ZM1.88333 10C1.75 9.46667 1.66667 8.90833 1.66667 8.33333C1.66667 7.75833 1.75 7.2 1.88333 6.66667H4.7C4.63333 7.21667 4.58333 7.76667 4.58333 8.33333C4.58333 8.9 4.63333 9.45 4.7 10M8.33333 1.69167C9.025 2.69167 9.58333 3.80833 9.925 5H6.74167C7.08333 3.80833 7.64167 2.69167 8.33333 1.69167ZM14.1 5H11.6417C11.3809 3.96788 10.9946 2.97158 10.4917 2.03333C12.025 2.55833 13.3 3.61667 14.1 5ZM8.33333 0C3.725 0 0 3.75 0 8.33333C0 10.5435 0.877974 12.6631 2.44078 14.2259C3.2146 14.9997 4.13326 15.6135 5.1443 16.0323C6.15535 16.4511 7.23898 16.6667 8.33333 16.6667C10.5435 16.6667 12.6631 15.7887 14.2259 14.2259C15.7887 12.6631 16.6667 10.5435 16.6667 8.33333C16.6667 7.23898 16.4511 6.15535 16.0323 5.1443C15.6135 4.13326 14.9997 3.2146 14.2259 2.44078C13.4521 1.66696 12.5334 1.05313 11.5224 0.634337C10.5113 0.215548 9.42768 0 8.33333 0Z" fill="#888888" />
    </svg>
}

export const EmailIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 13.8333C1.83696 13.8333 1.20107 13.5699 0.732233 13.1011C0.263392 12.6323 0 11.9964 0 11.3333V3C0 2.33696 0.263392 1.70107 0.732233 1.23223C1.20107 0.763392 1.83696 0.5 2.5 0.5H14.1667C14.8297 0.5 15.4656 0.763392 15.9344 1.23223C16.4033 1.70107 16.6667 2.33696 16.6667 3V11.3333C16.6667 11.9964 16.4033 12.6323 15.9344 13.1011C15.4656 13.5699 14.8297 13.8333 14.1667 13.8333H2.5ZM4.6875 4.01667C4.60261 3.94418 4.50403 3.88949 4.39759 3.85584C4.29116 3.82218 4.17906 3.81026 4.06793 3.82076C3.9568 3.83127 3.84891 3.86399 3.75067 3.917C3.65243 3.97 3.56584 4.0422 3.49604 4.12931C3.42624 4.21642 3.37466 4.31666 3.34434 4.42409C3.31403 4.53152 3.30561 4.64395 3.31958 4.7547C3.33355 4.86544 3.36963 4.97226 3.42567 5.06879C3.48172 5.16533 3.55659 5.24962 3.64583 5.31667L6.77083 7.8175C7.21424 8.17251 7.76532 8.36594 8.33333 8.36594C8.90135 8.36594 9.45243 8.17251 9.89583 7.8175L13.0208 5.3175C13.1063 5.2491 13.1775 5.16454 13.2303 5.06864C13.283 4.97275 13.3164 4.86739 13.3285 4.75859C13.3406 4.64979 13.3311 4.53968 13.3006 4.43454C13.2701 4.32941 13.2192 4.2313 13.1508 4.14583C13.0824 4.06036 12.9979 3.9892 12.902 3.93642C12.8061 3.88363 12.7007 3.85024 12.5919 3.83817C12.4831 3.8261 12.373 3.83558 12.2679 3.86606C12.1627 3.89654 12.0646 3.94744 11.9792 4.01583L8.85417 6.51583C8.70636 6.63417 8.52267 6.69865 8.33333 6.69865C8.144 6.69865 7.9603 6.63417 7.8125 6.51583L4.6875 4.01583V4.01667Z" fill="#699BF7" />
    </svg>
}

export const ShareIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none">
        <ellipse opacity="0.2" cx="15" cy="16.1898" rx="15" ry="15.5577" fill="white" />
        <path d="M18.4091 13.9671H15.4545V17.9955C15.4545 18.1134 15.4067 18.2265 15.3214 18.3098C15.2362 18.3932 15.1206 18.44 15 18.44C14.8794 18.44 14.7638 18.3932 14.6786 18.3098C14.5933 18.2265 14.5455 18.1134 14.5455 17.9955V13.9671H11.5909C11.1691 13.9675 10.7647 14.1316 10.4665 14.4233C10.1682 14.7149 10.0005 15.1104 10 15.5229V20.8571C10.0005 21.2695 10.1682 21.665 10.4665 21.9567C10.7647 22.2484 11.1691 22.4124 11.5909 22.4129H18.4091C18.8309 22.4124 19.2353 22.2484 19.5335 21.9567C19.8318 21.665 19.9995 21.2695 20 20.8571V15.5229C19.9995 15.1104 19.8318 14.7149 19.5335 14.4233C19.2353 14.1316 18.8309 13.9675 18.4091 13.9671ZM15.4545 11.4842L16.9514 12.9478C17.0374 13.0276 17.1518 13.0715 17.2703 13.07C17.3888 13.0685 17.5021 13.0218 17.5859 12.9398C17.6697 12.8579 17.7175 12.7471 17.719 12.6312C17.7205 12.5153 17.6757 12.4034 17.594 12.3193L15.3213 10.0968C15.2361 10.0135 15.1205 9.96667 15 9.96667C14.8795 9.96667 14.7639 10.0135 14.6787 10.0968L12.406 12.3193C12.3243 12.4034 12.2795 12.5153 12.281 12.6312C12.2825 12.7471 12.3303 12.8579 12.4141 12.9398C12.4979 13.0218 12.6112 13.0685 12.7297 13.07C12.8482 13.0715 12.9626 13.0276 13.0486 12.9478L14.5455 11.4842V13.9671H15.4545V11.4842Z" fill="white" />
    </svg>
}

export const ShareIcon2 = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="32" viewBox="0 0 30 32" fill="none">
        < ellipse opacity="0.3" cx="15" cy="16.1898" rx="15" ry="15.5577" fill="black" />
        <path d="M18.4091 13.9671H15.4545V17.9955C15.4545 18.1134 15.4067 18.2265 15.3214 18.3098C15.2362 18.3932 15.1206 18.44 15 18.44C14.8794 18.44 14.7638 18.3932 14.6786 18.3098C14.5933 18.2265 14.5455 18.1134 14.5455 17.9955V13.9671H11.5909C11.1691 13.9675 10.7647 14.1316 10.4665 14.4233C10.1682 14.7149 10.0005 15.1104 10 15.5229V20.8571C10.0005 21.2695 10.1682 21.665 10.4665 21.9567C10.7647 22.2484 11.1691 22.4124 11.5909 22.4129H18.4091C18.8309 22.4124 19.2353 22.2484 19.5335 21.9567C19.8318 21.665 19.9995 21.2695 20 20.8571V15.5229C19.9995 15.1104 19.8318 14.7149 19.5335 14.4233C19.2353 14.1316 18.8309 13.9675 18.4091 13.9671ZM15.4545 11.4842L16.9514 12.9478C17.0374 13.0276 17.1518 13.0715 17.2703 13.07C17.3888 13.0685 17.5021 13.0218 17.5859 12.9398C17.6697 12.8579 17.7175 12.7471 17.719 12.6312C17.7205 12.5153 17.6757 12.4034 17.594 12.3193L15.3213 10.0968C15.2361 10.0135 15.1205 9.96667 15 9.96667C14.8795 9.96667 14.7639 10.0135 14.6787 10.0968L12.406 12.3193C12.3243 12.4034 12.2795 12.5153 12.281 12.6312C12.2825 12.7471 12.3303 12.8579 12.4141 12.9398C12.4979 13.0218 12.6112 13.0685 12.7297 13.07C12.8482 13.0715 12.9626 13.0276 13.0486 12.9478L14.5455 11.4842V13.9671H15.4545V11.4842Z" fill="white" />
    </svg >
}