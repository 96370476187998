import ReactCardFlip from 'react-card-flip'
import Styles from './qrCodePreview.module.scss'
import QRCode from 'react-qr-code'
import { PREVIEW_CARD_URL } from '../../../utils/strings/Strings';
import { BsTelephoneFill } from 'react-icons/bs';
import { FaMapMarkerAlt, FaEnvelope, FaGlobe } from 'react-icons/fa'
import CardBg1Back from '../../../assests/imges/card-bg/card-1-front.webp'
import CardBg1Front from '../../../assests/imges/card-bg/card-1-back.webp'
import CardBg2Front from '../../../assests/imges/card-bg/card-2-front.webp'
import CardBg2Back from '../../../assests/imges/card-bg/card-2-back.webp'
export default function QrCodePreview({
    rotate,
    formValues,
    textColor,
    cardColor,
    cardData,
    cardBackground,
    backgroundImage
}) {


    const frontBackground = (cardBackground) => cardBackground === 'bg2' ? CardBg1Front : cardBackground === 'bg1' ? CardBg2Front : ""
    const backBackground = (cardBackground) => cardBackground === 'bg2' ? CardBg1Back : cardBackground === 'bg1' ? CardBg2Back : ""
    return (
        <ReactCardFlip isFlipped={rotate} flipDirection="horizontal">
            <div>
                <div className={Styles.frontSideCard} style={{
                    background: `${cardColor ? cardColor : "black"}`,
                    backgroundImage: `url(${frontBackground(cardBackground)})`,
                    backgroundSize: 'cover',
                    backgroundRepeat:'no-repeat'


                }}>

                    <div className={Styles.leftSide}>
                        <div>
                            <QRCode
                                className={Styles.qrcode}
                                value={window.location.host.includes("localhost") ? "http://localhost:3000/preview-card/" + cardData?._id : PREVIEW_CARD_URL + cardData?._id}
                                style={{ borderColor: `${textColor ? textColor : "white"}`, borderWidth: 3 }}
                                fgColor={`${cardColor ? cardColor : "black"}`}
                                color={textColor ? textColor : "white"}
                                bgColor={textColor ? textColor : "white"}
                            />

                        </div>
                    </div>
                    <div className={Styles.rightSide}>
                        <div>
                            {/* <img src={cardData?.logo ? cardData?.logo : LogoImage} alt="" width={100} className={Styles.LogoImage} /> */}
                            <div style={{ color: `${textColor ? textColor : "white"}` }} className={Styles.nameContainer}>
                                <h3
                                    className="font-bold"
                                    style={{
                                        color: `${textColor ? textColor : "white"}`,
                                        fontSize: `${cardData?.titleFontSize ? cardData?.titleFontSize + "px" : cardData?.firstName?.length + cardData?.lastName?.length + cardData?.prefix?.length + cardData?.suffix?.length >= 18 ? "10px" : cardData?.firstName?.length + cardData?.lastName?.length + cardData?.prefix?.length + cardData?.suffix?.length >= 12
                                            ? "13px" : "20px"
                                            }`,
                                    }}
                                >
                                    {cardData?.firstName || cardData?.lastName ? (cardData?.prefix + " " + cardData?.firstName + " " + cardData?.lastName + " " + cardData?.suffix) : "Card Connect"}
                                </h3>
                                <span
                                    style={{
                                        fontSize: `${formValues?.name?.length >= 18 || formValues?.tagline?.length >= 20 ? "6px" : formValues?.name?.length >= 12 || formValues?.tagline?.length >= 16 ? "7px" : "8px"}`,
                                    }}
                                >
                                    {cardData?.designation ? cardData?.designation : "Connecting People"}
                                </span>
                                {cardBackground === 'bg1' && <div className={Styles.strip}></div>}
                            </div>
                            <div className={Styles.personalDetails} style={{ color: `${textColor ? textColor : "white"}`, opacity: 1 }}>
                                <div className={Styles.Item}>
                                    <div className={Styles.Icon}>
                                        <BsTelephoneFill size={10} />
                                    </div>

                                    <small>
                                        {JSON.parse(cardData?.phones[0])[0]?.value}
                                    </small>
                                </div>
                                <div className={Styles.Item}>
                                    <div className={Styles.Icon}>
                                        <FaMapMarkerAlt size={10} />
                                    </div>
                                    <small>
                                        {cardData?.location}
                                    </small>
                                </div>
                                <div className={Styles.Item}>
                                    <div className={Styles.Icon}>
                                        <FaEnvelope size={10} />
                                    </div>
                                    <small>
                                        {JSON.parse(cardData?.emails[0])[0]?.value}
                                    </small>
                                </div>
                                <div className={Styles.Item}>
                                    <div className={Styles.Icon}>
                                        <FaGlobe size={10} />
                                    </div>
                                    <small>
                                        {JSON.parse(cardData?.webs[0])[0]?.value}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
                <div className={Styles.backSideCard} style={{
                    background: `${cardColor ? cardColor : "linear-gradient(103deg, #111 7.89 %, #000 30.16 %, #333 66.33 %, #222 66.34 %, #000 92.32 %);"}`,
                    backgroundImage: `url(${backBackground(cardBackground)})`,                    
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'


                }}>
                    <div className=''>
                        {console.log(backgroundImage)}
                        {backgroundImage !== "null" && <img src={backgroundImage} alt='' />}
                    </div>
                </div>
            </div>
        </ReactCardFlip>
    )
}
