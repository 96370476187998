import React, { useEffect, useState } from 'react'
import './fileinput.css'

export default function FileInput({ value, isMultiple, isPreview, previewData, resetForm, onChange, inputName }) {
    const [dragActive, setDragActive] = React.useState(false);
    const [file, setFile] = React.useState([])
    const [preview, setPreview] = React.useState()
    const [name, setName] = useState([])
    // ref
    const inputRef = React.useRef(null);

    useEffect(() => {
        resetState()
    }, [resetForm])

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files) {
            var file = e.dataTransfer.files;
            setFile(file);
            handlePreview(file);
            value(file)
            onChange(e.dataTransfer)
        }
    };
    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files) {
            setFile(e.target.files)
            handlePreview(e.target.files[0])
            value(e.target.files)
            onChange(e)
        }
    };

    function handlePreview(file) {
        !isMultiple && setPreview(URL.createObjectURL(file));
    }

    for (let index = 0; index < file.length; index++) {
        if (name?.length !== file.length) {
            name.push(file[index].name)
        }

    }

    const resetState = () => {
        setFile([])
        setPreview()
        setName([])
    }

    return (
        <div onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} className='drag-box'>
            <input multiple={isMultiple} name={inputName} type='file' id='upload' accept="image/*.jpg,.png,.jpeg,.webp" ref={inputRef} style={{ display: "none" }} onChange={handleChange} />
            <label className='drag-container' htmlFor='upload' >
                <div className='drag-icon'>
                    {file === undefined || file.length === 0 ? <React.Fragment>
                        <span className="icon mdi mdi-file-upload"></span>
                        <p>Drag & drop to Upload</p>
                        <span>or browse</span>
                    </React.Fragment> : isPreview ? <div>
                        {!isMultiple ? <img src={preview} width={150} height={150} style={{ objectFit: 'contain' }} /> :
                            isMultiple ? <p>{name.length} Image</p> : <p>{file?.name}</p>
                        }
                    </div> : null}

                </div>
                {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>Drop here....</div>}
            </label>
        </div>
    )
}
