import Styles from "./styles.module.scss";
// import Logo from '../../../assests/imges/zeeqr-logo.svg'
// import Span from '../../../assests/imges/span.png'
import Nfccard_img from '../../../assests/imges/collection.png'
import Post from '../../../assests/imges/whyus-1.png'
import Qr_code from '../../../assests/imges/qr-code.png'
import Nfc from '../../../assests/imges/nfc.png'
import Zeeqrcard from '../../../assests/imges/image10.png'
import Ecofrindly_logo1 from '../../../assests/imges/ecofrindly_logo1.png'
import L from '../../../assests/imges/l.png'
import about_product_zeeqr_img1 from '../../../assests/imges/vector/cards-image.svg'
import { Link } from 'react-router-dom'

import NavbarZEEQR from '../navbar/NavbarZEEQR';
import FooterZEEQR from '../footer/FooterZEEQR';
import { SiteName } from '../../../utils/strings/Strings';
import GetNfcCard from "../components/getNfcCard/GetNfcCard";

function About() {
    return (
        <div className={Styles.wrapper}>
            <section>
                <div className={Styles.header_bgabout}>
                    <div className={Styles.header1}>


                        <NavbarZEEQR page={'about'} />
                        <div className={Styles.about_banner}>
                            <div className={Styles.innerPagebanner}>
                                <div className={Styles.inner_banner_text}>
                                    <h2 className={Styles.blog_banner_text1}>About Us</h2>
                                    <h2 className={Styles.blog_banner_text2}><a href="/home">Home</a> - About Us</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <div className={Styles.mainContainer}>
                <section className={Styles.about_product_zeeqr}>
                    <div className={Styles.container}>
                        <div className={Styles.about_product}>
                            <div className={Styles.rowabout_product}>
                                <div className={Styles.col}>
                                    <p className={Styles.about_product_p1} >About {SiteName}</p>
                                    <p className={Styles.about_product_p2}>Simplify Your Lives With
                                        Innovative Technology</p>     
                                    <p className={Styles.about_product_p3} style={{ color: "#D18C0D" }}>Our mission is to empower individuals and businesses with
                                        innovative technology solutions that simplify and enhance their daily lives. We have
                                        developed a range of digital card products that seamlessly integrate with your digital
                                        presence, making it easier than ever to share your contact details, social media profiles,
                                        and website with others.</p>
                                    <p className={Styles.about_product_p4} style={{ color: "#D18C0D" }}>
                                        {SiteName} solutions are customizable and come in a variety of designs, allowing you to
                                        showcase your brand's identity and values. It is our mission to help businesses and
                                        individuals succeed in the digital age.
                                    </p>
                                </div>                                
                                <div className={Styles.col}>
                                    <div className={Styles.ourVideo}>
                                        {/* <Link className={Styles.video}>
                                        <span></span>
                                    </Link> */}
                                        <img alt='' className={Styles.about_product_zeeqr_img} src={about_product_zeeqr_img1} />
                                    </div>

                                </div>                                   
                            </div>
                        </div>


                    </div>

                </section>

                <section>
                    <div className={Styles.container}>
                        <div className={Styles.zeeqr_Services}>
                            <p className={Styles.zeeqr_Services_p1}>Our Cards</p>
                            <p className={Styles.zeeqr_Services_p2}>Well-crafted Digital Business Cards</p>
                            <p className={Styles.zeeqr_Services_p3}>{SiteName} Smart Business Cards are available in three variants. You can
                                choose the material you want to craft your Digital Card </p>
                        </div>
                        <div className={Styles.zeeqr_Services_card}>
                            <img alt='' src={Nfccard_img} width={900} />
                        </div>
                    </div>

                    <div className={Styles.slider_btn}>
                        <div className="" style={{ textAlign: "center" }}>
                            <Link to={"/contact"} style={{ textDecoration: "none" }}> <button type="button" className={Styles.btn}>Get your Digital Card
                            </button></Link>
                        </div>
                    </div>
                </section>

                <section className={Styles.eco_friendly_nfc} style={{ padding: "10px" }} >
                    <div className={Styles.container}>

                        <div className={Styles.row_eco_friendly_nfc}>
                            <div className="col">
                                <img alt='' className={Styles.zeeqr_card_img} src={Post} />
                            </div>
                            <div className="col">
                                <p className={Styles.eco_friendly_zeeqr_p_1} style={{ margin: "10px" }} >About {SiteName}</p>
                                <p className={Styles.eco_friendly_zeeqr_p_2} style={{ margin: "10px" }}>Special Feature Digital Card</p>
                                <p className={Styles.eco_friendly_zeeqr_p_3_p} style={{ margin: "10px" }}>Our Digital and QR business cards are the ultimate networking tool for the digital age. With just a tap or scan, you can instantly share your contact details, website, and social media profiles with potential clients and colleagues.
                                </p>                            
                                <div className={Styles.row_ecofriendly} style={{ padding: "10px" }}>
                                    <img alt='' className={Styles.object_fit_contain} src={Qr_code} />
                                    <div className={Styles.qr_img_text}>
                                        <p className={Styles.eco_friendly_zeeqr_p_3}>QR (Quick Response) </p>
                                        <p className={Styles.eco_friendly_zeeqr_p_p}>is a two-dimensional barcode that can be scanned by a
                                            smartphone camera to quickly access information.</p>
                                    </div>
                                </div>
                                <div className={Styles.row_ecofriendly} style={{ padding: "10px" }}>
                                    <img className={Styles.object_fit_contain} src={Nfc} alt='' />
                                    <div className={Styles.qr_img_text}>
                                        <p className={Styles.eco_friendly_zeeqr_p_5}>Digital Card</p>
                                        <p className={Styles.eco_friendly_zeeqr_p_p}>is a wireless communication technology that enables
                                            data exchange between devices by bringing them close together.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>


                <section className={Styles.profiles} style={{ padding: "10px" }}>
                    <div className="" style={{ textAlign: "center" }}>
                        <h2 className={Styles.small} style={{ marginBottom: "10px" }}>Professionals <span>.</span> Entrepreneurs <span>.</span> freelancers <span>.</span>
                            individuals </h2>
                        <p className={Styles.nfc_text}>{SiteName} Digital Smart Business cards are useful for anyone who convenes a meeting and
                            wishes to make a network.</p>
                    </div>
                </section>


                <section className={Styles.eco_friendly}>
                    <div className={Styles.container}>
                        <div className={Styles.eco_friendly_zeeqr}>
                            <div className={Styles.eco}>
                                <div className={Styles.col}>
                                    <img className={Styles.zeeqr_card_img} src={Zeeqrcard} alt='' />
                                </div>
                                <div className={Styles.col}>
                                    <p className={Styles.eco_friendly_zeeqr_p_1} m-3>About {SiteName}</p>
                                    <p className={Styles.eco_friendly_zeeqr_p_2} m-3>Highlights Of {SiteName} Business Card </p>
                                    <div className={Styles.row_ecofriendly} m-3>

                                        <img src={Ecofrindly_logo1} alt='' />

                                        <p className={Styles.eco_friendly_zeeqr_p_3}>Eco-Friendly</p>
                                    </div>
                                    <p className={Styles.eco_friendly_zeeqr_p_4} m-3>Digital business cards are more environmentally friendly
                                        compared to traditional printed business cards. They eliminate the need for paper, ink, and
                                        other materials that contribute to waste and environmental pollution.</p>
                                    <div className={Styles.row_ecofriendly} m-3>
                                        <img src={L} alt='' />
                                        <p className={Styles.eco_friendly_zeeqr_p_5}>Cost-effective </p>
                                    </div>
                                    <p className={Styles.eco_friendly_zeeqr_p_6} m-3>Digital business cards can be cost-effective compared to
                                        traditional printed business cards. It can save money in the long run by eliminating the need to
                                        constantly print and distribute traditional business cards.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <div className={Styles.getNfcContainer}>
                    <div style={{ top: -50, position: 'relative' }}>
                        <div className="px-2">
                            <GetNfcCard />
                        </div>
                    </div>
                </div>
            </div>
            <FooterZEEQR />



        </div>
    )
}

export default About