import { useState } from 'react';
import { SiteName } from '../../../utils/strings/Strings'

import QrCodePreview from './QrCodePreview'
import { ColorPicker } from 'antd'
import { useLocation, useParams } from 'react-router-dom'
import RotateIcon from '../../../assests/imges/rotate.png'

import CardBg2Front from '../../../assests/imges/card-bg/card-2-front.webp'
import { FiTrash } from 'react-icons/fi'
import { toast } from 'react-hot-toast'
import { updateCard } from '../../../services/api/api-services'
import LogoBlack from '../../../assests/imges/logo-white.png'

export default function CreateQrCard() {
    const location = useLocation()
    const cardData = location.state
    const [material, setMaterial] = useState('PVC')
    const [materialcolor, setColor] = useState("")
    const [materialFavoriteColor, setFovouritecolor] = useState("BWhite")
    const [textColor, setTextColor] = useState("white");
    const [cardColor, setCardColor] = useState("black");
    const [rotate, setRotate] = useState(false)
    const [formValues, setFormValues] = useState()
    const [imgPre, setImgPre] = useState();
    const [cardBackground, setCardBackground] = useState("bg1")
    const [backgrounImage, setBackgroundImage] = useState(null)
    const [backgroundImagePreview, setBackgroundImagePreview] = useState(cardData?.backgroundImg ? cardData?.backgroundImg : LogoBlack)
    const { cardId } = useParams()
    const [isDisable, setDisable] = useState(false);
    const [error, setError] = useState("")
    const blackGradient = "linear-gradient(103deg, #111 7.89%, #000 30.16%, #333 66.33%, #222 66.34%, #000 92.32%)";
    const whiteGradient = "linear-gradient(103deg, #F3F1F1 7.89%, #F0F0F0 52.04%, #FFF 64.21%, #E5E5E5 65%, #DFDFDF 96.96%)";

    const handlePickerText = (col) => {
        const color = "#" + col.toHex()
        setTextColor(color)
    }
    const handlePickerCard = (col) => {
        const color = "#" + col.toHex()        
        setCardColor(color)
    }

    const handleLogoInput = (event) => {
        if (event.target.files && event.target.files[0]) {
            let image = URL.createObjectURL(event.target.files[0]);
            setBackgroundImage(event.target.files[0]);
            setBackgroundImagePreview(image);
            setError("");
        } else {
            return
        }
    }

    const removeItem = () => {
        document.getElementById("backgroundImage").value = "";
        setBackgroundImage(null);
        setBackgroundImagePreview(null);
    }

    const handleUpdateBackgroundImage = () => {
        if (backgrounImage === null) {
            setError("Please select a logo image");
            return
        }

        let body = new FormData();
        body.append("cardId", cardId);
        body.append("backgroundImg", backgrounImage);

        const toastId = toast.loading("Updating....");

        //return

        updateCard(body).then((response) => {
            setDisable(true)
            if (response?.err === false) {
                toast.dismiss(toastId)
                toast.success(response?.msg)
                setDisable(false)
            } else {
                toast.dismiss(toastId)
                toast.error(response?.msg)
                setDisable(false)
            }

        }).catch((error) => console.log(error));

    }

    return (
        <div className=' max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
            <div className='nk-content-inner'>
                <div className='row '>
                    <div className='col-xl-8 mb-5'>
                        <p className='text-4xl sm:text-6xl font-medium gradient-text'>Create your digital<br />
                            <span className='font-bold'>{SiteName}</span>
                        </p>
                        <p className='text-3xl font-medium color-theme'>Customize your Card</p>
                        <div className='flex flex-col'>
                            <div className='block sm:flex pt-4 mt-4 '>
                                <p className='text-sm w-60 font-medium color-theme'>Select your Card Material -</p>
                                <div className='flex gap-2'>
                                    <p className={`${material === "PVC" ? 'materialSelect' : 'material'}`} onClick={() => { setMaterial("PVC"); setColor("PBlack"); setFovouritecolor("BWhite"); setTextColor('#FFFFFF') }} >PVC</p>
                                    <p className={`${material === "Metal" ? 'materialSelect' : 'material'}`} onClick={() => { setMaterial("Metal"); setColor("MBlack"); setFovouritecolor("BGoldY"); setTextColor('#CB9536'); setCardBackground("") }}>Premium Metal</p>
                                    {/* <p className={`${material === "Luxury" ? 'materialSelect' : 'material'}`} onClick={() => { setMaterial("Luxury"); setColor("MGold"); setFovouritecolor("MGold"); setTextColor('#604A1D') }}>Luxury</p> */}
                                </div>
                            </div>
                            <div className='block sm:flex pt-4 mt-4'>
                                <p className='text-sm w-60 font-medium color-theme' >Select your Card Color -</p>
                                <div className='flex  gap-2'>
                                    {material === 'PVC' ?
                                        <>
                                            <p className={`${materialcolor === "PBlack" ? 'materialSelect' : 'material'}`} onClick={() => {
                                                setColor("PBlack");
                                                setFovouritecolor("BWhite");
                                                setTextColor('#FFFFFF');
                                                setCardColor(blackGradient)
                                            }}>Black</p>
                                            <p className={`${materialcolor === "PWhite" ? 'materialSelect' : 'material'}`} onClick={() => {
                                                setColor("PWhite");
                                                setFovouritecolor("WBlack");
                                                setTextColor('#000000')
                                                setCardColor(whiteGradient)
                                            }}>White</p>
                                            {/* <p className={`${materialcolor === "foil" ? 'materialSelect' : 'material'}`} onClick={() => setColor("foil")}>Foil</p>
                                            <p className={`${materialcolor === "gold" ? 'materialSelect' : 'material'}`} onClick={() => setColor("gold")}>Gold</p>
                                            <p className={`${materialcolor === "silver" ? 'materialSelect' : 'material'}`} onClick={() => setColor("silver")}>Silver</p> */}
                                            <p className={`${materialcolor === "custom" ? 'materialSelect' : 'material'}`} onClick={() => {
                                                setColor("custom");
                                                setFovouritecolor('');
                                                setTextColor('white');
                                                setCardColor('black')
                                            }}>Custom</p>
                                            {materialcolor === "custom" ?

                                                <ColorPicker
                                                    presets={[
                                                        {
                                                            label: 'Recommended',
                                                            colors: [
                                                                '#000000',
                                                                '#000000E0',
                                                                '#000000A6',
                                                                '#00000073',
                                                                '#00000040',
                                                                '#00000026',
                                                                '#0000001A',
                                                                '#00000012',
                                                                '#0000000A',
                                                                '#00000005',
                                                                '#F5222D',
                                                                '#FA8C16',
                                                                '#FADB14',
                                                                '#8BBB11',
                                                                '#52C41A',
                                                                '#13A8A8',
                                                                '#1677FF',
                                                                '#2F54EB',
                                                                '#722ED1',
                                                                '#EB2F96',
                                                                '#F5222D4D',
                                                                '#FA8C164D',
                                                                '#FADB144D',
                                                                '#8BBB114D',
                                                                '#52C41A4D',
                                                                '#13A8A84D',
                                                                '#1677FF4D',
                                                                '#2F54EB4D',
                                                                '#722ED14D',
                                                                '#EB2F964D',
                                                            ],
                                                        },
                                                        // {
                                                        //   label: 'Recent',
                                                        //   colors: [],
                                                        // },
                                                    ]}
                                                    value={cardColor ? cardColor : '#000000'}
                                                    onChange={handlePickerCard}
                                                    className='h-[40px] w-[40px]'
                                                    placement="bottomRight"
                                                />
                                                : ''
                                            }
                                        </>

                                        : material === "Metal" ?
                                            <>
                                                <p className={`${materialcolor === "MBlack" ? 'materialSelect' : 'material'}`} onClick={() => {
                                                    setColor("MBlack");
                                                    setFovouritecolor("BGoldY");
                                                    setTextColor('#CB9536')
                                                    setCardColor(blackGradient)
                                                }}>Black</p>
                                                <p className={`${materialcolor === "MWhite" ? 'materialSelect' : 'material'}`} onClick={() => {
                                                    setColor("MWhite");
                                                    setFovouritecolor("WGoldY")
                                                    setTextColor('#CB9536')
                                                    setCardColor(whiteGradient)

                                                }}>White</p>
                                            </>
                                            :
                                            <p className='materialSelect' onClick={() => { setColor("MGold"); setTextColor('#604A1D') }} >24K Gold</p>
                                    }

                                </div>

                            </div>
                            <div className='block sm:flex pt-4 mt-4'>
                                <p className='text-sm w-60 font-medium color-theme' >Select your Text Color -</p>
                                <div className='flex  gap-2'>
                                    {material === "Luxury" ? <p className='materialSelect' onClick={() => setFovouritecolor("MGold")} >Black  </p> :
                                        (material === "PVC" && materialcolor === "PWhite") || materialcolor === "PBlack" ?
                                            <>
                                                {material === "PVC" && materialcolor === "PBlack" ?
                                                    <>
                                                        <p className={`${materialFavoriteColor === "BWhite" ? 'materialSelect' : 'material'} `} onClick={() => { setFovouritecolor("BWhite"); setTextColor('#FFFFFF') }}>White </p>
                                                        <p className={`${materialFavoriteColor === "BGray" ? 'materialSelect' : 'material'} `} onClick={() => { setFovouritecolor("BGray"); setTextColor('#A3A3A3') }}>Grey </p>

                                                    </>
                                                    :
                                                    material === "PVC" && materialcolor === "PWhite" ?
                                                        <>
                                                            <p className={`${materialFavoriteColor === "WBlack" ? 'materialSelect' : 'material'} `} onClick={() => { setFovouritecolor("WBlack"); setTextColor('#000000') }}>Black </p>
                                                            <p className={`${materialFavoriteColor === "WBlue" ? 'materialSelect' : 'material'} `} onClick={() => { setFovouritecolor("WBlue"); setTextColor('#232A61') }}>Blue </p>
                                                        </>
                                                        : ''
                                                }
                                                <p className={`${materialFavoriteColor === "Gold" ? 'materialSelect' : 'material'}`} onClick={() => { setFovouritecolor("Gold"); setTextColor('#B07A31') }}>Gold </p>
                                                <p className={`${materialFavoriteColor === "Silver" ? 'materialSelect' : 'material'}`} onClick={() => { setFovouritecolor("Silver"); setTextColor('#606263') }}>Silver </p>
                                                {/* <p className={`${materialFavoriteColor === "Foil" ? 'materialSelect' : 'material'}`} onClick={() => { setFovouritecolor("Foil"); setTextColor('#5DB1E0') }}>Foil </p> */}
                                            </>
                                            :
                                            <>
                                                {material === "Metal" && materialcolor === "MBlack" ?
                                                    <>
                                                        <p className={`${materialFavoriteColor === "BGoldY" ? 'materialSelect' : 'material'} `} onClick={() => { setFovouritecolor("BGoldY"); setTextColor('#CB9536') }}>Gold </p>
                                                        <p className={`${materialFavoriteColor === "BGray" ? 'materialSelect' : 'material'} `} onClick={() => { setFovouritecolor("BGray"); setTextColor('#CCCCCB') }}>Silver </p>

                                                    </>
                                                    :
                                                    material === "Metal" && materialcolor === "MWhite" ?
                                                        <>
                                                            <p className={`${materialFavoriteColor === "WGoldY" ? 'materialSelect' : 'material'}`} onClick={() => { setFovouritecolor("WGoldY"); setTextColor('#CB9536') }}>Gold </p>
                                                            <p className={`${materialFavoriteColor === "WGray" ? 'materialSelect' : 'material'}`} onClick={() => { setFovouritecolor("WGray"); setTextColor('#CCCCCB') }}>Silver </p>
                                                        </>
                                                        : ''
                                                }
                                            </>
                                    }
                                    {materialcolor === "custom" ?
                                        <ColorPicker
                                            presets={[
                                                {
                                                    label: 'Recommended',
                                                    colors: [
                                                        '#000000',
                                                        '#000000E0',
                                                        '#000000A6',
                                                        '#00000073',
                                                        '#00000040',
                                                        '#00000026',
                                                        '#0000001A',
                                                        '#00000012',
                                                        '#0000000A',
                                                        '#00000005',
                                                        '#F5222D',
                                                        '#FA8C16',
                                                        '#FADB14',
                                                        '#8BBB11',
                                                        '#52C41A',
                                                        '#13A8A8',
                                                        '#1677FF',
                                                        '#2F54EB',
                                                        '#722ED1',
                                                        '#EB2F96',
                                                        '#F5222D4D',
                                                        '#FA8C164D',
                                                        '#FADB144D',
                                                        '#8BBB114D',
                                                        '#52C41A4D',
                                                        '#13A8A84D',
                                                        '#1677FF4D',
                                                        '#2F54EB4D',
                                                        '#722ED14D',
                                                        '#EB2F964D',
                                                    ],
                                                },
                                                // {
                                                //   label: 'Recent',
                                                //   colors: [],
                                                // },
                                            ]}
                                            value={textColor ? textColor : '#ffffff'}
                                            onChange={handlePickerText}
                                            className='h-[40px] w-[40px]'
                                            placement="bottomRight"
                                        />
                                        : ''
                                    }
                                </div>
                            </div>
                            {material === "PVC" && <div className='block sm:flex pt-4 mt-4'>
                                <p className='text-sm w-60 font-medium color-theme' >Select your Card Background -</p>
                                <div className='flex  gap-2'>
                                    <div onClick={() => { setCardBackground("bg1") }} className={cardBackground === 'bg1' ? 'materialSelect' : 'material'}>
                                        <img src={CardBg2Front} width={50} height={50} />
                                    </div>
                                    {/* <div onClick={() => { setCardBackground("bg2") }} className={cardBackground === 'bg2' ? 'materialSelect' : 'material'}>
                                    <img src={CardBg1Front} width={50} height={50} />
                                </div> */}
                                    {cardBackground && <div onClick={() => { setCardBackground("") }} className='material'>
                                        <p className='color-theme'>Remove</p>
                                    </div>}

                                </div>
                            </div>}
                            {material === "PVC" && <div className='block sm:flex mt-4 pt-4 items-center'>
                                <p className='text-sm w-60 font-medium color-theme' >Upload Logo -</p>
                                <div>
                                    <div className='flex gap-2 items-center h-auto'>
                                        <div className='form-control flex' >
                                            <input required type="file" id='backgroundImage' onChange={handleLogoInput} className='w-full' accept='image/png, image/jpg, image/webp, image/avif' />
                                            <button className='remove-button' onClick={removeItem}><FiTrash size={15} /></button>
                                        </div>
                                        <div className='upload-logo'>
                                            <button className='_btn_sm mt-0' disabled={isDisable} onClick={handleUpdateBackgroundImage}>Submit</button>
                                        </div>
                                    </div>
                                    <div>
                                        <small className='invalid-feedback d-block'>{error}</small>
                                    </div>

                                </div>

                            </div>}

                        </div>
                    </div>
                    <div className='col-xl-4' >
                        <QrCodePreview backgroundImage={backgroundImagePreview} cardBackground={cardBackground} cardData={cardData} rotate={rotate} material={material} materialcolor={materialcolor} materialFavoriteColor={materialFavoriteColor} imgPre={imgPre} formValues={formValues} textColor={textColor} cardColor={cardColor} />
                        <div onClick={() => setRotate(!rotate)} className='d-flex align-items-center justify-content-center py-3'>
                            <img src={RotateIcon} width={55} height={40} style={{filter:"invert(1)"}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
