import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { AiFillPhone } from 'react-icons/ai';
import { MdEmail } from 'react-icons/md';
import { FaLocationArrow } from 'react-icons/fa6'
import { BsGlobe2 } from 'react-icons/bs';
import SocialMediaSlider from '../components/social-media-slider/SocialMediaSlider';
import { AVATAR, CARD_BG } from '../../../utils/images/Images';
import { isValidLink } from '../components/isValidUrl';
export default function CardTemplate({ data, onClickShareButton, onClickAddToContactButton, isMobileView }) {
    // useEffect(() => {
    //     let vh = window.innerHeight * 0.01;
    // 
    //     document.getElementById("cardWrapper").style.setProperty('--vh', `${vh}px`);
    //     console.log(window.innerWidth);

    //     if (isMobileView || (window.innerWidth <= 600)) {
    //         document.getElementById("cardWrapper").style.setProperty('height', '650px');
    //     } else {
    //         // You may want to set a different height or reset to auto height if isMobileView is false
    //         document.getElementById("cardWrapper").style.setProperty('height', '530px');
    //     }
    // }, [isMobileView, window.innerWidth]); // Add isMobileView as a dependency to the useEffect

    return (
        <div className={styles.CardTemplate9}>
            <div className={styles.backgroundImage} style={{ backgroundImage: `url(${data?.logoImagePreview ? data?.logoImagePreview : CARD_BG})`, backgroundColor: data?.secondaryColor ? data?.secondaryColor : "#222" }} />
            <div className={styles.CardWrapper} id='cardWrapper'  >
                {/* <div className={styles.logoImage}>
                    <img src='https://freepngimg.com/save/9880-batman-logo-png/1600x1044' />
                </div> */}
                <div className={styles.userInfo}>
                    <img src={data?.userImagePreview ? data?.userImagePreview : AVATAR} />
                    <h2 style={{
                        color: data?.profileTextColor,
                        fontSize: `${data?.titleFontSize ? data?.titleFontSize + "px" : data?.firstName?.length + data?.lastName?.length + data?.prefix?.length + data?.suffix?.length >= 18
                            ? "14px"
                            : data?.firstName?.length + data?.lastName?.length + data?.prefix?.length + data?.suffix?.length >= 12
                                ? "16px"
                                : "18px"
                            }`,
                    }}>{data?.firstName || data?.lastName ? (data?.prefix + " " + data?.firstName + " " + data?.lastName + " " + data?.suffix) : "Card Connect"}</h2>
                    <h6 style={{ color: data?.profileTextColor, marginBottom: 5 }}>{data?.designation ? data?.designation : "Founder & Managing Partner"} (<i>{data?.company ? data?.company : "Company Name"}</i>)</h6>
                    <p>{data?.summary}</p>

                </div>
                {data?.socialMediaLinks?.length !==0 && <div className={styles.socialMediaContainer}>
                    <h4>Start the conversation</h4>
                    <SocialMediaSlider data={data} />
                </div>}
                <div className={styles.contactContainer}>
                    {data?.phones?.length > 0 && data?.phones[0].value !== "" &&
                        <div className={styles.strip}>
                            {data.phones.map((i, index) => <a target='_blank' href={"tel:" + i.value} key={index.toString()} className={styles.stripRow} rel="noreferrer">
                                <div className={styles.icon}>
                                    <AiFillPhone size={20} color='#000' />
                                </div>
                                <div className={styles.label}>
                                    {i.value}
                                </div>
                            </a>)}
                        </div>}
                    {data?.emails?.length > 0 && data?.emails[0].value !== "" &&
                        <div className={styles.strip}>
                            {data.emails.map((i, index) => <a target='_blank' href={"mailto:" + i.value} key={index.toString()} className={styles.stripRow} rel="noreferrer">
                                <div className={styles.icon}>
                                    <MdEmail size={20} color='#000' />
                                </div>
                                <p></p>
                                <div className={styles.label}>
                                    {i.value}
                                </div>
                            </a>)}
                        </div>
                    }
                    {data?.location &&
                        <div className={styles.strip}>
                            <a target='_blank' href={"https://maps.google.com/?q=" + data?.location.replaceAll(",", "")} className={styles.stripRow} rel="noreferrer">
                                <div className={styles.icon}>
                                    <FaLocationArrow size={20} color='#000' />
                                </div>
                                <p>{data?.location}</p>
                                <div className={styles.label}>
                                    {data?.location}
                                </div>
                            </a>
                        </div>
                    }
                    {data?.website?.length > 0 && data?.website[0].value !== "" &&
                        <div className={styles.strip}>
                            {data.website.map((i, index) => <a target='_blank' href={isValidLink(i.value)} key={index.toString()} className={styles.stripRow} rel="noreferrer">
                                <div className={styles.icon}>
                                    <BsGlobe2 size={20} color='#000' />
                                </div>
                                <p></p>
                                <div className={styles.label}>
                                    {isValidLink(i.value)}
                                </div>
                            </a>)}
                        </div>
                    }
                    {data?.customLinks?.length > 0 && data?.customLinks[0].value !== "" &&                                        
                        data.customLinks.map((i, index) => <div className={styles.strip}>
                            <a target='_blank' href={isValidLink(i.value)} key={index.toString()} className={styles.stripRow} rel="noreferrer">
                                <div className={styles.icon}>
                                    <BsGlobe2 size={20} color='#000' />
                                </div>
                                <div className={styles.label}>
                                    {isValidLink(i.value)}
                                </div>
                            </a>
                        </div>)}                                           
                </div>
            </div>
        </div>
    )
}
