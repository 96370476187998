import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { toast } from 'react-hot-toast';
import { changePassword } from '../../../../services/api/api-services';
import { errorStyle } from '../../../../utils/strings/Strings';
import { FiX } from 'react-icons/fi';
import { Modal } from 'antd';

function ChangePassword({ isShow, onClose, userId }) {

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [oldPasswordError, setOldPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)

    const [isDisabled, setDisabled] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@#$!%*?&]{8,}$/
    const handleFormValidation = (event) => {
        event.preventDefault()
        if (oldPassword === "" || newPassword === "" || confirmPassword === "" || !passRegex.test(newPassword)) {
            if (oldPassword === "") {
                setOldPasswordError(true)
            } else {
                setOldPasswordError(false)
            }
            if (newPassword === "") {
                setNewPasswordError(true)
            } else {
                setNewPasswordError(false)
            }
            if (confirmPassword === "") {
                setConfirmPasswordError(true)
            } else {
                setConfirmPasswordError(false)
            }
            return
        } else {
            handleFromSubmit()
        }
    }

    const handleFromSubmit = () => {

        let body = {
            "userId": userId,
            "oldPassword": oldPassword,
            "newPassword": newPassword
        }
        console.log(body);
        setDisabled(true)
        const toastId = toast.loading('Logging...');
        changePassword(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId);
                toast.success(response?.msg);
                setDisabled(false);
                resetState()
                onClose()
            } else {
                toast.dismiss(toastId);
                toast.error(response?.msg);
                setDisabled(false)
            }
        }).catch(err => console.log(err))

    }

    const resetState = () => {
        setOldPassword("")
        setNewPassword("")
        setConfirmPassword("")

        setOldPasswordError(false)
        setNewPasswordError(false)
        setConfirmPasswordError(false)
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event?.target?.value)
        if (newPassword === event?.target?.value) {
            setConfirmPasswordError(false)
        } else {
            setConfirmPasswordError(true)
        }
    }

    const handlePasswordShow = () => setShowPassword(!showPassword)
    const handleOldPasswordShow = () => setShowOldPassword(!showOldPassword)


    useEffect(() => {
        return () => {
            resetState()
        }
    }, [])

    return (


        <Modal
            title="Change Password"
            centered
            open={isShow}
            onOk={onClose && onClose}
            onCancel={onClose && onClose}
            okButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            cancelButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            className='theme-modal-wrapper'
        >
            <form onSubmit={handleFormValidation} autoComplete='off'>
                    <div className='form-group'>
                        <div className='form-label-group'>
                            <label className='form-label' htmlFor='default-01'>
                                Old Password
                            </label>
                        </div>
                        <div className='form-control-wrap'>
                            <a onClick={handleOldPasswordShow} className='form-icon form-icon-right passcode-switch top-0'>
                                <em className={`passcode-icon icon icon-show ni ${showOldPassword ? "ni-eye" : "ni-eye-off"}`} />
                            </a>
                            <input
                                type={showOldPassword ? "text" : 'password'}
                                style={oldPasswordError ? errorStyle : {}}
                                className='form-control'
                                id='default-01'
                                onChange={(event) => setOldPassword(event?.target?.value)}
                                placeholder='Enter old password'
                                autoComplete='off'
                                role='presentation'
                                value={oldPassword}
                            />
                            {oldPasswordError && <small className='invalid-feedback d-block'>Old password must be filled out.</small>}
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='form-label-group'>
                            <label className='form-label' htmlFor='default-01'>
                                New Password
                            </label>
                        </div>
                        <div className='form-control-wrap'>
                            <input
                                type={'password'}
                                style={newPasswordError ? errorStyle : {}}
                                className='form-control'
                                id='default-01'
                                onChange={(event) => setNewPassword(event?.target?.value)}
                                placeholder='Enter new password'
                                autoComplete='off'
                                role='presentation'
                                value={newPassword} />
                            {newPasswordError ? <small className='invalid-feedback d-block'>Password must be filled out.</small> : newPassword !== "" && !passRegex.test(newPassword) ? <small className='invalid-feedback d-block'>Minimum 8 characters,  at least 1 uppercase and lowercase letter , 1 number and 1 special character</small> : ""}
                        </div>

                    </div>
                    <div className='form-group'>
                        <div className='form-label-group'>
                            <label className='form-label' htmlFor='default-01'>
                                Confirm Password
                            </label>
                        </div>
                        <div className='form-control-wrap'>
                            <a onClick={handlePasswordShow} className='form-icon form-icon-right passcode-switch top-0'>
                                <em className={`passcode-icon icon icon-show ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
                            </a>
                            <input
                                type={showPassword ? "text" : 'password'}
                                style={confirmPasswordError ? errorStyle : {}}
                                className='form-control'
                                id='default-01'
                                onChange={handleConfirmPasswordChange}
                                placeholder='Enter confirm password'
                                autoComplete='off'
                                role='presentation'
                                value={confirmPassword} />
                            {confirmPasswordError && <small className='invalid-feedback d-block'>Confirm password do not match.</small>}
                        </div>
                    </div>
                <div className='btn-wrapper-gradient w-100'>
                    <button disabled={isDisabled} type='submit' className='_btn__ w-100'>
                            Submit
                        </button>
                    </div>
            </form>
        </Modal>

    );
}

export default ChangePassword;