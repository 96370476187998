import React, { useCallback, useRef, useState } from 'react'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';


import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import Styles from './styles.module.scss'
import { useSelector } from 'react-redux';
import { Dropdown } from 'antd';


const brandColorArray = (alpha) => [
    {
        id: 1,
        title: 'Facebook',
        color: `rgba(66, 103, 178, ${alpha})`
    },
    {
        id: 2,
        title: 'Instagram',
        color: `rgba(225, 48, 108, ${alpha})`
    },
    {
        id: 3,
        title: 'Twitter',
        color: `rgba(20, 23, 26, ${alpha})`
    },
    {
        id: 4,
        title: 'LinkedIn',
        color: `rgba(0, 119, 181, ${alpha})`
    },
    {
        id: 5,
        title: 'TikTok',
        color: `rgba(255, 0, 80, ${alpha})`
    },
    {
        id: 6,
        title: 'Snapchat',
        color: `rgba(255, 252, 178, ${alpha})`
    },
    {
        id: 7,
        title: 'Whatsapp',
        color: `rgba(37, 211, 102, ${alpha})`
    },
    {
        id: 8,
        title: 'Telegram',
        color: `rgba(0, 136, 204, ${alpha})`
    },
    {
        id: 9,
        title: 'Vimeo',
        color: `rgba(26, 183, 234, ${alpha})`
    },
    {
        id: 10,
        title: 'Youtube',
        color: `rgba(255, 0, 0, ${alpha})`
    },
    {
        id: 11,
        title: 'Wistia',
        color: `rgba(30, 113, 231, ${alpha})`
    },
    {
        id: 12,
        title: 'Twitch',
        color: `rgba(100, 65, 164, ${alpha})`
    },
    {
        id: 13,
        title: 'Discord',
        color: `rgba(114, 137, 218, ${alpha})`
    },
    {
        id: 14,
        title: 'Pinterest',
        color: `rgba(189, 8, 28, ${alpha})`
    },
    {
        id: 15,
        title: 'Yelp',
        color: `rgba(250, 93, 91, ${alpha})`
    },
    {
        id: 16,
        title: 'Paypal',
        color: `rgba(0, 121, 193, ${alpha})`
    },
    {
        id: 17,
        title: 'Venmo',
        color: `rgba(130, 188, 232, ${alpha})`

    },
    {
        id: 18,
        title: 'CashApp',
        color: `rgba(40, 191, 1, ${alpha})`
    },
    {
        id: 20,
        title: 'Shopify',
        color: `rgba(30, 215, 96, ${alpha})`
    },
    {
        id: 21,
        title: 'Github',
        color: `rgba(65, 65, 65, ${alpha})`
    },
    {
        id: 22,
        title: 'Dribbble',
        color: `rgba(234, 76, 137, ${alpha})`
    },
    {
        id: 23,
        title: 'Behance',
        color: `rgba(17, 102, 255, ${alpha})`
    },
    {
        id: 24,
        title: 'Custom URL',
        color: `rgba(66, 103, 178, ${alpha})`
    },
    {
        id: 25,
        title: 'Wikipedia',
        color: `rgba(172, 193, 210, ${alpha})`
    },
    {
        id: 26,
        title: 'Paytm',
        color: `rgba(4, 129, 192, ${alpha})`
    },
    {
        id: 27,
        title: 'BHIM UPI',
        color: `rgba(252, 208, 112, ${alpha})`
    },
    {
        id: 28,
        title: 'ChatGPT',
        color: `rgba(71, 162, 104, ${alpha})`
    },
    {
        id: 29,
        title: 'Google',
        color: `rgba(52, 168, 83, ${alpha})`
    },
]

export default function SocialMediaSlider({ isTruncate, data }) {

    // const cardData = useSelector(selectVCardData);

    const swiperRef = useRef(null);
    const [isDropdown, setDropdown] = useState(false)
    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, []);

    const domainRegex = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/

    const isSocialMedia = (value) => {
        const { link, title, } = value
        if (link.includes("www.")) {
            let url = link.replace("www.", "https://")
            return url
        } else if (!link.includes("https://") && !link.includes("http://")) {
            if (!domainRegex.test(link)) {
                let url = `https://${title.toLowerCase()}.com/${link}`
                return url
            } else {
                let url = `https://${link}`;
                return url
            }
        } else {
            return link
        }
    }

    const handleBackgrounColor = (data) => {
        let color = brandColorArray(0.1).filter(item => item.title === data?.title)[0].color
        return color
    }


    if (data?.socialMediaLinks?.length === 0) {
        return <div id='empty' />

    } else {
        return (
            isTruncate ? <div className={Styles.TruncateSocialContainer}
                id='TruncateSocialContainer'
                onMouseDown={() => {
                    setDropdown(true);
                    document.querySelector("#DropdownContainer").setAttribute("style", "transform: translateY(0px); opacity:1; z-index:9");
                }}
                onMouseOver={() => {
                    setDropdown(true);
                    document.querySelector("#DropdownContainer").setAttribute("style", "transform: translateY(0px); opacity:1; z-index:9");
                }}
                onMouseOut={() => {
                    setDropdown(false);
                    document.querySelector("#DropdownContainer").setAttribute("style", "transform: translateY(-50px); opacity:0; z-index:-1")
                }}>

                <div className={Styles.TruncateSocialItemContainer}>
                    <div className={Styles.TruncateSocialItemWrapper}>
                        {data?.socialMediaLinks.slice(0, 3)?.map((item, index) => {
                            return <div className={Styles.TruncateSocialItem}>
                                <img src={item.logo} />
                            </div>
                        })}
                    </div>
                    <div className={Styles.TruncateItemCount}>
                        <p>+{data?.socialMediaLinks?.length - 3}</p>
                    </div>
                </div>

                <div className={Styles.DropdownContainer} id='DropdownContainer'>
                    <div className={Styles.DropdownItemContainer}>
                        {data?.socialMediaLinks?.map((item, index) => {
                            return <a className={Styles.DropdownItem} href={isSocialMedia(item)} id="links" target="_blank" style={{ backgroundColor: handleBackgrounColor(item) }}>
                                <div className={Styles.Logo} >
                                    <img src={item?.logo} alt="" />
                                </div>
                                <p>{item?.title}</p>
                            </a>
                        })}
                    </div>
                </div>

            </div> :
                <div className={Styles.SocialMediaContainer}>
                    <span className={Styles.leftArrow}  >
                        {data?.socialMediaLinks?.length > 5 && <MdOutlineKeyboardArrowLeft size={13} onClick={handlePrev} />}
                    </span>
                    <div className={Styles.SliderContainer} >
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={7}
                            slidesPerView={5}
                        >
                            {data.socialMediaLinks.map((i, index) => <SwiperSlide>
                                <div className={Styles.slideItemDiv}>
                                    <a href={isSocialMedia(i)} id="links" target="_blank">
                                        <img src={i.logo} /> </a>
                                </div>
                            </SwiperSlide>)}

                        </Swiper>
                    </div>
                    <span className='right-arrow'>
                        {data.socialMediaLinks?.length > 5 && <MdOutlineKeyboardArrowRight size={13} onClick={handleNext} />}
                    </span>

                </div>
        )
    }
}
