import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapContainer from "../components/googleMap/GoogleMapContainer";
import { getGraphValues, getLocationDataByCardId, getLocationDataByUserId, getTotalCardViewCount } from "../../services/api/api-services";
import { toast } from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import ParseJwt from "../../services/parseJwt/parseJwt";
import RoyalTopStrip from '../../assests/imges/vector/royal-edge.svg'
import RoyalBottomStrip from '../../assests/imges/vector/royal-edge-bottom.svg'
import GetNfcCard from "../../components/landing/components/getNfcCard/GetNfcCard";
import Card from "./cards/Card";
import RoyalTopRightCornerImage from '../../assests/imges/vector/corner-1.svg'
import RoyalMiddleImage from '../../assests/imges/vector/middle.svg'
import ApexChart from "./chart/Graph";
import Calendar from 'react-calendar';
import Calender from "../../components/calender/Calender";
import moment from "moment";
import { OverlayTrigger, Tooltip, } from 'react-bootstrap';
export default function Analytics() {
    const [markers, setMarkers] = useState([]);
    const [data, setData] = useState([]);
    const [isEmpty, setEmpty] = useState(false)
    const { cardId } = useParams();
    const [perPage, setParPage] = useState(10);
    const [offset, setOffset] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [totalViewCount, setTotalViewCount] = useState(0);
    const { state } = useLocation()
    const [activeTab, setActiveTab] = useState("tab2")
    const dataFetchedRef = useRef(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [openStartDate, setOpenStartDate] = useState(false)
    const [openEndDate, setOpenEndDate] = useState(false);
    const [graphData, setGraphData] = useState([])
    const [userId, setUserId] = useState("")
    const [last7Days, setLast7Days] = useState([]);
    const [last30Days, setLast30Days] = useState([]);
    const [totalLength, setTotalLength] = useState(0);

    const handleGetLocationData = (userId) => {
        getLocationDataByUserId(userId).then((response) => {
            if (response.err === false) {
                if (response?.card?.length !== 0) {
                    // response?.card.forEach((ele) => {
                    //     data.push(...ele?.views)
                    // })
                    setTotalLength(response?.card?.length)
                    setEmpty(false)
                    // setTotalViewCount(response?.count)
                    response?.card?.forEach((element, index) => {
                        element?.views.forEach((location,) => {
                            location?.location?.forEach((cord) => {
                                if (markers?.length > 0) {
                                    if (markers[index]?.lat !== cord?.longitude) {
                                        markers.push({
                                            lat: cord.longitude,
                                            lng: cord?.latitude
                                        })
                                    }
                                } else {
                                    markers.push({
                                        lat: cord.longitude,
                                        lng: cord?.longitude
                                    })
                                }
                            })
                        })
                    });
                } else {
                    setEmpty(true)
                }
            } else {
                toast.error(response?.msg);
            }
        }).catch((err) => console.log(err))
    }

    const getUserId = () => {
        let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
        handleGetLocationData(decodedToken?._id._id);
        setUserId(decodedToken?._id._id)
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getUserId()
    }, [getUserId])

    const handlePageChange = (pageNumber) => {
        if (totalLength > activePage * perPage) {
            setActivePage(pageNumber);
            setOffset(pageNumber * perPage)
        }

    }

    const handleTabs = (tab) => {
        setActiveTab(tab)
    }

    const handleCalender = (type) => {
        if (type === 'start') {
            setOpenStartDate(!openStartDate)
        } else {
            setOpenEndDate(!openEndDate)
        }
    }

    const onChangeDate = (type, date) => {
        if (type === 'start') {
            setStartDate(moment(date).format("yyyy-MM-DD"))
        } else {
            setEndDate(moment(date).format("yyyy-MM-DD"))
        }

    }



    const getGraphData = useCallback(() => {
        setTotalViewCount(0)
        if (startDate && endDate && userId) {
            let body = {
                "userId": userId,
                "startDate": startDate,
                "endDate": endDate
            }
            getGraphValues(body).then((response) => {
                setGraphData(response);
                if (response?.getViewCountResult?.length !== 0) {
                    response?.getViewCountResult?.forEach((element) => {
                        setTotalViewCount(prevCount => prevCount + element?.totalViews);
                    });
                }
                if (response?.getByFilterResult?.length !== 0) {
                    setData(response?.getByFilterResult)
                }
            }).catch((err) => console.log(err))
        }
    }, [startDate, endDate, userId])

    function getLast7Days() {
        const today = new Date();
        const last7Days = [];

        for (let i = 6; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);

            // Format the date as "YYYY-MM-DD" (e.g., "2023-01-15")
            const formattedDate = date.toISOString().split('T')[0];
            last7Days.push(formattedDate);
        }

        return last7Days;
    }
    function getLast30Days() {
        const today = new Date();
        const last30Days = [];

        for (let i = 29; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);

            // Format the date as "YYYY-MM-DD" (e.g., "2023-01-15")
            const formattedDate = date.toISOString().split('T')[0];
            last30Days.push(formattedDate);
        }

        return last30Days;
    }

    useEffect(() => {
        getGraphData()
    }, [startDate, endDate, getGraphData])

    useEffect(() => {
        const last7Days = getLast7Days();
        const last30Days = getLast30Days();
        if (last7Days.length !== 0 && activeTab === "tab1") {
            setStartDate(last7Days[0]);
            setEndDate(last7Days[6])
        }
        if (last30Days.length !== 0 && activeTab === "tab2") {
            setStartDate(last30Days[0]);
            setEndDate(last30Days[29]);
        }
    }, [last7Days, last30Days, activeTab, startDate, endDate])


    const Tooltips = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id} className='tooltip-bg-dark' > {title}</Tooltip>}>
            <div>{children}</div>
        </OverlayTrigger>
    );

    return (

        <div className="nk-content-inner pt-0">
            <div className="nk-content-body">
                <div className="nk-ecwg nk-ecwg7 h-100">
                    <div className="flex-grow-1">
                        {markers.length > 0 && <div className="nk-ecwg7-ck" style={{ height: 591 }}>
                            <GoogleMapContainer markers={markers} />
                        </div>}
                    </div>
                </div>
                <div className="rounded-container">
                    <div className="flex justify-center" style={{ marginTop: -33 }}>
                        <img src={RoyalTopStrip} />
                    </div>
                    <div className="position-absolute w-full  h-full pointer-events-none z-0">
                        <div className="max-w-8xl mx-auto px-2 sm:px-6 lg:px-8 position-relative">
                            <img src={RoyalTopRightCornerImage} className="RoyalTopRightCornerImage" />
                            <img src={RoyalMiddleImage} className="RoyalMiddleImage" />
                        </div>
                    </div>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8" style={{ marginTop: 150 }}>
                        <div className="nk-block">
                            <div className="row g-3">
                                <div className="col-lg-4 mb-5">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="nk-block-head nk-block-head-sm mb-3">
                                                <div className="nk-block-between">
                                                    <div className="nk-block-head-content">
                                                        <h3 className="nk-block-title page-title gradient-text">Dashboard</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex align-center analytics-tabs mb-4">
                                                <div className={`analytics-tab ${activeTab === 'tab1' && 'active'}`} onClick={() => handleTabs("tab1")}>
                                                    <span>Last 7 days</span>

                                                </div>
                                                <div className={`analytics-tab ${activeTab === 'tab2' && 'active'}`} onClick={() => handleTabs("tab2")}>
                                                    <span>Last 30 days</span>
                                                </div>
                                                <div className={`analytics-tab custom flex ${activeTab === 'tab3' && 'active'}`} onClick={() => {
                                                    handleTabs("tab3");
                                                    handleCalender("start");
                                                }}>
                                                    <span className="mr-4">Custom</span>
                                                    <div className="flex">
                                                        <Tooltips title={"Choose date"}>
                                                            <label>
                                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M14 7V12.4444C14 12.857 13.8361 13.2527 13.5444 13.5444C13.2527 13.8361 12.857 14 12.4444 14H1.55556C1.143 14 0.747335 13.8361 0.455612 13.5444C0.163888 13.2527 0 12.857 0 12.4444V7H14ZM10.1111 0C10.3174 0 10.5152 0.0819442 10.6611 0.227806C10.8069 0.373667 10.8889 0.571498 10.8889 0.777778V1.55556H12.4444C12.857 1.55556 13.2527 1.71944 13.5444 2.01117C13.8361 2.30289 14 2.69855 14 3.11111V5.44444H0V3.11111C0 2.69855 0.163888 2.30289 0.455612 2.01117C0.747335 1.71944 1.143 1.55556 1.55556 1.55556H3.11111V0.777778C3.11111 0.571498 3.19306 0.373667 3.33892 0.227806C3.48478 0.0819442 3.68261 0 3.88889 0C4.09517 0 4.293 0.0819442 4.43886 0.227806C4.58472 0.373667 4.66667 0.571498 4.66667 0.777778V1.55556H9.33333V0.777778C9.33333 0.571498 9.41528 0.373667 9.56114 0.227806C9.707 0.0819442 9.90483 0 10.1111 0Z" />
                                                                </svg>
                                                            </label>
                                                        </Tooltips>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-9 col-sm-6 col-xm-12">
                                                    <Card count={totalViewCount} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 mb-lg-0 mb-5">
                                    <ApexChart data={graphData} startDate={startDate} endDate={endDate} activeTab={activeTab} />
                                </div>

                                {/* Card View by city */}
                                <div className="col-md-12">

                                    <div className="table-responsive">

                                        <div className="table position-relative z-10">

                                            <table className='nowrap nk-tb-list nk-tb-ulist table-dark'>
                                                <thead>
                                                    <tr className='nk-tb-item nk-tb-head'>
                                                        <th className='nk-tb-col' style={{ borderRight: "1px solid #707070", minWidth: 200 }}>
                                                            <span className='sub-text'>City</span>
                                                        </th>
                                                        <th className='nk-tb-col' style={{ borderRight: "1px solid #707070", minWidth: 200 }}>
                                                            <span className='sub-text'>State/Province</span>
                                                        </th>
                                                        <th className='nk-tb-col' style={{ borderRight: "1px solid #707070", minWidth: 200 }}>
                                                            <span className='sub-text'>Country</span>
                                                        </th>
                                                        <th className='nk-tb-col'>
                                                            <span className='sub-text'>Card&nbsp;Views</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.length > 0 ? data?.slice(offset, offset + perPage).map((item, index) => (
                                                        <tr className='nk-tb-item' key={index.toString()}>
                                                            <td className='nk-tb-col'>
                                                                <span className="tb-lead text-capitalize text-dim-regular">{item?.city}</span>
                                                            </td>
                                                            <td className='nk-tb-col'>
                                                                <span className="tb-lead text-capitalize text-dim-regular">{item?.state}</span>
                                                            </td>
                                                            <td className='nk-tb-col'>
                                                                <span className="tb-lead text-capitalize text-dim-regular">{item?.region}</span>
                                                            </td>
                                                            <td className='nk-tb-col'>
                                                                <span className="tb-lead text-capitalize text-dim-regular">{item?.totalViews}</span>
                                                            </td>
                                                        </tr>
                                                    )) : isEmpty ? <tr><td colSpan={4} style={{ textAlign: 'center' }} className='py-5'>No data found!</td></tr> : <tr><td colSpan={4} style={{ textAlign: 'center' }} className='py-5'>Loading....</td></tr>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div className='d-flex align-items-center justify-content-center my-4'>
                                                <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={10}
                                                    totalItemsCount={totalLength}
                                                    pageRangeDisplayed={5}
                                                    onChange={handlePageChange}
                                                    innerClass='pagination'
                                                    itemClass='page-item'
                                                    linkClass='page-link'
                                                />
                                            </div> */}
                                </div>
                                {/* Card Location */}

                            </div>
                        </div>
                    </div>
                </div>
                <div className='nfc-spacing'>
                    <img src={RoyalBottomStrip} />
                    <GetNfcCard />
                </div>
                <Calender isVisible={openStartDate} onClose={() => handleCalender("start")} onChange={(type, value) => onChangeDate(type, value)} startDate={startDate} endDate={endDate} />
            </div>
        </div>
    );
}
