import React, { memo, useCallback, useEffect, useId, useLayoutEffect, useState } from 'react'
import JsxParser from 'react-jsx-parser'

import CardSetup from './card-setup/CardSetup'
import CardDesign from './card-design/CardDesing';
import CardLaunch from './card-launch/CardLaunch';
import { useLocation, useParams, useSearchParams, } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectVCardData, vCardUpdate } from '../../../../../redux/action/cardData';
import ParseJwt from '../../../../../services/parseJwt/parseJwt';
import { toast } from 'react-hot-toast';
import { createCardColor, createCardSetup, getCardById, getTemplateById, updateCard } from '../../../../../services/api/api-services';
import Output from '../digital-business-card-output/Output';
import { vCardData as vCardSetup, vCardDataDesign, vCardDataAdditional } from '../../../../../redux/action/cardData';
import Loader from '../../../../components/shared/Loader';

function CardDetailsLayout() {
  const { state } = useLocation();
  const [key, setKey] = useState('step1');
  const vCardData = useSelector(selectVCardData)
  const [userId, setUserId] = useState();
  const { layoutId, cardId } = useParams();
  const [logo, setLogo] = useState(null)
  const [profileImage, setProfileImage] = useState("https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80")
  const [isOutputShow, setOutput] = useState(false)
  const [template, setTemplate] = useState([])
  const [isLoaded, setLoaded] = useState(false);
  const [previewCardId, setPreviewCardId] = useState("")

  const dispatch = useDispatch()
  // handle top tabs
  const handleTabs = (step) => {
    setKey(step)
  }
  // get user id by token
  const getUserId = () => {
    let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
    setUserId(decodedToken?._id._id);
  }

  // get template or layout by id
  const handleGetTemplateById = (layoutId) => {
    getTemplateById(layoutId).then((response) => {
      if (response?.err === false) {
        setTemplate(response?.template)
        handleWriteCss(response?.template?.css);
        setLoaded(true)
      } else {
        toast.error("Card template not loaded!")
      }

    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    getUserId()
    if (state?.isUpdate === undefined) {
      handleGetTemplateById(layoutId)
    } else {
      if (cardId !== undefined) {
        handleGetCardData(cardId)
      }
    }
    return () => {

    }
  }, [])

  //write css in the header
  const handleWriteCss = (css) => {
    let head = document.head || document.getElementsByTagName('head')[0]
    let style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  }

  // handle all value submit to the api
  const handleSubmitForm = () => {

    let body = new FormData();
    body.append("userId", userId);
    body.append("templateId", layoutId)
    body.append("firstName", vCardData?.firstName);
    body.append("lastName", vCardData?.lastName);
    body.append("prefix", vCardData?.prefix);
    body.append("suffix", vCardData?.suffix);
    body.append("designation", vCardData?.designation);
    body.append("department", vCardData?.department);
    body.append("pronouns", vCardData?.pronouns);
    body.append("company", vCardData?.company);
    body.append("summery", vCardData?.summary);
    body.append("location", vCardData?.location);
    body.append("profilePhoto", profileImage);
    body.append("logo", logo);
    body.append("phones", JSON.stringify(vCardData?.phones));
    body.append("emails", JSON.stringify(vCardData?.emails));
    body.append("webs", JSON.stringify(vCardData?.website));
    body.append("customFields", JSON.stringify(vCardData?.customLinks));
    body.append("socialLink", JSON.stringify(vCardData?.socialMediaLinks));
    body.append("primaryColor", vCardData?.primaryColor);
    body.append("secondaryColor", vCardData?.secondaryColor);
    body.append("profileTextColor", vCardData?.profileTextColor);
    body.append("font", vCardData?.fontFamily);
    body.append("fontSize", vCardData?.titleFontSize);
    body.append("isDownload", vCardData?.isDownload);
    body.append("isLocation", vCardData?.isLocation);


    const toastId = toast.loading("Creating card....");

    createCardSetup(body).then((response) => {
      if (response?.err === false) {
        toast.dismiss(toastId)
        toast.success(response?.msg)
        setPreviewCardId(response?.created?._id)
        // console.log(response);
        setOutput(true)
      } else {
        toast.dismiss(toastId)
        toast.error(response?.msg)
      }

    }).catch((error) => console.log(error));

  }

  const convertJson = (json) => {
    return json && JSON.parse(json);
  };

  const updateReduxStore = async (response) => {
    // setProfileImage(response?.card?.profilePhoto);
    // setLogo(response?.card?.logo)
    dispatch(vCardSetup({
      firstName: response?.card?.firstName,
      lastName: response?.card?.lastName,
      prefix: response?.card?.prefix,
      suffix: response?.card?.suffix,
      designation: response?.card?.designation,
      department: response?.card?.department,
      pronouns: response?.card?.pronouns,
      company: response?.card?.company,
      summary: response?.card?.summery,
      location: response?.card?.location,
      logoImagePreview: response?.card?.logo,
      userImagePreview: response?.card?.profilePhoto,
      logo: response?.card?.logo,
      userImage: response?.card?.profilePhoto,
      phones: convertJson(response?.card?.phones),
      emails: convertJson(response?.card?.emails),
      website: convertJson(response?.card?.webs),
      customLinks: convertJson(response?.card?.customFields),
      socialLinks: convertJson(response?.card?.socialLink),
    }));
    dispatch(vCardDataDesign({
      primaryColor: response?.card?.primaryColor,
      profileTextColor: response?.card?.profileTextColor,
      secondaryColor: response?.card?.secondaryColor,
      fontFamily: response?.card?.font,
      titleFontSize: response?.card?.fontSize
    }))
    dispatch(vCardDataAdditional({
      isLocation: response?.card?.isLocation,
      isDownload: response?.card?.isDownload,
    }))
    if (response?.card?.templateId) {
      handleGetTemplateById(response?.card?.templateId);
    }

  }

  // handle card data when update card
  const handleGetCardData = useCallback((cardId) => {
    getCardById(cardId).then((response) => {
      if (response?.err === false) {
        // handle get template by id when update        
        handleConvertJson(response)
      } else {
        // console.log(response);
      }
    }).catch((err) => console.log(err))
  }, [])

  const handleConvertJson = async (response) => {
    await updateReduxStore(response)
  }

  //handle update submit data
  const handleSubmitUpdateData = () => {
    let body = new FormData();
    body.append("cardId", cardId);
    // body.append("templateId", layoutId)
    body.append("firstName", vCardData?.firstName);
    body.append("lastName", vCardData?.lastName);
    body.append("prefix", vCardData?.prefix);
    body.append("suffix", vCardData?.suffix);
    body.append("designation", vCardData?.designation);
    body.append("department", vCardData?.department);
    body.append("pronouns", vCardData?.pronouns);
    body.append("company", vCardData?.company);
    body.append("summery", vCardData?.summary);
    body.append("location", vCardData?.location);
    body.append("profilePhoto", profileImage);
    body.append("logo", logo);

    body.append("phones", JSON.stringify(vCardData?.phones));
    body.append("emails", JSON.stringify(vCardData?.emails));
    body.append("webs", JSON.stringify(vCardData?.website));
    body.append("customFields", JSON.stringify(vCardData?.customLinks));
    body.append("socialLink", JSON.stringify(vCardData?.socialMediaLinks));
    body.append("primaryColor", vCardData?.primaryColor);
    body.append("secondaryColor", vCardData?.secondaryColor);
    body.append("profileTextColor", vCardData?.profileTextColor);
    body.append("font", vCardData?.fontFamily);
    body.append("fontSize", vCardData?.titleFontSize);
    body.append("isDownload", vCardData?.isDownload);
    body.append("isLocation", vCardData?.isLocation);
    const toastId = toast.loading("Updating....");

    //return

    updateCard(body).then((response) => {
      if (response?.err === false) {
        toast.dismiss(toastId)
        toast.success(response?.msg)
        setOutput(true);
        setPreviewCardId(cardId)
      } else {
        toast.dismiss(toastId)
        toast.error(response?.msg)
      }

    }).catch((error) => console.log(error));



  }

  if (!isLoaded) {
    return <Loader />
  } else {
    return (
      <div className='container-fluid'>
        <Output cardId={previewCardId} isShow={isOutputShow} onClose={() => setOutput(false)} innerHtml={template?.html} />
        <ul className='nav nav-tabs tab-container p-0'>
          <li className={`nav-item cursor-pointer ${key === 'step1' && "s-active"}`} onClick={() => handleTabs("step1")}>
            <span>Step1</span>
            <h4>Setup</h4>
          </li>
          <li className={`nav-item cursor-pointer ${key === 'step2' && "s-active"}`} onClick={() => handleTabs("step2")}>
            <span>Step2</span>
            <h4>Design</h4>
          </li>
          <li className={`nav-item cursor-pointer ${key === 'step3' && "s-active"}`} onClick={() => handleTabs("step3")}>
            <span>Step3</span>
            <h4>Launch</h4>
          </li>
          <li className='nav-item last-item d-flex justify-center align-items-center' onClick={state?.isUpdate ? handleSubmitUpdateData : handleSubmitForm}>
            <h4 className='text-white'>{state?.isUpdate ? "Update" : "Finish"}</h4>
          </li>
        </ul>
        <div className='row justify-content-between' style={{ marginTop: 80 }}>
          <div className='col-md-7'>
            {key === "step1" && isLoaded && <CardSetup logoImageData={(data) => setLogo(data)} userImageData={(data) => setProfileImage(data)} />}
            {key === "step2" && <CardDesign />}
            {key === "step3" && <CardLaunch />}
          </div>
          <div className='col-md-5 '>

            <div className='card-preview-container'>
              <div className='card-preview' id='preview'>
                {isLoaded && <JsxParser
                  showWarnings
                  bindings={{
                    socialMediaLinks: vCardData?.socialMediaLinks,
                    emails: state?.isUpdate ? vCardData?.emails : vCardData?.emails,
                    phones: vCardData?.phones,
                    name: vCardData?.firstName + " " + vCardData?.lastName,
                    customLinks: vCardData?.customLinks,
                    designation: vCardData?.designation,
                    userImage: vCardData?.userImagePreview,
                    logo: vCardData?.logoImagePreview,
                    location: vCardData?.location,
                    prefix: vCardData?.prefix,
                    suffix: vCardData?.suffix,
                    department: vCardData?.department,
                    pronouns: vCardData?.pronouns,
                    company: vCardData?.company,
                    summary: vCardData?.summary,
                    primaryColor: vCardData?.primaryColor,
                    profileTextColor: vCardData?.profileTextColor,
                    secondaryColor: vCardData?.secondaryColor,
                    titleFontSize: vCardData?.titleFontSize,
                    fontFamily: vCardData?.fontFamily,
                    website: vCardData?.website,
                  }}
                  jsx={template?.html}
                />}

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}


export default memo(CardDetailsLayout)