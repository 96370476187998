import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { deleteCard, getCardById, getMyCards, getTemplateById } from '../../services/api/api-services';
import { toast } from 'react-hot-toast';
import ParseJwt from '../../services/parseJwt/parseJwt';
import { useDispatch } from 'react-redux';
import { vCardData, vCardDataAdditional, vCardDataDesign } from '../../redux/action/cardData'
import { OverlayTrigger, Tooltip, } from 'react-bootstrap';
import { vCardData as vCardSetup } from '../../redux/action/cardData'
import { PREVIEW_CARD_URL } from '../../utils/strings/Strings';
import { FaTrash, FaQrcode, FaAddressCard, FaEdit } from 'react-icons/fa';
import { HiShare } from 'react-icons/hi';
import { RiEyeFill } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import QRcode from '../qrcode/Qrcode';
import ShareModal from '../../components/sharemodal/ShareModal';
import GetNfcCard from '../../components/landing/components/getNfcCard/GetNfcCard';
import RoyalBottomStrip from '../../assests/imges/vector/royal-edge-bottom.svg'
export default function MyCardTable() {
    const navigate = useNavigate()


    const [cards, setCards] = useState([]);
    const [totalLength, setTotalLength] = useState(0)
    const [perPage, setParPage] = useState(10);
    const [offset, setOffset] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [isEmpty, setEmpty] = useState(false)
    const dispatch = useDispatch()
    const [isOutputShow, setOutput] = useState(false)
    const [template, setTemplate] = useState([])
    const [previewCardId, setPreviewCardId] = useState("")
    const [viewCount, setView] = useState(0)
    const [isQrCodeShow, setIsQrCodeShow] = useState(false);
    const [qrcodeValue, setQrCodeValue] = useState("")
    const [isShare, setIsShare] = useState(false)
    const [shareId, setShareId] = useState([])
    const [isNameSort, setNameSort] = useState(false);
    const [isViewSort, setViewSort] = useState(false)
    const getUserId = () => {
        let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
        handleGetMyCard(decodedToken?._id._id)
    }

    const handleGetMyCard = (userId) => {
        getMyCards(userId).then((response) => {
            if (response?.err === false) {
                if (response?.card?.length !== 0) {
                    setCards(response?.card);
                    setTotalLength(response?.card?.length)
                    setEmpty(false)
                } else {
                    setEmpty(true)
                }
            } else {
                toast.error("Something went wrong!")
            }

        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        getUserId();
        return () => {
            handleReduxStateInitial()
        }
    }, []);


    const handlePageChange = (pageNumber) => {
        if (totalLength > activePage * perPage) {
            setActivePage(pageNumber);
            setOffset(pageNumber * perPage)
        }
    }

    const handleReduxStateInitial = () => {
        dispatch(vCardData({
            firstName: "",
            lastName: "",
            prefix: "",
            suffix: "",
            designation: "",
            department: "",
            pronouns: "",
            company: "",
            summary: "",
            phones: [],
            emails: [],
            website: [],
            customLinks: [],
            socialMediaLinks: [],
            location: "",
            userImage: null,
            logo: null,
            logoImagePreview: "https://cdn.pixabay.com/photo/2014/08/15/11/29/beach-418742_1280.jpg",
            userImagePreview: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80",
        }))
        dispatch(vCardDataAdditional({
            isDownload: false,
            isLocation: false
        }))
        dispatch(vCardDataDesign({
            primaryColor: "",
            profileTextColor: "",
            secondaryColor: "",
            fontFamily: "",
            titleFontSize: 16,
        }))
    }

    const handleCardDelete = (cardId) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this card.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const toastId = toast.loading("Deleting....")
                        deleteCard(cardId).then((response) => {
                            if (response?.err === false) {
                                toast.success(response?.msg);
                                toast.dismiss(toastId);
                                getUserId()
                            } else {
                                toast.error(response?.msg);
                                toast.dismiss(toastId)
                            }
                        }).catch((err) => console.log(err));
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }

    const Tooltips = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id} className='tooltip-bg-dark' > {title}</Tooltip>}>
            <div>{children}</div>
        </OverlayTrigger>
    );

    //write css in the header

    const handleWriteCss = (css) => {
        let head = document.head || document.getElementsByTagName('head')[0]
        let style = document.createElement('style');

        head.appendChild(style);

        style.type = 'text/css';
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
    }
    // get template or layout by id
    const handleGetTemplateById = (layoutId) => {
        getTemplateById(layoutId).then((response) => {
            if (response?.err === false) {
                setTemplate(response?.template)
                handleWriteCss(response?.template?.css);
                setOutput(true)
            } else {
                toast.error("Card template not loaded!")
            }

        }).catch((err) => console.log(err))
    }

    const convertJson = (json) => {
        return json && JSON.parse(json);
    };

    const updateReduxStore = async (response) => {
        dispatch(vCardSetup({
            firstName: response?.card?.firstName,
            lastName: response?.card?.lastName,
            prefix: response?.card?.prefix,
            suffix: response?.card?.suffix,
            designation: response?.card?.designation,
            department: response?.card?.department,
            pronouns: response?.card?.pronouns,
            company: response?.card?.company,
            summary: response?.card?.summery,
            location: response?.card?.location,
            logoImagePreview: response?.card?.logo,
            userImagePreview: response?.card?.profilePhoto,
            logo: response?.card?.logo,
            userImage: response?.card?.profilePhoto,
            phones: convertJson(response?.card?.phones),
            emails: convertJson(response?.card?.emails),
            website: convertJson(response?.card?.webs),
            customLinks: convertJson(response?.card?.customFields),
            socialLinks: convertJson(response?.card?.socialLink),
        }));
        dispatch(vCardDataDesign({
            primaryColor: response?.card?.primaryColor,
            profileTextColor: response?.card?.profileTextColor,
            secondaryColor: response?.card?.secondaryColor,
            fontFamily: response?.card?.font,
            titleFontSize: response?.card?.fontSize
        }))
        dispatch(vCardDataAdditional({
            isLocation: response?.card?.isLocation,
            isDownload: response?.card?.isDownload,
        }))
        if (response?.card?.templateId) {
            handleGetTemplateById(response?.card?.templateId);
        }

    }

    // handle card data when update card
    const handleGetCardData = useCallback((cardId, views) => {
        setPreviewCardId(cardId)
        setView(views)
        getCardById(cardId).then((response) => {
            if (response?.err === false) {
                // handle get template by id when update        
                handleConvertJson(response)
            } else {
                // console.log(response);
            }
        }).catch((err) => console.log(err))
    }, [])

    const handleConvertJson = async (response) => {
        await updateReduxStore(response)
    }

    const handleShareLink = (_id) => {
        setIsShare(true)
        setShareId(_id)
        // toast.success("Link copy successfully")
        // navigator.clipboard.writeText(PREVIEW_CARD_URL + _id)
    }

    const openNewWindow = (_id) => {
        if (window.location.host.includes("localhost")) {
            window.open("http://localhost:3000/preview-card/" + _id, '_blank');
        } else if (window.location.host.includes("192.168")) {
            window.open("http://192.168.29.94:3000/preview-card/" + _id, '_blank');
        }

        else {
            window.open(PREVIEW_CARD_URL + _id, '_blank');
        }

    }

    const handleShowMyCard = (item) => {
        navigate(`qr-code/${item?._id}`, { state: item })
    }

    const handleShowQrcode = (item) => {
        setQrCodeValue(item);
        setIsQrCodeShow(!isQrCodeShow)
    }


    const handleSortButton = (type) => {

        function compare1(a, b) {
            if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase()) {
                return -1;
            }
            if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase()) {
                return -1;
            }
            return 0;
        }

        function compare2(a, b) {
            if (a.count - b.count) {
                return -1;
            }
            if (a.count - b.count) {
                return 1;
            }
            return 0;
        }

        if (type === 'name') {
            setCards(cards.sort(compare1))
            setNameSort(!isNameSort)
        }
        if (type === 'views') {
            setCards(cards.sort(compare2))
            setViewSort(!isViewSort)
        }
    }
    return (
        <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>

            <div className='nk-content-inner'>
                <div className='nk-content-body'>
                    <div className='nk-block-head row gx-1'>
                        <div className='nk-block-head-content col-6 align-self-center'>
                            <h3 className="nk-block-title page-title gradient-text">My Cards ({totalLength})</h3>
                        </div>
                        <div className='col-6 text-end'>
                            <Link to={'/select-card'} className='btn-wrapper-gradient'>
                                <div className='_btn__'>Create new card</div>
                            </Link>
                        </div>
                    </div>
                    <div className='table-responsive'>
                        <div className='table'>
                            <table className='nowrap nk-tb-list nk-tb-ulist table-dark'>
                                <thead>
                                    <tr className='nk-tb-item nk-tb-head'>
                                        <th className='nk-tb-col' style={{ borderRight: "1px solid #707070", minWidth: 200 }}>
                                            <div className='flex flex-grow-1 justify-between align-center cursor-pointer' onClick={() => handleSortButton("name")}>
                                                <div className='sub-text'>Card</div>
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
                                                        <path d="M12 10.2748C12 10.4736 11.9258 10.6456 11.7773 10.7909L6.52734 15.9283C6.37891 16.0735 6.20312 16.1461 6 16.1461C5.79688 16.1461 5.62109 16.0735 5.47266 15.9283L0.222656 10.7909C0.0742188 10.6456 0 10.4736 0 10.2748C0 10.0761 0.0742188 9.90404 0.222656 9.75879C0.371094 9.61353 0.546875 9.54091 0.75 9.54091H11.25C11.4531 9.54091 11.6289 9.61353 11.7773 9.75879C11.9258 9.90404 12 10.0761 12 10.2748ZM12 5.87133C12 6.0701 11.9258 6.24211 11.7773 6.38736C11.6289 6.53262 11.4531 6.60524 11.25 6.60524H0.75C0.546875 6.60524 0.371094 6.53262 0.222656 6.38736C0.0742188 6.24211 0 6.0701 0 5.87133C0 5.67256 0.0742188 5.50055 0.222656 5.35529L5.47266 0.217881C5.62109 0.0726271 5.79688 0 6 0C6.20312 0 6.37891 0.0726271 6.52734 0.217881L11.7773 5.35529C11.9258 5.50055 12 5.67256 12 5.87133Z" fill={isNameSort ? "#666666" : "#fff"} />
                                                        <path d="M6.84756 15.3225C6.37901 15.7683 5.61807 15.7683 5.14952 15.3225L0.35152 10.7577C0.0066639 10.4296 -0.0945439 9.94105 0.0928779 9.5131C0.2803 9.08515 0.715118 8.80698 1.20242 8.80698H10.7984C11.282 8.80698 11.7205 9.08515 11.908 9.5131C12.0954 9.94105 11.9904 10.4296 11.6493 10.7577L6.85131 15.3225H6.84756Z" fill={!isNameSort ? "#666666" : "#fff"} />
                                                    </svg>

                                                </div>
                                            </div>
                                        </th>
                                        <th className='nk-tb-col' style={{ borderRight: "1px solid #707070", minWidth: 200 }}>
                                            <div className='flex flex-grow-1 justify-between align-center cursor-pointer' onClick={() => handleSortButton("views")}>
                                                <div className='sub-text'>Views</div>
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17" fill="none">
                                                        <path d="M12 10.2748C12 10.4736 11.9258 10.6456 11.7773 10.7909L6.52734 15.9283C6.37891 16.0735 6.20312 16.1461 6 16.1461C5.79688 16.1461 5.62109 16.0735 5.47266 15.9283L0.222656 10.7909C0.0742188 10.6456 0 10.4736 0 10.2748C0 10.0761 0.0742188 9.90404 0.222656 9.75879C0.371094 9.61353 0.546875 9.54091 0.75 9.54091H11.25C11.4531 9.54091 11.6289 9.61353 11.7773 9.75879C11.9258 9.90404 12 10.0761 12 10.2748ZM12 5.87133C12 6.0701 11.9258 6.24211 11.7773 6.38736C11.6289 6.53262 11.4531 6.60524 11.25 6.60524H0.75C0.546875 6.60524 0.371094 6.53262 0.222656 6.38736C0.0742188 6.24211 0 6.0701 0 5.87133C0 5.67256 0.0742188 5.50055 0.222656 5.35529L5.47266 0.217881C5.62109 0.0726271 5.79688 0 6 0C6.20312 0 6.37891 0.0726271 6.52734 0.217881L11.7773 5.35529C11.9258 5.50055 12 5.67256 12 5.87133Z" fill={isViewSort ? "#666666" : "#fff"} />
                                                        <path d="M6.84756 15.3225C6.37901 15.7683 5.61807 15.7683 5.14952 15.3225L0.35152 10.7577C0.0066639 10.4296 -0.0945439 9.94105 0.0928779 9.5131C0.2803 9.08515 0.715118 8.80698 1.20242 8.80698H10.7984C11.282 8.80698 11.7205 9.08515 11.908 9.5131C12.0954 9.94105 11.9904 10.4296 11.6493 10.7577L6.85131 15.3225H6.84756Z" fill={!isViewSort ? "#666666" : "#fff"} />
                                                    </svg>
                                                </div>
                                            </div>
                                        </th>
                                        <th className='nk-tb-col' >
                                            <div className='sub-text'></div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cards.length > 0 ? cards?.slice(offset, offset + perPage).map((item, index) => (
                                        <tr className='nk-tb-item' key={index.toString()}>
                                            <td className='nk-tb-col'>
                                                <div className='user-card'>
                                                    <div className='user-avatar bg-dim-primary'>
                                                        {item?.profilePhoto ? <img src={item?.profilePhoto} style={{ width: 38, height: 38, maxWidth: "unset", maxHeight: "unset", objectFit: 'cover' }} /> : <span className='text-uppercase'>{item?.firstName?.charAt(0)}{item?.lastName?.charAt(0)}</span>}
                                                    </div>
                                                    <div className='user-info'>
                                                        {/* <Link> */}
                                                        <span className='tb-lead'>{item?.firstName} {item?.lastName}</span>
                                                        {/* </Link> */}
                                                    </div>
                                                </div>
                                            </td>
                                            {/* <td className='nk-tb-col'>
                                                                <span className='tb-amount'>{PREVIEW_CARD_URL}{item?._id}</span>
                                                            </td> */}
                                            <td className='nk-tb-col'>
                                                <span>{item?.count}</span>
                                            </td>
                                            <td className='nk-tb-col'>
                                                <ul className='nk-tb-actions gx-1'>
                                                    <li className='nk-tb-action-hidden'>
                                                        <Tooltips title={"Analytics"}>
                                                            <Link className='btn btn-trigger btn-icon' to={`/analytics/${item?._id}`} state={{ cardData: item }}>
                                                                <em className="icon ni ni-bar-chart-fill"></em>
                                                            </Link>
                                                        </Tooltips>
                                                    </li>
                                                    <li className='nk-tb-action-hidden'>
                                                        <Tooltips title={"Open"} id={item?._id}>
                                                            <div className='btn btn-trigger btn-icon' onClick={() => openNewWindow(item?._id)}>
                                                                <RiEyeFill className='icon' />
                                                            </div>
                                                        </Tooltips>
                                                    </li>
                                                    <li className='nk-tb-action-hidden'>
                                                        <Tooltips title={"Share link"} id={item?._id}>
                                                            <div className='btn btn-trigger btn-icon' onClick={() => handleShareLink(item)}>
                                                                <HiShare className='icon' />
                                                            </div>
                                                        </Tooltips>
                                                    </li>

                                                    <li className='nk-tb-action-hidden'>
                                                        <Tooltips title={"Qr Code"} id={item?._id}>
                                                            <div className='btn btn-trigger btn-icon' onClick={() => handleShowQrcode(item)}>
                                                                <FaQrcode className='icon' />
                                                            </div>
                                                        </Tooltips>
                                                    </li>
                                                    <li className='nk-tb-action-hidden'>
                                                        <Tooltips title={"Show Cards"} id={item?._id}>
                                                            <div className='btn btn-trigger btn-icon' onClick={() => handleShowMyCard(item)}>
                                                                <FaAddressCard className='icon' />
                                                            </div>
                                                        </Tooltips>
                                                    </li>
                                                    <li className='nk-tb-action-hidden'>
                                                        <Tooltips title={"Edit"}>
                                                            <Link to={`update/${item?._id}`} state={{ isUpdate: true }} className='btn btn-trigger btn-icon'>
                                                                <FaEdit className='icon' />
                                                            </Link>
                                                        </Tooltips>
                                                    </li>
                                                    <li className='nk-tb-action-hidden'>
                                                        <Tooltips title={"Delete"} id={item?._id}>
                                                            <div className='btn btn-trigger btn-icon' onClick={() => handleCardDelete(item?._id)}>
                                                                <FaTrash className='icon' />
                                                            </div>
                                                        </Tooltips>
                                                    </li>

                                                    {/* <li className='nk-tb-action-hidden'>
                                                                        <Tooltips title={"Show"}>
                                                                            <Link className='btn btn-trigger btn-icon' target='_blank' to={`${PREVIEW_CARD_URL}${item?._id}`}>
                                                                                <em className='icon ni ni-eye-fill' />
                                                                            </Link>
                                                                        </Tooltips>
                                                                    </li> */}

                                                    {/* <li>
                                                                        <div className='drodown'>
                                                                            <a href='#' className='dropdown-toggle btn btn-icon btn-trigger' data-bs-toggle='dropdown'><em className='icon ni ni-more-h' /></a>
                                                                            <div className='dropdown-menu dropdown-menu-end'>
                                                                                <ul className='link-list-opt no-bdr'>
                                                                                    <li>
                                                                                <a href='#'><em className='icon ni ni-wallet-fill' /><span>Share wallet pass</span></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='#'><em className='icon ni ni-swap-alt-fill' /><span>Duplicate</span></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='#'><em className='icon ni ni-repeat' /><span>Change owner</span></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href='#'><em className='icon ni ni-exchange' /><span>Change organization</span></a>
                                                                            </li>
                                                                            <li className='divider' />
                                                                            <li>
                                                                                <a href='#'><em className='icon ni ni-template-fill' /><span>Mark as template</span></a>
                                                                            </li>
                                                                                    <li>
                                                                                        <a href='#' onClick={() => handleCardDelete(item?._id)}><em className='icon ni ni-trash-fill' /><span>Delete</span></a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </li> */}
                                                </ul>
                                            </td>
                                        </tr>
                                    )) : isEmpty ? <tr><td colSpan={5} style={{ textAlign: 'center' }} className='py-5'>No data found!</td></tr> : <tr><td colSpan={5} style={{ textAlign: 'center' }} className='py-5'>Loading....</td></tr>}
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-4'>
                            {/* <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={totalLength}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                innerClass='pagination'
                                                itemClass='page-item'
                                                linkClass='page-link'
                                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='nfc-spacing'>
                <img src={RoyalBottomStrip} />
                <GetNfcCard />
            </div>
            <QRcode isShow={isQrCodeShow} onClose={() => { setIsQrCodeShow(!isQrCodeShow); setQrCodeValue("") }} value={qrcodeValue} />
            <ShareModal isShow={isShare} onClose={() => setIsShare(!isShare)} shareId={shareId} />
        </div>

    )
}
