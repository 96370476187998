import { useCallback, useEffect, useRef, useState } from 'react';
import CardDetails from './CardDetails'
import { useDispatch, useSelector } from 'react-redux';
import { selectVCardData, vCardDataAdditional, vCardDataDesign, vCardData as vCardSetup, vCardTemplateId } from '../../redux/action/cardData';
import { getCardById } from '../../services/api/api-services';
import { useNavigate, useParams } from 'react-router-dom';
import { LoaderIcon } from 'react-hot-toast';
import TemplateImage from '../template/TemplateImage';
import TemplateRoute from '../template/TemplateRoute';
import { LiaIdCardSolid } from 'react-icons/lia';
import { MdClose } from 'react-icons/md';
import GetNfcCard from '../../components/landing/components/getNfcCard/GetNfcCard';
import RoyalBottomStrip from '../../assests/imges/vector/royal-edge-bottom.svg'
export default function CreateCard() {
    const dispatch = useDispatch();
    const { cardId } = useParams();
    const childRef = useRef();
    const [isLoaded, setLoaded] = useState(true);
    const [selectedCard, setSelectedCard] = useState(1)
    const vCardData = useSelector(selectVCardData);
    const [isSideCardView, setSideCardView] = useState(false)
    const navigate = useNavigate()
    //update card 

    const convertJson = useCallback((json) => {
        return json && JSON.parse(json);
    }, []);

    const updateReduxStore = useCallback((response) => {
        dispatch(vCardSetup({
            firstName: response?.card?.firstName,
            lastName: response?.card?.lastName,
            prefix: response?.card?.prefix,
            suffix: response?.card?.suffix,
            designation: response?.card?.designation,
            department: response?.card?.department,
            pronouns: response?.card?.pronouns,
            company: response?.card?.company,
            summary: response?.card?.summery,
            location: response?.card?.location,
            logoImagePreview: response?.card?.logo,
            userImagePreview: response?.card?.profilePhoto,
            logo: response?.card?.logo,
            userImage: response?.card?.profilePhoto,
            phones: convertJson(response?.card?.phones),
            emails: convertJson(response?.card?.emails),
            website: convertJson(response?.card?.webs),
            customLinks: convertJson(response?.card?.customFields),
            socialLinks: convertJson(response?.card?.socialLink),
        }));
        dispatch(vCardDataDesign({
            primaryColor: response?.card?.primaryColor,
            profileTextColor: response?.card?.profileTextColor,
            secondaryColor: response?.card?.secondaryColor,
            fontFamily: response?.card?.font,
            titleFontSize: response?.card?.fontSize
        }))
        dispatch(vCardDataAdditional({
            isLocation: response?.card?.isLocation,
            isDownload: response?.card?.isDownload,
        }))
        setLoaded(false)

    }, [convertJson, dispatch])

    // handle card data when update card
    const handleGetCardData = useCallback((cardId) => {
        getCardById(cardId).then((response) => {
            if (response?.err === false) {
                updateReduxStore(response);
                setSelectedCard(Number(response?.card?.pronouns))
            } else {
                console.log(response);
            }
        }).catch((err) => console.log(err))
    }, [updateReduxStore])

    useEffect(() => {
        if (cardId !== undefined) {
            handleGetCardData(cardId)
        } else {
            setLoaded(false)
        }

    }, [cardId, handleGetCardData,])

    useEffect(() => {
        dispatch(vCardTemplateId({
            pronouns: selectedCard,
        }));
        console.log(selectedCard, "selected");
    }, [selectedCard])

    const handleCancelButton = () => {
        navigate('/my-card')
    }

    if (isLoaded) {
        return <div className='loader-container'>
            <LoaderIcon style={{ width: 50, height: 50 }} />
        </div>
    } else {
        return (
            <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
                <div className='nk-content-inner'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <p className='gradient-text text-[13px] font-semibold mb-2'>My Cards  {'>'} {!!!cardId ? "Create New" : "Update"} card</p>
                            <p className='font-medium text-[40px] gradient-text'>{!!!cardId ? "Create New" : "Update"}  Card<br /></p>
                            <p className='text-3xl font-medium mt-5 color-theme'>Select a template</p>
                            <div>
                                <TemplateImage isSelectedCard={selectedCard} setSelectedCard={setSelectedCard} />
                            </div>
                            <div className='flex flex-col w-100 mt-5'>
                                {!isLoaded && <CardDetails ref={childRef} />}
                            </div>
                        </div>
                        <div className='col-lg-4 d-lg-block d-none card-hidden' >
                            <div className='generate-card-container'>
                                <div className='save_button_container'>
                                    <button className='cancel-button mb-5' onClick={handleCancelButton}>
                                        <div>Cancel</div>
                                    </button>
                                    <button className='save-button mb-5 ml-6' onClick={() => childRef?.current?.handleSubmitButton(selectedCard)}>
                                        <div>Save</div>
                                    </button>
                                </div>
                                <div className='max-height-75 overflow-auto'>
                                    <div className='border-left-preview-container flex justify-end template-route-container'>
                                        {!isLoaded && <TemplateRoute selectedTemplate={selectedCard} data={vCardData} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mobile-view-card">
                            <div className='mobile-view-button' onClick={() => setSideCardView(!isSideCardView)}>
                                <LiaIdCardSolid size={25} color='#151515' />
                            </div>
                            <div className={`mobile-side-panel ${isSideCardView && 'isActive'}`}>
                                <div className='mobile-panel-close'>
                                    <button type='button' onClick={() => setSideCardView(!isSideCardView)}>
                                        <MdClose size={25} color='#4D3000' />
                                    </button>
                                </div>
                                <div className='card-preview-panel'>
                                    <div className='zoom-5'>
                                        {!isLoaded && <TemplateRoute selectedTemplate={selectedCard} data={vCardData} isDrawer={true} />}
                                    </div>
                                </div>
                                <div className='template-preview-panel'>
                                    <h2>Choose card template</h2>                                    
                                    <TemplateImage isSelectedCard={selectedCard} setSelectedCard={setSelectedCard} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='nfc-spacing'>
                        <img src={RoyalBottomStrip} />
                        <GetNfcCard />
                    </div>
                    {/* <div className='col-xl-4' >
                        {!isLoaded && <CardPreview />}
                    </div> */}
                </div>
            </div>
        )
    }


}
