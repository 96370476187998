import Axios from 'axios';
import { HEADER_IN_JSON, SIGN_IN, SIGN_UP, CHANGE_PASSWORD, RESET_PASSWORD, FORGOT_PASSWORD, GET_TEMPLATE, CREATE_SETUP_CARD, HEADER_IN_JSON_IMAGE, CREATE_CARD_COLOR, GET_MY_CARDS, GET_TEMPLATE_BY_ID, UPDATE_CARD, DELETE_CARD, GET_CARD_BY_ID, GET_LOCATION_DATA, GET_LOCATION_DATA_BY_USER_ID, GET_LOCATION_DATA_BY_CARD_ID, VERIFY_OTP, GET_PROFILE, UPDATE_USER, GET_COUNT_BY_CARD_ID, GET_GRAPH_BY_DATE, GET_CARD_VIEW_BY_DATE } from './api-path';
import { GET_TOTAL_COUNT } from './api-path';

export const signIn = async (send) => {
    const response = await Axios.post(SIGN_IN, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const signUp = async (send) => {
    const response = await Axios.post(SIGN_UP, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const changePassword = async (send) => {
    const response = await Axios.post(CHANGE_PASSWORD, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const resetPassword = async (send) => {
    const response = await Axios.post(RESET_PASSWORD, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const forgotPassword = async (send) => {
    const response = await Axios.post(FORGOT_PASSWORD, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}

export const verifyOTP = async (send) => {
    const response = await Axios.post(VERIFY_OTP, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const getProfileById = async (send) => {
    const response = await Axios.get(GET_PROFILE + send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const updateUserProfile = async (send) => {
    const response = await Axios.put(UPDATE_USER, send, { headers: HEADER_IN_JSON_IMAGE, validateStatus() { return true } });
    const data = response.data;
    return data;
}

export const getTemplate = async (send, token) => {
    const response = await Axios.get(GET_TEMPLATE + send, { headers: HEADER_IN_JSON, validateStatus() { return true } })
    const data = response.data
    return data;
}

export const createCardSetup = async (send, token) => {
    const response = await Axios.post(CREATE_SETUP_CARD, send, { headers: HEADER_IN_JSON_IMAGE, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const createCardColor = async (send, token) => {
    const response = await Axios.post(CREATE_CARD_COLOR, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const getMyCards = async (send, token) => {
    const response = await Axios.get(GET_MY_CARDS + send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}


export const getTemplateById = async (send) => {
    const response = await Axios.get(GET_TEMPLATE_BY_ID + send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}


export const updateCard = async (send) => {
    const response = await Axios.post(UPDATE_CARD, send, { headers: HEADER_IN_JSON_IMAGE, validateStatus() { return true } });
    const data = response.data;
    return data;
}

export const deleteCard = async (send) => {
    const response = await Axios.delete(DELETE_CARD + send, { headers: HEADER_IN_JSON_IMAGE, validateStatus() { return true } });
    const data = response.data;
    return data;
}

export const getCardById = async (send) => {
    const response = await Axios.get(GET_CARD_BY_ID + send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}


export const getLocationDataByUserId = async (send) => {
    const response = await Axios.get(GET_LOCATION_DATA_BY_USER_ID + send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const getLocationDataByCardId = async (send) => {
    const response = await Axios.get(GET_LOCATION_DATA_BY_CARD_ID + send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}

export const getTotalCardViewCount = async (send) => {
    const response = await Axios.get(GET_COUNT_BY_CARD_ID + send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}

export const getGraphValues = async (send) => {
    const response = await Axios.post(GET_GRAPH_BY_DATE, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}
export const getCardGraphValues = async (send) => {
    const response = await Axios.post(GET_CARD_VIEW_BY_DATE, send, { headers: HEADER_IN_JSON, validateStatus() { return true } });
    const data = response.data;
    return data;
}