import React, { useCallback, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyOTP } from '../../../../services/api/api-services';
import { toast } from 'react-hot-toast';
import { errorStyle } from '../../../../utils/strings/Strings';
import Styles from '../forgot-password/styles.module.scss'
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function VerifyOtp() {
    const navigation = useNavigate();

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false);
    const [isDisabled, setDisabled] = useState(false)
    let query = useQuery();

    const handleOtpValidation = (event) => {
        event.preventDefault();

        if (otp === "") {
            setOtpError(true);
            return
        } else {
            setOtpError(false);
            handleFormSubmit();
        }
    }

    const handleFormSubmit = useCallback(() => {
        let body = {
            "email": query.get("value"),
            "OTP": otp,
        }

        const toastId = toast.loading("Loading...")
        setDisabled(true)
        verifyOTP(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId);
                toast.success(response?.msg)
                navigation(`/reset-password?value=${query.get("value")}`)
                setDisabled(false)
            } else {
                toast.dismiss(toastId);
                toast.error(response?.msg)
                setDisabled(false)
            }
        }).catch((err) => console.log(err))
    }, [navigation, otp, query])


    useEffect(() => {
        if (query.get("value")) {
            return
        } else {
            navigation('/')
        }
    }, [navigation, query])

    useEffect(() => {
        if (otp.length === 4) {
            handleFormSubmit()
        }
    }, [handleFormSubmit, otp])

    return (
        <div className={Styles.loginwrap}>
            <div className={Styles.loginForm}>
                <div className={Styles.forgotPassword}>

                    <h3 >Enter verification code</h3>

                    <p>We have sent you <b>One Time Password</b> to your <br /> {query.get("value").includes("@") ? "email address" : "phone number"} <b>{query.get("value")}</b></p>
                    <p className='mb-4'></p>
                </div>

                <form className="nk-block-head-content" onSubmit={handleOtpValidation}>


                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span>-</span>}
                        containerStyle={{ justifyContent: 'center' }}
                        inputStyle={otpError ? errorStyle : {}}
                        shouldAutoFocus

                        renderInput={(props) => <input {...props} className={Styles.otpinputStyle} />}
                    />
                    {otpError && <small className='invalid-feedback d-block'>OTP must be filled out.</small>}
                    <div className='mb-3'></div>
                    <button type='submit' disabled={isDisabled} className={Styles.btn_primary}>Verify OTP</button>
                </form>
            </div>
        </div>


    )
}
