import { Modal } from 'antd'
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';

import { AiFillFacebook, AiFillInstagram } from 'react-icons/ai'
import { PREVIEW_CARD_URL } from '../../utils/strings/Strings';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookShareButton, InstapaperShareButton, LinkedinShareButton, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappShareButton } from 'react-share';
export const socialMediaMetaData = [
    {
        id: 1,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/wgu1uyyzwjh7krg6oo4z.png",
        title: 'Facebook',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-facebook-4_tj2psc.svg"
    },
    {
        id: 2,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/yseji32pybmfcygiqhes.png",
        title: 'Instagram',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-instagram-11_edvxho.svg"
    },
    {
        id: 3,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1693314179/twitter-new_iopewa.png",
        title: 'Twitter',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1693302567/twitter_cblijy.png"
    },
    {
        id: 4,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/zpty4iyd35hpns5ciwn6.png",
        title: 'LinkedIn',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-linkedin-3_yidwlg.svg"
    },
    // {
    //     id: 9,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/frp7yf43efx0mqblcyok.png",
    //     title: 'Vimeo',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966477/iconmonstr-vimeo-1_rh9osz.svg"
    // },
    // {
    //     id: 10,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/rcebhhnu2xfrh13pad70.png",
    //     title: 'Youtube',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966477/iconmonstr-youtube-6_oogiig.svg"
    // },
    // {
    //     id: 11,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/h4vbtwntsnmtneqk0gnb.png",
    //     title: 'Wistia',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/wistia_mrkhyw.svg"
    // },
    // {
    //     id: 12,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/hurwb0wbtfnbvrvp3rb5.png",
    //     title: 'Twitch',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966476/iconmonstr-twitch-4_ehjtpw.svg"
    // },
    // {
    //     id: 13,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309311/xo8jcqdzioe6cbdu0rcn.png",
    //     title: 'Discord',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-discord-1_ryapt6.svg"
    // },
    // {
    //     id: 14,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/x8v7fkpldsw9yh2vsoax.png",
    //     title: 'Pinterest',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-pinterest-1_wtcsj5.svg"
    // },
    // {
    //     id: 15,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/jvvycr4859etuydzl6gx.png",
    //     title: 'Yelp',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/yelp_adkmnb.svg"
    // },
    // {
    //     id: 16,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/on0dfbufj2nbzzwinmda.png",
    //     title: 'Paypal',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-paypal-1_xfd3oz.svg"
    // },
    // {
    //     id: 17,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/pgjzkcf3sjd5uqrswdbh.png",
    //     title: 'Venmo',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/logo-venmo_lettae.svg"

    // },
    // {
    //     id: 18,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/ksoulgpvqkgenbnvybgt.png",
    //     title: 'CashApp',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/cashapp_zhcsba.svg"
    // },
    // {
    //     id: 19,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/jhitxe1h7ztuqk2a2yu3.png",
    //     title: 'Calendly'
    // },
    // {
    //     id: 20,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/iwis3c9mg1essjd5t1v8.png",
    //     title: 'Shopify',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/shopify_lzhh1q.svg"
    // },
    // {
    //     id: 21,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/zniuwkqpi65lxtsr8ykj.png",
    //     title: 'Github',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-github-1_jdkvqn.svg"
    // },
    // {
    //     id: 22,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/yt9o9hxfroryqdhgar8i.png",
    //     title: 'Dribbble',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-dribbble-1_czmdq0.svg"
    // },
    // {
    //     id: 23,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/tueqxmfwg0qxyg37yf8w.png",
    //     title: 'Behance',
    //     svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-behance-4_fe9hw7.svg"
    // },
]

const message = [
    {
        id: 7,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/vth5rytxkvc39foxv59e.png",
        title: 'Whatsapp',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966477/iconmonstr-whatsapp-1_jvetgp.svg"
    },
    {
        id: 6,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/dnbpdtucsaucr6c8jvri.png",
        title: 'Snapchat',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-snapchat-1_fwetqm.svg"
    },
    {
        id: 8,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/onvjx5ole5iuzdvjkmwd.png",
        title: 'Telegram',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966476/iconmonstr-telegram-1_wkoade.svg"
    },
]


export default function ShareModal({ isShow, onClose, shareId }) {
    const URL_STRING = PREVIEW_CARD_URL + shareId?._id
    const CommonDescription = "Card Connect || Connecting People"
    const CommonTitle = `Hello this is  ${shareId?.firstName} ${shareId?.lastName}`
    const handleCopyLink = (_id) => {
        toast.success("Link copy successfully")
        navigator.clipboard.writeText(PREVIEW_CARD_URL + _id)
    }
    return (
        <Modal title={<h1 className='h1 gradient-text'>Share</h1>} open={isShow} onOk={onClose && onClose} onCancel={onClose && onClose} cancelButtonProps={{
            style: {
                display: 'none'
            }
        }}
            okButtonProps={{
                style: {
                    display: 'none'
                }
            }}
            rootClassName='theme-modal-wrapper'
        >
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 mb-3 mt-3'>
                        <h2 className='color-theme'>As a post</h2>
                    </div>
                    <div className='col-lg-12 mb-4'>
                        <div className='flex w-full flex-wrap gap-2 h-full'>
                            <FacebookShareButton
                                url={URL_STRING}
                                quote={CommonTitle}
                                hashtag={"#hashtag"}
                                description={CommonDescription}
                            >
                                <img src="https://res.cloudinary.com/cardscanner/image/upload/v1684309312/wgu1uyyzwjh7krg6oo4z.png" width={55} />
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={URL_STRING}
                                title={CommonTitle}
                                hashtag={"#hashtag"}
                                via={CommonDescription}
                            >
                                <img src="https://res.cloudinary.com/cardscanner/image/upload/v1693314179/twitter-new_iopewa.png" width={55} />
                            </TwitterShareButton>
                            {/* <InstapaperShareButton
                                url={URL_STRING}
                                title={CommonTitle}
                                hashtags={"#hashtag"}
                                description={CommonDescription}
                            >
                                <img src="https://res.cloudinary.com/cardscanner/image/upload/v1684309312/yseji32pybmfcygiqhes.png" width={55} />
                            </InstapaperShareButton> */}
                            <LinkedinShareButton
                                url={URL_STRING}
                                title={CommonTitle}
                                hashtag={"#hashtag"}
                                summary={CommonDescription}
                                source={CommonDescription}

                            >

                                <img src="https://res.cloudinary.com/cardscanner/image/upload/v1684309312/zpty4iyd35hpns5ciwn6.png" width={55} />
                            </LinkedinShareButton>
                        </div>
                    </div>
                    <div className='col-lg-12  mb-3'>
                        <h2 className='color-theme'>As a message</h2>
                    </div>
                    <div className='col-lg-12 mb-3'>
                        <div className='flex w-full flex-wrap gap-2 h-full'>
                            <WhatsappShareButton
                                url={URL_STRING}
                                title={CommonTitle}
                                separator=":: "
                            >
                                <img src="https://res.cloudinary.com/cardscanner/image/upload/v1684309312/vth5rytxkvc39foxv59e.png" width={55} />
                            </WhatsappShareButton>
                            <TelegramShareButton
                                url={URL_STRING}
                                title={CommonTitle}
                            >
                                <img src="https://res.cloudinary.com/cardscanner/image/upload/v1684309312/onvjx5ole5iuzdvjkmwd.png" width={55} />
                            </TelegramShareButton>
                            {/* <FacebookMessengerShareButton
                                url={URL_STRING}
                                quote={"Hello This is Me"}
                                hashtag={"#hashtag"}
                                description={"aiueo"}                                >
                                <img src="https://res.cloudinary.com/cardscanner/image/upload/v1693316181/messanger_ciq2s1.png" width={55} />
                            </FacebookMessengerShareButton> */}
                            <EmailShareButton
                                url={URL_STRING}
                                subject={CommonTitle}
                                body={CommonDescription}                            >
                                <EmailIcon round size={50} />

                            </EmailShareButton>
                        </div>
                    </div>
                    <div className='col-lg-12  mb-3'>
                        <h2 className='color-theme'>Copy Link</h2>
                    </div>
                    <div className='col-lg-12'>
                        <div className='flex items-center' onClick={() => handleCopyLink(shareId?._id)}>
                            <div className='form-control me-3'>
                                <div className='sharelink'>{URL_STRING}</div>
                            </div>
                            <div>
                                <button className='btn btn-secondary'>Copy</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    )
}
