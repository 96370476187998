
import { useCallback, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useEffect } from 'react';
import { useRef } from 'react';
import moment from 'moment';

function ApexChart({ data, startDate, endDate, activeTab }) {

    const [yaxis, setYaxis] = useState([]);
    const [xaxis, setXaxis] = useState([])
    const chart = useRef()

    let monthNumber = (element) => {
        // eslint-disable-next-line default-case
        switch (element) {
            case 1:
                return "Jan";
            case 2:
                return "Feb"
            case 3:
                return "Mar"
            case 4:
                return "Apr"
            case 5:
                return "May"
            case 6:
                return "Jun"
            case 7:
                return "Jul"
            case 8:
                return "Aug"
            case 9:
                return "Sep"
            case 10:
                return "Oct"
            case 11:
                return "Nov"
            case 12:
                return "Dec"
        }


    }


    const getData = useCallback((data) => {
        let dates = getDatesBetween(new Date(startDate), new Date(endDate));
        if (data?.getViewCountResult?.length !== 0) {
            if (data?.getViewCountResult?.length) {
                setYaxis([]);
                setXaxis([]);
                const monthSums = {};
                // if (activeTab === "tab1") {
                //     setXaxis(dates);
                // }
                // calculate same month data              
                data?.getViewCountResult?.forEach(item => {
                    const date = new Date(item._id);
                    const month = date.getMonth() + 1; // Get the month (0-11)

                    if (monthSums[month] === undefined) {
                        monthSums[month] = 0;
                    }
                    monthSums[month] += item.totalViews;
                });
                if (Object.keys(monthSums)?.length === 1) {
                    setYaxis(oldArray => [...oldArray, 0]);
                }
                Object.keys(monthSums).map((element) => {
                    // if (activeTab !== 'tab1') {
                        setXaxis(prev => [...prev, monthNumber(Number(element))])
                    // }
                    setYaxis(oldArray => [...oldArray, monthSums[element]]);
                });

            }
        } else {
            setYaxis([0]);
        }
    }, [endDate, startDate,])

    function getDatesBetween(startDate, endDate) {
        const dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(moment(currentDate).format("YYYY-DD-MM"));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }


    function getMonthNamesBetweenDates(startDate, endDate) {

        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const months = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            months.push(monthNames[currentDate.getMonth()]);
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return months;
    }

    useEffect(() => {
        getData(data)
    }, [getData, startDate, data])
    
    return (
        <div id="chart" className='chart-bg w-full'>
            <ReactApexChart
                ref={chart}
                options={{
                    noData: {
                        text: undefined,
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '14px',
                            fontFamily: undefined
                        }
                    },
                    chart: {
                        height: 250,
                        type: 'line',
                        zoom: {
                            enabled: false
                        },
                        group: [],
                        foreColor: "#A2A2A2",
                        toolbar: {
                            show: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        colors: ["#ECC466"],
                        width: 2
                    },
                    grid: {
                        borderColor: 'transparent',
                        row: {
                            colors: ['transparent'], // takes an array which will be repeated on columns
                            opacity: 1,

                        },
                    },
                    xaxis: {
                        categories: xaxis,
                        tooltip: {
                            enabled: false
                        },
                    },
                    markers: {
                        size: 7,
                        colors: ["#FFD86E"],
                        strokeColors: 'transparent',
                        strokeWidth: 2,
                        strokeOpacity: 0.9,
                        strokeDashArray: 0,
                        fillOpacity: 1,
                        discrete: [],
                        shape: "circle",
                        radius: 2,
                        offsetX: 0,
                        offsetY: 0,
                        onClick: undefined,
                        onDblClick: undefined,
                        showNullDataPoints: true,
                        hover: {
                            size: undefined,
                            sizeOffset: 3
                        },

                    },
                    tooltip: {
                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                            return `<div class="arrow_box" style="">
                                        <span>${series[seriesIndex][dataPointIndex]} Views</span>
                                        <!--<span class="notch"></span>-->
                            </div>`
                        },
                        enabled: true,
                        enabledOnSeries: undefined,
                        shared: false,
                        intersect: true,
                        followCursor: false,
                        inverseOrder: false,
                        fillSeriesColor: false,
                        style: {
                            fontSize: '12px',
                            fontFamily: undefined
                        },
                        onDatasetHover: {
                            highlightDataSeries: false,
                        },
                        x: {
                            show: true,
                            format: 'dd MMM',
                            formatter: undefined,
                        },
                        y: {
                            formatter: undefined,
                            title: {
                                formatter: (seriesName) => seriesName,
                            },

                        },
                        z: {
                            formatter: undefined,
                            title: 'Size: '
                        },
                        marker: {
                            show: true,
                        },
                        items: {
                            display: "block",

                        },
                        fixed: {
                            enabled: false,
                            offsetX: -50
                        },
                        cssClass: "apex-tooltip"
                    }
                }} series={[
                    {
                        name: "card-1",
                        data: yaxis
                    }
                ]} type="line" height={280} />
        </div>
    )
}

export default ApexChart