import React from 'react'
import Styles from './styles.module.scss'
// import { EmailIcon, GloabIcon, NavigationIcon, PhoneIcon, ShareIcon, ShareIcon2 } from '../components/Icons';

import { AVATAR, CARD_BG } from '../../../utils/images/Images';
import { isValidLink } from '../components/isValidUrl';
import { useEffect } from 'react';
import './Qrcardpreview.scss';
import SocialMediaSlider from '../components/social-media-slider/SocialMediaSlider'
import { AiOutlineShareAlt, } from 'react-icons/ai'

import EmailIcon from '../../assets/images/icon/email.png'
import GoogleMapIcon from '../../assets/images/icon/googlemap.png'
import PhoneIcon from '../../assets/images/icon/phone.png'
import LinkIcon from '../../assets/images/icon/link.png'
import WebsiteIcon from '../../assets/images/icon/safari.png'
import { useState } from 'react';
export default function CardTemplate({ data, onClickShareButton, onClickAddToContactButton, isMobileView}) {    
    // useEffect(() => {
    //     let vh = window.innerHeight * 0.01;
    //     document.getElementById("cardWrapper").style.setProperty('--vh', `${vh}px`);
    //     console.log(window.innerWidth);
       
    //     if (isMobileView || (window.innerWidth <= 600)) {
    //         document.getElementById("cardWrapper").style.setProperty('height', '650px');
    //       } else {
    //         // You may want to set a different height or reset to auto height if isMobileView is false
    //         document.getElementById("cardWrapper").style.setProperty('height', '530px');
    //       }
    //   }, [isMobileView,window.innerWidth]); // Add isMobileView as a dependency to the useEffect
    
    
    return (
        <div className={Styles.CardTemplate8}>
            <div className={Styles.CardWrapper} id='cardWrapper' style={{height:'100%'}} >
                <div className={Styles.CardContainer}>
                    <div className={Styles.CardBody}                >
                        <div className='preview-card-containerr'>
                            <div className='top-container' style={{ backgroundImage: `url(${data?.logoImagePreview ? data?.logoImagePreview : CARD_BG})`, backgroundColor: data?.secondaryColor ? data?.secondaryColor : "#222" }} />
                            <div className='main-container'>
                                <div className='mid-container'>
                                    <div className='profile-img'>
                                        <img src={data?.userImagePreview ? data?.userImagePreview : AVATAR} />
                                    </div>
                                    <div className='top-details' style={{ backgroundColor: data?.primaryColor }}>
                                        <h1 style={{
                                            color: data?.profileTextColor,
                                            fontSize: `${data?.titleFontSize ? data?.titleFontSize + "px" : data?.firstName?.length + data?.lastName?.length + data?.prefix?.length + data?.suffix?.length >= 18
                                                ? "14px"
                                                : data?.firstName?.length + data?.lastName?.length + data?.prefix?.length + data?.suffix?.length >= 12
                                                    ? "16px"
                                                    : "18px"
                                                }`,
                                        }}>{data?.firstName || data?.lastName ? (data?.prefix + " " + data?.firstName + " " + data?.lastName + " " + data?.suffix) : "Card Connect"}</h1>
                                        <span style={{ color: data?.profileTextColor, marginBottom: 5 }}>{data?.designation ? data?.designation : "Founder & Managing Partner"}</span>
                                        <span style={{ color: data?.profileTextColor, fontSize: "0.7rem", opacity: 0.8 }}>{data?.company ? data?.company : "Company Name"}</span>
                                    </div>
                                </div>
                                <div className='bottom-container'>
                                    <div className='bottom-content'>
                                        <div className='btn-container'>
                                            <button className='add-to-contact' onClick={onClickAddToContactButton}>Add to Contact</button>

                                            <button className='share-button' onClick={onClickShareButton}><span><AiOutlineShareAlt size={12} /></span>Share it</button>
                                        </div>

                                        <div className='about-container'>
                                            <h3 className='section-heading'>About</h3>
                                            <p>{data?.summary}</p>
                                        </div>
                                        <div className='Social-link-container'>
                                            <h3 className='section-heading'>Social Media Links</h3>
                                                <SocialMediaSlider data={data} />
                                            <div className='social-media'>
                                            </div>
                                        </div>
                                        <div className='personal-information contact-info'>
                                            <h3 className='section-heading'>Contact Info</h3>
                                            <div className='info'>
                                            {data?.phones?.length > 0 && data?.phones[0].value !== "" && <div>
                                            {data.phones.map((i, index) => <a  target='_blank' href={"tel:" + i.value} key={index.toString()} className='phones-info' rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                <img src={PhoneIcon} />
                                                </div>
                                                <p>{i.value}</p>
                                            </a>)}

                                        </div>}
                                        {data?.emails?.length > 0 && data?.emails[0].value !== "" && <div >
                                            {data.emails.map((i, index) => <a target='_blank' href={"mailto:" + i.value} key={index.toString()} className='phones-info' rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                <img src={EmailIcon} />
                                                </div>
                                                <p>{i.value}</p>

                                            </a>)}
                                        </div>}
                                        {data?.website?.length > 0 && data?.website[0].value !== "" && <div>
                                            {data.website.map((i, index) => <a target='_blank' href={isValidLink(i.value)} key={index.toString()} className='phones-info' rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                <img src={WebsiteIcon} />
                                                </div>
                                                <p>{isValidLink(i.value)}</p>
                                            </a>)}
                                        </div>}
                                        {data?.location && <div>
                                            <a target='_blank' href={"https://maps.google.com/?q=" + data?.location.replaceAll(",", "")} className='phones-info' rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                <img src={GoogleMapIcon} />
                                                </div>
                                                <p>{data?.location}</p>
                                            </a>
                                        </div>
                                        }

                                            {data?.customLinks?.length > 0 && data?.customLinks[0].value !== "" && <div>
                                            {data.customLinks.map((i, index) => <a target='_blank' href={isValidLink(i.value)} key={index.toString()} className='phones-info' rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                <img src={LinkIcon} />
                                                </div>
                                                <p>{isValidLink(i.value)}</p>
                                            </a>)}
                                        </div>}
                  
                                        {/* <div>
                                            <SocialMediaSlider />
                                        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
