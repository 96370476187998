import Styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import NavbarZEEQR from "../navbar/NavbarZEEQR";
import FooterZEEQR from "../footer/FooterZEEQR";
import { SiteName } from '../../../utils/strings/Strings';

import RoyalHeroPath from '../../../assests/imges/vector/royal-path-1.svg'
import RoyalHeroPathCircle from '../../../assests/imges/vector/royal-path-circle.svg'
import WhyusImage from '../../../assests/imges/whyus.png'
import WhyusImage1 from '../../../assests/imges/whyus-1.png'
import WhyusCardImage from '../../../assests/imges/vector/cards-image.svg'
import WhyusFlashImage from '../../../assests/imges/vector/flash.svg'


import RoyalTopLeftCornerImage from '../../../assests/imges/vector/corner.svg'
import RoyalTopRightCornerImage from '../../../assests/imges/vector/corner-1.svg'
import RoyalMiddleImage from '../../../assests/imges/vector/middle.svg'
import RoyalButtonStrip from '../../../assests/imges/vector/button-dec.svg'
import RoyalRectangular from '../../../assests/imges/vector/rectangle.svg'
import CardImageFlash from '../../../assests/imges/vector/flash1.svg'
import CardImageLock from '../../../assests/imges/vector/lock.svg'
import CardImageChat from '../../../assests/imges/vector/chat.svg'
import CardImageStarCard from '../../../assests/imges/vector/card.svg'
import CollectionImage from '../../../assests/imges/collection.png'
import MissionImage from '../../../assests/imges/mission.png'
import RoyalTopStrip from '../../../assests/imges/vector/royal-edge.svg'
import RoyalBottomStrip from '../../../assests/imges/vector/royal-edge-bottom.svg'
import GetNfcCard from '../components/getNfcCard/GetNfcCard';
import Tagline from "../components/tagline/Tagline";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Autoplay, } from 'swiper/modules';
const Spacer = ({ space }) => {
  return <div style={{ margin: space }} />
}
function HomepageZeeqr({ isAuth }) {
  const [open, myTopnav] = useState(false);


  var x = document.getElementById("myTopnav");

  return (
    <>
      <div className={Styles.wrapper}>
        <NavbarZEEQR isAuth={isAuth} />
        {/* Hero Section Start*/}
        <section className={Styles.zeeqr_section_home}>

          <div className={Styles.container}>

            <div className={Styles.zeeqr_section}>
              <div className={Styles.col_home}>
                <div className={Styles.col}>
                  {/* <p className={Styles.zeeqr_section_p}>Why {SiteName}</p> */}
                  <img src={RoyalHeroPath} alt="" />
                  <div className={Styles.text_content}>
                    <p className={Styles.hero_section_p1}>Elevate your Brand</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="138" height="17" viewBox="0 0 138 17" fill="none" className={Styles.marginLeftAuto}>
                      <path d="M3.12792 14.2856C2.00604 11.7006 27.7289 7.91952 58.7252 5.5864C92.6213 3.03501 112.565 2.26471 134.979 3.08428" stroke="#ECA436" stroke-width="5" stroke-linecap="round" />
                    </svg>
                  </div>
                  <p className={Styles.hero_section_p2}>With {SiteName} ®</p>
                  <Tagline />
                  <Link
                    to={"/select-card"}
                    className={Styles.btn}
                  >
                    Start Now!
                  </Link>
                </div>

                <div className={Styles.col}>
                  <div className={Styles.ourVideo}>
                    {/* <img src={HeroImage}></img> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className={Styles.section_overlay}></div>
          <img src={RoyalHeroPathCircle} className={Styles.hero_section_circleImg} />
        </section>
        {/* Hero Section End*/}

        <main className={Styles.mainContainer}>
          <div className={Styles.topVectorImage}>
            <img src={RoyalTopStrip} />
          </div>
          <section className={Styles.whyus}>
            <div className={Styles.whyus_card}>

              <div className={Styles.container}>
                <div className={Styles.absoluteImageContainer}>
                  <img src={RoyalTopLeftCornerImage} className={Styles.topLeftCorner} />
                  <img src={RoyalTopRightCornerImage} className={Styles.topRightCorner} />
                  <img src={RoyalMiddleImage} className={Styles.lineLeftSideExtra} />
                  <img src={RoyalMiddleImage} className={Styles.lineLeftSide} />
                  <img src={RoyalMiddleImage} className={Styles.lineRightSide} />
                  <img src={RoyalTopRightCornerImage} className={Styles.bottomLeftCorner} />
                  <img src={RoyalTopLeftCornerImage} className={Styles.bottomRightCorner} />
                </div>
                <h1 className={Styles.gradient_text}>
                  Why  Us?
                </h1>
                <Spacer space={50} />
                <div className={Styles.whyus_col}>
                  <div className={Styles.col}>
                    <p className={Styles.whyus_h3}>
                      Dynamic and Amazing Cards
                    </p>
                    <Spacer space={26} />
                    <p className={Styles.whyus_p}>
                      We are a dedicated team with highly talented designers and skillful developers who can craft an amazing Digital Card for you.
                    </p>
                  </div>
                  <div className={Styles.imgaWhyus}>
                    <img src={WhyusImage} />
                  </div>
                </div>
                <div className={Styles.whyus_col_inverse}>

                  <div className={Styles.imgaWhyus}>
                    <img src={WhyusImage1} />
                  </div>
                  <div className={Styles.col}>
                    <Spacer space={57} />
                    <p className={Styles.whyus_h3}>
                      Save Your Time
                    </p>
                    <Spacer space={26} />
                    <p className={Styles.whyus_p}>
                      With an Digital business card, you can share your contact information with just a tap of the card on a smartphone.
                    </p>
                  </div>
                </div>
                <div className={Styles.whyus_col}>
                  <div className={Styles.col}>
                    <Spacer space={57} />
                    <p className={Styles.whyus_h3}>
                      Latest technology
                    </p>
                    <Spacer space={26} />
                    <p className={Styles.whyus_p}>
                      Digital cards can be cost-effective compared to traditional printed business cards. It can save money in the long run by eliminating the need to constantly print and distribute traditional business cards.
                    </p>
                  </div>
                  <div className={Styles.imgaWhyus}>
                    <img src={WhyusCardImage} />
                  </div>
                </div>
                <div className={Styles.whyus_col_inverse}>

                  <div className={Styles.imgaWhyus}>
                    <img src={WhyusFlashImage} />
                  </div>
                  <div className={Styles.col}>
                    <Spacer space={57} />
                    <p className={Styles.whyus_h3}>
                      Supercharge your Strategy
                    </p>
                    <Spacer space={26} />
                    <p className={Styles.whyus_p}>
                      Don't settle for a plain business card that will be easily forgotten. Upgrade to an Digital card and make a lasting impression with every interaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <div className={Styles.get_nfc}>

          <div className={Styles.getnfc_text}>
            <div className={Styles.overlay} />
            <div className={Styles.overlay2} />

            <div className={Styles.container}>
              <img src={RoyalRectangular} className={Styles.RoyalRectangular1} />
              <div className={Styles.row_nfc}>
                <div className="col">
                  <img src={RoyalHeroPath} width={175.447} />
                  <Spacer space={4.5} />
                  <h1 className={Styles.gradient_text}>
                    Experience Elegance
                  </h1>
                  <Spacer space={4.5} />
                  <p>That makes strong connections</p>
                </div>
                <div className="col">
                  <div className="align-center flex-column">
                    <img src={RoyalButtonStrip} />
                    <Link to={"/select-card"}
                      style={{ textDecoration: "none" }}>
                      {" "}
                      <button
                        type="button"
                        className={Styles.btn_get_zeeqr}
                      >
                        Get your Card
                      </button>
                    </Link>
                    <img src={RoyalButtonStrip} style={{ transform: "rotate(-180deg)" }} />
                  </div>
                </div>
              </div>
              <img src={RoyalRectangular} className={Styles.RoyalRectangular2} />
              <img src={RoyalRectangular} className={Styles.RoyalRectangular3} />
            </div>

          </div>
        </div>

        <div className={Styles.features}>
          <div className={Styles.features_card}>
            <div className={Styles.container}>
              {/* <div className={Styles.row_features}> */}
              <Swiper
                spaceBetween={20}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}

                breakpoints={{
                  680: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  717: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1400: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                modules={[Autoplay]}
                loop
              // onSlideChange={() => console.log('slide change')}

              >
                <SwiperSlide>
                  <FeatureCard image={CardImageFlash} label={"Instant Sharing"}
                    content="Digital card business cards can be easily shared by tapping them against an digital card smartphone. This makes it easy for potential clients, colleagues, or contacts to receive your contact information without the need for manual data entry." />
                </SwiperSlide>
                <SwiperSlide> <FeatureCard image={CardImageLock} label={"Secure"}
                  content="The data stored on the digital card is encrypted. This can help to protect the data from unauthorized access in case the card is lost or stolen. We constantly make sure that the user’s information is secure, and we never jeopardize the privacy of the user’s information." />
                </SwiperSlide>
                <SwiperSlide>  <FeatureCard image={CardImageChat} label={"Enhanced Engagement"} content="An digital card can be programmed with additional information, such as a link to your website, social media profiles, or a promotional video. This can help to enhance engagement with your brand and encourage potential clients to learn more about your business." />
                </SwiperSlide>
                <SwiperSlide>  <FeatureCard image={CardImageStarCard}
                  label={"Customize your own cards"}
                  content="An digital card can be programmed with additional information, such as a link to your website, social media profiles, or a promotional video. This can help to enhance engagement with your brand and encourage potential clients to learn more about your business."
                /></SwiperSlide>
              </Swiper>

              {/* </div> */}
            </div>
          </div>
        </div>
        <div className={Styles.offer}>
          <div className={Styles.offer_text}>
            <div className={Styles.container}>
              <div className={Styles.offer_row}>
                <div className={Styles.offer_col}>
                  <div className={Styles.offer_content}>
                    <h1>Our Collections</h1>
                    <p>Card Connect always delivers not just a card but a stylish and customizable solution which would be the game changer in marketing.</p>
                  </div>
                </div>
                <div className={Styles.offer_col} />
                <div className={Styles.offer_col}>
                  <img src={CollectionImage} alt="" />
                </div>
              </div>
              <Spacer space={100} />
              <div className={Styles.offer_row}>
                <div className={Styles.offer_col}>
                  <div className={Styles.offer_content}>
                    <h1>Our Mission</h1>
                    <p className={Styles.missionContent}>Our mission is to empower individuals and businesses with innovative technology solutions that simplify and enhance their daily lives. We have developed a range of digital card products that seamlessly integrate with your digital presence, making it easier than ever to share your contact details, social media profiles, and website with others.
                      Card Connect solutions are customizable and come in a variety of designs, allowing you to showcase your brand's identity and values. It is our mission to help businesses and individuals succeed in the digital age.</p>
                  </div>
                </div>
                <div className={Styles.offer_col} />
                <div className={Styles.offer_col}>
                  <img src={MissionImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.bottomVectorImage}>
          <img src={RoyalBottomStrip} />
        </div>
        <div className="px-2">
          <GetNfcCard />
        </div>
        <FooterZEEQR />
      </div>
    </>
  );
}

export default HomepageZeeqr



const FeatureCard = ({ label, image, content }) => {
  return <div className={Styles.feature_col}>
    <div className={Styles.feature_card}>
      <img src={image} alt="" />
      <button>
        {label}
      </button>
    </div>
    <p className={Styles.content}>
      {content}
    </p>
  </div>
}

