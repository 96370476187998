import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import QRCode, { QRCodeSVG } from 'qrcode.react';
import { PREVIEW_CARD_URL } from '../../utils/strings/Strings';
import LogoImage from '../../assests/imges/logo.png'
import html2canvas from 'html2canvas';

const QRcode = ({ value, isShow, onClose, }) => {



    const onImagedownload = () => {
        // const svg = document.getElementById("QRCode");
        // const svgData = new XMLSerializer().serializeToString(svg);
        // const canvas = document.createElement("canvas");

        // const ctx = canvas.getContext("2d");
        // const img = new Image();
        // img.onload = () => {
        //     canvas.width = img.width;
        //     canvas.height = img.height;
        //     ctx.drawImage(img, 0, 0);
        //     ctx.strokeRect(0, 0, canvas.width + 25, canvas.height + 25);
        //     const pngFile = canvas.toDataURL("image/png");
        //     const downloadLink = document.createElement("a");
        //     downloadLink.download = "QRCode";
        //     downloadLink.href = `${pngFile}`;
        //     downloadLink.click();
        // };
        // img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

        // const canvas = document.getElementById("qr-gen");

        // let ctx = canvas.getContext("2d");
        // ctx.fillStyle = 'blue';

        // const pngUrl = canvas?.toDataURL("image/png")?.replace("image/png", "image/octet-stream");
        // let downloadLink = document.createElement("a");
        // downloadLink.href = pngUrl;
        // downloadLink.download = `${value?.firstName}.png`;
        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);
        // console.log(canvas);


        /*var container = document.getElementById("image-wrap");*/ /*specific element on page*/
        var container = document.getElementById("htmltoimage");; /* full page */
        html2canvas(container, { allowTaint: true }).then(function (canvas) {
            var link = document.createElement("a");
            document.body.appendChild(link);
            link.download = value?.firstName + "-Card-Connect-QrCode" + ".png";
            link.href = canvas.toDataURL();
            link.target = '_blank';
            link.click();

        });
    };

    return (
        <>

            <Modal title={value?.firstName + " " + value?.lastName} open={isShow} onOk={onClose && onClose} onCancel={onClose && onClose} cancelButtonProps={{
                style: {
                    display: 'none'
                }
            }}
                okButtonProps={{
                    style: {
                        display: 'none'
                    }
                }}
            >
                <div className='w-full'>

                    <QRCodeSVG
                        value={window.location.host.includes("localhost") ? "http://localhost:3000/preview-card/" + value?._id : PREVIEW_CARD_URL + value?._id}
                        includeMargin={true} size={290}
                        className='m-auto'
                    />
                </div>
                <div className='flex items-center justify-center mt-5'>
                    <div>
                        <button className='_btn_ px-3' onClick={onImagedownload}>DOWNLOAD CODE</button>
                    </div>

                </div>
            </Modal>
            <div id='htmltoimage' >
                <div className='d-flex flex-column justify-center items-center mb-5 mt-5'>
                    <img src={LogoImage} width={200} className='mb-3' />
                    <h4 style={{ fontSize: 13 }}>Visit: https://cardconnect.ai</h4>
                </div>
                <QRCodeSVG
                    value={window.location.host.includes("localhost") ? "http://localhost:3000/preview-card/" + value?._id : PREVIEW_CARD_URL + value?._id}
                    includeMargin={true}
                    size={600}
                />
                <h1 style={{
                    fontSize: "30px",
                    textAlign: "center",
                    fontWeight: "600",
                    marginBottom: 5
                }}>{value?.firstName} {value?.lastName}</h1>
                <h2 style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontWeight: "600",
                }}>{value?.designation}</h2>
                <h2 style={{
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: "600",
                    marginBottom: 40
                }}>{value?.company}</h2>

            </div>
        </>
    );
};

export default QRcode;