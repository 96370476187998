import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaLeaf, FaTimes } from "react-icons/fa";

import Styles from "./styles.module.scss";

import Logo from '../../../assests/imges/logo-white.png'
import Span from "../../../assests/imges/span.png";
import Black_logo from "../../../assests/imges/logo.png"
import ParseJwt from '../../../services/parseJwt/parseJwt';
import Dropdown from '../../dropDown/DropDown';
import ActiveIndicator from '../../../assests/imges/vector/active-indicator.svg'



 let warningTimeout = 20 * 60 * 1000;
//let warningTimeout = 1000 * 60;
let warningTimerID;

function NavbarZEEQR({ page, isAuth }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSecure, setSecure] = useState(false)
    const [active, setActive] = useState("")
    const navigate = useNavigate()
const location = useLocation()
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const generateSassionId = async () => {
        if (localStorage.getItem("token")) {                  
            const currentTime = new Date();
            const sessionId = await localStorage.getItem("sessionId");                     
            if (sessionId) {
                let storeTime = new Date(sessionId)
                const differenceInMilliseconds = currentTime - storeTime;              
                const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
                const warningTimeoutMinutes = Math.floor(warningTimeout / (1000 * 60));
                await handleSessionLogout(differenceInMinutes, warningTimeoutMinutes).then(response => {
                    if (response) {
                        idealLogoutHandler();
                    } else {
                        localStorage.setItem("sessionId", currentTime);
                    }
                })              
            } else {
                localStorage.setItem("sessionId", currentTime);
            }
 
        }     
    }


    const handleSessionLogout =  async (differenceInMinutes, warningTimeoutMinutes) => {             
        if (differenceInMinutes > warningTimeoutMinutes) {              
            return true
        } else {
return false                        
        }
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    
    const handleLogout = (() => {    
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to Logout.',            
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            localStorage.clear()
                            isAuth && isAuth(); 
                            navigate('/');
                            setIsMenuOpen(false);
                            setSecure(false)
                                                                                   
                        } catch (error) {                           
                            localStorage.clear()
                            isAuth && isAuth()
                            navigate('/');
                            setIsMenuOpen(false);
                            setSecure(false)
                            
                        }
                    }
                },
                {
                    label: 'No',
                }
            ],           
        });

    })

    const [modal2Open, setModal2Open] = useState(false);    

    const idealLogoutHandler = useCallback( () => {

            try {
                localStorage.clear()
                isAuth && isAuth();
                navigate('/')

            } catch (error) {
                localStorage.clear()
                isAuth && isAuth()
                navigate('/')

            }

    }, [isAuth, navigate])
    

    
    const startTimer = useCallback(()=> {        
        // window.setTimeout returns an ID that can be used to start and stop the timer
        warningTimerID = window.setTimeout(idealLogoutHandler, warningTimeout);        
    }, [idealLogoutHandler])
    
    const resetTimer = useCallback(()=>{        
        window.clearTimeout(warningTimerID);
        startTimer();
        generateSassionId()
    }, [startTimer])
    
    const startCountdown = useCallback(() => {
        document.addEventListener("mousemove", resetTimer);
        document.addEventListener("mousedown", resetTimer);
        document.addEventListener("keypress", resetTimer);
        document.addEventListener("touchmove", resetTimer);
        document.addEventListener("onscroll", resetTimer);
        document.addEventListener("wheel", resetTimer);
        startTimer();
    }, [resetTimer, startTimer])

    useEffect(() => {
        setActive(location.pathname)        
         startCountdown()
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setIsOpen(false); // Adjust the breakpoint as needed
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Call the handler initially

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [location.pathname, startCountdown]);


    useEffect(() => {                
        window.addEventListener('load', generateSassionId);
        window.addEventListener('beforeunload', generateSassionId);
        if (localStorage.getItem('token') !== null) {
            let decodedToken = ParseJwt(JSON.stringify(localStorage.getItem('token')));
            if (decodedToken?._id._id) {
                setSecure(true)
            }
        } else {
            setSecure(false)
        }
        return () => {
              window.removeEventListener('beforeunload', generateSassionId);
        }
    }, [isSecure])


    return (
        <>
            {isSecure ? <nav className={Styles.s_navContainer}>
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="relative flex items-center justify-between">
                        <div className="absolute inset-y-0 right-0 flex items-center md:hidden" style={{ zIndex: 99999 }}>
                            <button
                                 onClick={toggleMenu}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                aria-expanded={isMenuOpen}
                            >
                                <span className="sr-only">Open main menu</span>
                                {isMenuOpen ? (
                                    <FaTimes  color={ "#fff"} size={20}/>
                                ) : (
                                        <FaBars  color={"#fff"} size={20}/>
                                )}
                                {/* {!isMenuOpen ? (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                )} */}
                            </button>
                        </div>
                        <div className="flex-1 flex items-center justify-between" style={{ zIndex: 9999 }}>
                            <div className="flex-shrink-0">
                                <Link to={"/"}>
                                    <img className={Styles.LogoImage} width={130} src={Logo} alt="" />
                                </Link>
                            </div>
                            <div className={Styles.s_navbar}>
                                <ul>
                                    <li>
                                        <Link to={"/"} className={Styles.s_nav_link}>Home</Link>
                                        {active === '/' && <img src={ActiveIndicator} alt="" className={Styles.ActiveIndicator} />}
                                    </li>
                                    <li>
                                        <Link to={"/my-card"} className={Styles.s_nav_link}>My Card</Link>
                                        {active === '/my-card' && <img src={ActiveIndicator} alt="" className={Styles.ActiveIndicator} />}
                                    </li>
                                    <li>
                                        <Link to={"/analytics"} className={Styles.s_nav_link}>Analytics</Link>
                                        {active === '/analytics' && <img src={ActiveIndicator} alt="" className={Styles.ActiveIndicator} />}
                                    </li>
                                </ul>                                                                


                            </div>
                            <div className="hidden md:block sm:ml-6" style={{ margin: "20px 0 20px 0px" }}>
                                <div>
                                    <nav className="flex items-center justify-between   ">
                                        <div className="w-full block flex-grow md:flex md:items-center md:w-auto h-16">                                         
                                            <Dropdown isAuth={isAuth} setSecure={()=>setSecure(!isSecure)} />
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden">
                        <div className={Styles.nav_menu}>
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            <Link
                                    to={"/"}                                
                                    onClick={toggleMenu}
                                    className={Styles.navLink}
                            >
                                Home
                            </Link>
                            <Link
                                to={"/my-card"}
                                    onClick={toggleMenu}                                    
                                    className={Styles.navLink}
                            >
                                My Card
                            </Link>
                                <Link
                                    to={"/analytics"}
                                    onClick={toggleMenu}
                                    className={Styles.navLink}
                            >
                                    Analytics
                                </Link>
                            <Link
                                to={"/profile"}
                                    onClick={toggleMenu}                                    
                                    className={Styles.navLink}
                            >
                                Profile
                            </Link>
                                <Link                                    
                                    className={Styles.navLink}
                                    onClick={handleLogout}                                      
                            >
                                Logout
                            </Link>
                            </div>
                        </div>
                    </div>
                )}
            </nav> : <header className={`${page === "selectCard"
                ? Styles.bg1
                : Styles.bg
                }`}>
                <div className={Styles.container}>
                    <div className="topnav" id="myTopnav">
                        <nav>
                            <Link to={"/"} className={Styles.navbar_brand}>
                                {isOpen ? (
                                        <img src={page === 'selectCard' ? Logo : Logo} alt="card-connect" width={180} />
                                ) : page === "about" || page === "contact" || page === "selectCard" ? (
                                    <>
                                                <img src={page === 'selectCard' ? Logo : Logo} alt="card-connect" width={180} className={Styles.showInMobile} />
                                        <img src={Logo} alt="card-connect" width={180} className={Styles.showInDesktop} />
                                    </>
                                ) : (
                                                <img src={page === 'selectCard' ? Logo : Logo} alt="card-connect" width={180} />
                                )}
                            </Link>
                            <div className={isOpen ? Styles.nav_menu : Styles.navv}>
                                <ul>
                                    <li>
                                        <Link
                                            to={"/"}
                                            className={`${page === "about" || page === "contact" || page === "selectCard"
                                                ? Styles.navbar1
                                                : Styles.navbar
                                                }`}
                                        >
                                            Home{" "}
                                            </Link>
                                            {active==='/' && <img src={ActiveIndicator} alt="" className={Styles.ActiveIndicator} />}
                                            
                                    </li>
                                    {/* <li>
                                        <Link
                                            to={"/pricing"}
                                            className={`${page === "about" || page === "contact" || page === "selectCard"
                                                ? Styles.navbar1
                                                : Styles.navbar
                                                }`}
                                        >
                                            Pricing{" "}
                                            </Link>
                                            {active === '/pricing' && <img src={ActiveIndicator} alt="" className={Styles.ActiveIndicator} />}
                                    </li> */}
                                    <li>
                                        <Link
                                            to={"/about"}
                                            className={`${page === "about" || page === "contact" || page === "selectCard"
                                                ? Styles.navbar1
                                                : Styles.navbar
                                                }`}
                                        >
                                            About Us
                                            </Link>
                                              {active==='/about' && <img src={ActiveIndicator} alt="" className={Styles.ActiveIndicator} />}
                                    </li>
                                    <li>
                                        <Link
                                            to={"/contact"}
                                            className={`${page === "about" || page === "contact" || page === "selectCard"
                                                ? Styles.navbar1
                                                : Styles.navbar
                                                }`}
                                        >
                                            Contact
                                            </Link>
                                              {active==='/contact' && <img src={ActiveIndicator} alt="" className={Styles.ActiveIndicator} />}
                                    </li>
                                    <li>                                       
                                        {/* <button
                                            className={`${page === "selectCard"
                                                    ? Styles.btn1
                                                    : Styles.btn
                                                }`}
                                            type="submit"
                                            onClick={() => {
                                                setModal2Open(true);
                                            }}
                                        >
                                            Create your card{" "}
                                            <span className="" style={{ margin: "20px" }}>
                                                <img src={Span} />
                                            </span>
                                            Log-In
                                        </button> */}
                                            <div className={Styles.btn_wrapper}>
                                        <div className={`${page === "selectCard"
                                            ? Styles.btn1
                                            : Styles.btn
                                                    }`}>
                                                    <Link

                                                        to='/login'

                                                        className={`${page === "selectCard"
                                                            ? 'hover:text-[#ffffff]'
                                                            : 'hover:text-[#795909]'
                                                            }`}
                                                    >
                                                        Log-In
                                                    </Link>
                                           
                                            <span className="" style={{ margin: "10px", opacity:0.6 }}>
                                                <img src={Span} alt='' />
                                            </span>
                                                    <Link
                                                        to='/select-card'
                                                        className={`${page === "selectCard"? 'hover:text-[#ffffff]': 'hover:text-[#795909]'}`}
                                                    >
                                                        Create your card
                                                    </Link>
                                        </div>
                                            </div>
                                        {/* <a
                                            href="javascript:void(0);"
                                            className="icon"
                                            onclick="myFunction()"
                                        >
                                            <i className="fa fa-bars"></i>
                                        </a> */}
                                    </li>
                                </ul>
                            </div>
                            <div
                                className={`${page === "about" || page === "contact"
                                    ? Styles.menu_icon1
                                    : Styles.menu_icon1
                                        }`}
                                    
                                    style={{ color: page === 'selectCard' ?"#fff":"#000"}}
                                    onClick={handleToggle}
                            >
                                {isOpen ? (
                                        <FaTimes className={Styles.fatimes} color={page === 'selectCard' ? "#fff" : "#fff"} />
                                ) : (
                                            <FaBars className={Styles.fabars} color={page === 'selectCard' ? "#fff" : "#000"} />
                                )}
                            </div>
                        </nav>
                    </div>
                </div>
            </header>}        
            {modal2Open ? (
                <>
                    <div
                        className="modal_overlay bg-black  "
                        id="modal"
                        style={{ zIndex: 1 }}
                    >
                        <button
                            className="cursor-pointer absolute top-0 right-0 mt-8  mr-5 text-white hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
                            onClick={() => setModal2Open(false)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-x"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="2.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                        {/* <div className="md:w-5/6 mt-10 relative p-5 ">
                            <LoginAndRegister />
                        </div> */}
                    </div>
                </>
            ) : null}
        </>
    );
}


export default NavbarZEEQR



// // import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Dropdown from "../dropDown/DropDown";
// import logo from "../../assests/zeeqr.png";
// // import { ShoppingCartOutlined } from "@ant-design/icons";
// import Logo from "../../assests/imges/zeeqr-logo.svg";
// import cartLogo from "../../assests/userImg/cartLogo.svg";

// import { Avatar, Badge, Space } from "antd";
// import { useSelector } from "react-redux";
// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
// import { getUserCreatedCard, userLogout } from "../../api/AdminRequest";
// import { clearCardData } from "../../Redux/Actions/CardAction";
// import { useDispatch } from "react-redux";
//import { set } from '../../../../node_modules/moment/src/lib/locale/set';

// const Navbar = () => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const { cartCount, cardCount } = useSelector((state) => state.userReducer);
//     const { authData } = useSelector((state) => state.authReducer);
//     // const [userCreatedCard,setUserCreatedCard] =useState('')
//     console.log(cartCount, '9999999999999999');

//     // useEffect(() => {
//     //   const getCardLimit = async () => {
//     //     try {
//     //       const { data } = await getUserCreatedCard()
//     //       console.log(data,'1234567890----'); 
//     //       setUserCreatedCard(data.card.length)
//     //     } catch (error) {
//     //       console.log(error);
//     //     }
//     //   }
//     //   getCardLimit()
//     // })

//     const [openTab, setOpenTab] = useState(1);

//     const toggleMenu = () => {
//         setIsMenuOpen(!isMenuOpen);
//     };

//     const handleLogout = () => {
//         confirmAlert({
//             title: "Confirm to submit",
//             message: "Are you sure to Logout.",
//             buttons: [
//                 {
//                     label: "Yes",
//                     onClick: async () => {
//                         try {
//                             const refToken = await localStorage.getItem("refToken");

//                             const { data } = await userLogout(refToken);
//                             console.log(data);
//                             if (data.success) {
//                                 dispatch(clearCardData());
//                                 localStorage.clear();
//                                 navigate("/");
//                             }
//                         } catch (error) {
//                             console.log(error.response.data);
//                             dispatch(clearCardData());

//                             localStorage.clear();
//                             navigate("/");
//                         }
//                     },
//                 },
//                 {
//                     label: "No",
//                 },
//             ],
//         });
//     };

//     return (
     
//     );
// };

// export default Navbar;
