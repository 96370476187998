import React from 'react'
import Styles from './styles.module.scss'
import { Link } from 'react-router-dom'
export default function GetNfcCard() {
    return (
        <div>
            <div className={Styles.get_your_card}>
                {/* <div className={Styles.white_bg} /> */}
                <div className={Styles.getyour_card_text}>
                    <div className={Styles.row_your_card}>
                        <div className="col">
                            <h1 className="">
                                Elevate your Brand
                            </h1>
                            <p className="">
                                With Card Connect
                            </p>
                            <Link to={"/select-card"}
                                style={{ textDecoration: "none" }}>
                                {" "}
                                <button
                                    type="button"
                                    className={Styles.btn_get_zeeqr}
                                    btn
                                >
                                    Get started
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
