
export const isValidLink = (value) => {
    if (value.includes("www.")) {
        let url = value.replace("www.", "https://")
        return url
    } else if (!value.includes("https://") && !value.includes("http://")) {
        let url = `https://${value}`;
        return url
    } else {
        return value
    }
}