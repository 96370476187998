import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../../../services/api/api-services';
import { toast } from 'react-hot-toast';
import { errorStyle } from '../../../../utils/strings/Strings';
import Styles from '../forgot-password/styles.module.scss'
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function ResetPassword() {
    const navigation = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [isDisabled, setDisabled] = useState(false)
    let query = useQuery();
    const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    const handleOtpValidation = (event) => {
        event.preventDefault();
        if (password === "" || confirmPassword === "") {
            if (password === "") {
                setPasswordError(true)
            } else {
                setPasswordError(false);
            }
            if (confirmPassword === "") {
                setConfirmPasswordError(true)
            } else {
                setConfirmPasswordError(false);
            }
            if (password !== confirmPassword) {
                setConfirmPasswordError(true)
            } else {
                setConfirmPasswordError(false)
            }
            return
        } else {
            handleFormSubmit();
        }

    }

    const handleFormSubmit = () => {
        let body = {
            "email": query.get("value"),
            "password": password
        }
        const toastId = toast.loading("Loading...")
        setDisabled(true)
        resetPassword(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId);
                toast.success(response?.msg)
                navigation('/')
                setDisabled(false)
            } else {
                toast.dismiss(toastId);
                toast.error(response?.msg);
                setDisabled(false)
            }
        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        if (!query.get("value")) {
            navigation('/')
        }
    }, [])


    const handleConfirmPassword = (event) => {
        setConfirmPassword(event?.target?.value)
        if (password === event?.target?.value) {
            setConfirmPasswordError(false)
        } else {
            setConfirmPasswordError(true)
        }
    }
    const handlePasswordShow = () => setShowPassword(!showPassword)
    return (
        <div className={Styles.loginwrap}>
            <div className={Styles.loginForm}>
                <div className={Styles.forgotPassword}>

                    <h3 >Reset password</h3>
                    <p className='mb-0'>Create new password</p>
                    <p> <b>{query.get("value")}</b></p>
                    <p className='mb-4'></p>
                </div>


                <form onSubmit={handleOtpValidation}>
                    <div className='form-group'>
                        <div className='form-control-wrap'>
                            <a onClick={handlePasswordShow} className='form-icon form-icon-right passcode-switch'>
                                <em className={`passcode-icon icon icon-show ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
                            </a>
                            <input
                                type={showPassword ? "text" : 'password'}
                                style={passwordError ? errorStyle : {}}
                                className={Styles.form_control}
                                id='default-01'
                                onChange={(event) => setPassword(event?.target?.value)}
                                placeholder='New Password' />
                            {passwordError ? <small className='invalid-feedback d-block'>Password must be filled out.</small> : password !== "" && !passRegex.test(password) ? <small className='invalid-feedback d-block'>Minimum 8 characters,  at least 1 uppercase and lowercase letter , 1 number and 1 special character</small> : ""}
                            {/* {passwordError && <small className='invalid-feedback d-block'>Password must be filled out.</small>} */}
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='form-control-wrap'>
                            <input
                                type='password'
                                style={confirmPasswordError ? errorStyle : {}}
                                className={Styles.form_control}
                                id='default-01'
                                onChange={handleConfirmPassword}
                                placeholder="Confirm Password" />
                            {confirmPasswordError && <small className='invalid-feedback d-block'>Confirm password do not match.</small>}
                        </div>
                    </div>
                    <button type='submit' disabled={isDisabled} className={Styles.btn_primary}>Submit</button>
                </form>
            </div>
        </div>

    )
}
