import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import HomepageZeeqr from './components/landing/homepage/Homepage';
import './App.css'
import {
  CardAnalytics,
  ForgotPassword,
  LoginPage,
  RegisterPage,
  SuccessPage,
  Analytics,
  VerifyOtp,
  ResetPassword,
  Profile,
  AuthLayout,
  MyCardTable,
  CreateQrCard,
  Setting
} from './routes/routes';



import About from './components/landing/about/About';
import Contact from './components/landing/contact/Contact';
import Pricing from './components/landing/pricing/Pricing';
import Page404 from './components/404/Page404';
import HomeLayout from './user/home/HomeLayout';
import CreateCard from './user/home/CreateCard';
import PreviewCard from './preview-card/PreviewCard';

export default function App() {
  const [isValid, setIsValid] = useState(false);
  const navigation = useNavigate();
  const location = useLocation()




  const handleValidForm = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      setIsValid(true);
      navigation('/my-card', { replace: true });
    } else {
      setIsValid(false);
      // navigation('/');
    }
  }, [])

  useEffect(() => {
    if (!location.pathname.includes('/preview-card')) {
      handleValidForm()
    }


    return () => { }
  }, [])


  return (
    <Routes>
      <Route path='/' element={<HomepageZeeqr isAuth={handleValidForm} />} />
      <Route path='/about' Component={About} />
      <Route path='/contact' Component={Contact} />
      <Route path='/pricing' Component={Pricing}/>
      <Route path='*' Component={Page404} />
      <Route path='/select-card' Component={HomeLayout}>
        <Route index Component={CreateCard} />
      </Route>
      <Route path='/preview-card/:cardId' Component={PreviewCard} />
      {!isValid ?
        <>
          <Route path='/login' Component={AuthLayout}>
            <Route index element={<LoginPage isAuth={handleValidForm} />} />
          </Route>
          <Route path='/forgot-password' Component={AuthLayout}>
            <Route index Component={ForgotPassword} />
          </Route>
          <Route path='/verify-otp' Component={AuthLayout}>
            <Route index Component={VerifyOtp} />
          </Route>
          <Route path='/reset-password' Component={AuthLayout}>
            <Route index Component={ResetPassword} />
          </Route>
          <Route path='/signup' Component={AuthLayout}>
            <Route index element={<RegisterPage isAuth={handleValidForm} />} />
            <Route path='success' element={<SuccessPage isAuth={handleValidForm} />} />
          </Route>
        </> :
        <>
          <Route path="/home" element={<HomeLayout isAuth={handleValidForm} />}>
            <Route index Component={CreateCard} />
            <Route path="my-card" Component={MyCardTable} />
          </Route>
          <Route path="/my-card" element={<HomeLayout isAuth={handleValidForm} />}>
            <Route index Component={MyCardTable} />
            <Route path='qr-code/:cardId' Component={CreateQrCard} />
            <Route path='update/:cardId' Component={CreateCard} />
          </Route>
          <Route path="/analytics" element={<HomeLayout isAuth={handleValidForm} />}>
            <Route index Component={Analytics} />
            <Route path=':cardId' Component={CardAnalytics} />
          </Route>
          <Route path="/profile" element={<HomeLayout isAuth={handleValidForm} />}>
            <Route index Component={Profile} />
          </Route>

        </>}
    </Routes>
  )
}
