import { Autocomplete, GoogleMap, LoadScript, } from "@react-google-maps/api"
import React, { Component } from "react"
import { GOOGLE_MAP_KEY } from "../../services/api/api-path"


class MyMapWithAutocomplete extends Component {
    constructor(props) {
        super(props)
        this.autocomplete = null
        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
    }

    onLoad(autocomplete) {
        this.autocomplete = autocomplete
    }
    getCity = (value) => {
        //locality
        let city = ""
        if (value) {
            value?.address_components.forEach((element) => {
                if (element?.types[0] === 'locality') {
                    city = element?.long_name
                }
            })
        }
        return city
    }
    getCountry = (value) => {
        //country
        let country = ""
        if (value) {
            value?.address_components.forEach((element) => {
                if (element?.types[0] === 'country') {
                    // this.setState({ country: element?.long_name })
                    country = element?.long_name
                }
            })
        }
        return country
    }
    getState = (value) => {
        //administrative_area_level_1
        let state = ""
        if (value) {
            value?.address_components.forEach((element) => {
                if (element?.types[0] === 'administrative_area_level_1') {
                    // this.setState({ state: element?.long_name })
                    state = element?.long_name
                }
            })
        }
        return state;
    }
    getPostalCode = (value) => {
        // Postal code
        let postalCode = ""
        if (value) {
            value?.address_components.forEach((element) => {
                if (element?.types[0] === 'postal_code') {
                    // this.setState({ state: element?.long_name })
                    postalCode = element?.long_name
                }
            })
        }
        return postalCode;
    }
    getLat = (value) => {
        let latitude = 0;
        if (value) {
            latitude = value?.geometry?.location?.lat();
        }
        return latitude
    }
    getLong = (value) => {
        let longitude = 0;
        if (value) {
            longitude = value?.geometry?.location?.lng();
        }
        return longitude
    }

    onPlaceChanged() {

        if (this.autocomplete !== null) {
            if (this.props.onChange) {
                this.props.onChange({
                    address: this.autocomplete.getPlace().formatted_address,
                    // city: this.getCity(this.autocomplete.getPlace()),
                    // state: this.getState(this.autocomplete.getPlace()),
                    // country: this.getCountry(this.autocomplete.getPlace()),
                    // postalCode: this.getPostalCode(this.autocomplete.getPlace()),
                    // lat: this.getLat(this.autocomplete.getPlace()),
                    // long: this.getLong(this.autocomplete.getPlace()),
                })
            }
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }




    render() {
        return (<LoadScript googleMapsApiKey={GOOGLE_MAP_KEY} libraries={['places']}>
            <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged}>
                <input type="text" placeholder="Full address" className="form-control"
                />
            </Autocomplete>
        </LoadScript>
        )
    }
}

export default MyMapWithAutocomplete