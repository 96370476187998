import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { SiteName, errorStyle } from '../../../../utils/strings/Strings'
import { toast } from 'react-hot-toast';
import { signUp } from '../../../../services/api/api-services';
import { FiX } from "react-icons/fi";
import { Checkbox, Modal } from 'antd';
import Styles from './styles.module.scss'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'
import { getCountryCallingCode } from 'react-phone-number-input';

const userType = [
    {
        value: "organization",
        label: "Organization",
    },
    {
        value: "individual",
        label: "Individual",
    }
]


export default function Register({ isAuth }) {
    const navigation = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [checkedS, setCheckedS] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [disable, setDisable] = useState(false)
    const [isPasswordMatch, setIsPasswordMatch] = useState("")
    const [countryCode, setCountryCode] = useState("IN")
    const [isAgreement, setAgreement] = useState(false)
    const [isAgreementError, setAgreementError] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [industry, setIndustry] = useState("");
    const [industryError, setIndustryError] = useState(false)

    const [organizationNameError, setOrgnizationNameError] = useState(false)
    const [organizationName, setOrgnizationName] = useState("")

    const [isUserType, setUserType] = useState("organization")

    const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@#$!%*?&]{8,}$/
    const phoneRegex = /^!*([0-9]!*){10,}$/

    const onChangeS = (e) => {
        setCheckedS(e.target.checked);
        navigation("/login");
    };

    const handleFormValidation = (event) => {
        event.preventDefault()
        if (firstName === "" || lastName === "" || email === "" || password === "" || !passRegex.test(password) || phoneNumber === "" || !isAgreement || industry === "") {
            if (firstName === "") {
                setFirstNameError(true)
            } else {
                setFirstNameError(false)
            }
            if (lastName === "") {
                setLastNameError(true)
            } else {
                setLastNameError(false)
            }
            if (email === "") {
                setEmailError(true)
            } else {
                setEmailError(false)
            }
            if (password === "") {
                setPasswordError(true)
            } else {
                setPasswordError(false)
            }
            if (password === "") {
                setPasswordError(true)
            } else {
                setPasswordError(false)
            }
            if (phoneNumber === "") {
                setPhoneNumberError(true)
            } else {
                setPhoneNumberError(false)
            }
            if (!isAgreement) {
                setAgreementError(true)
            } else {
                setAgreementError(false)
            }
            if (industry === "") {
                setIndustryError(true)
            } else {
                setIndustryError(false)
            }
            return
        } else {
            handleFormSubmit();
        }
    }

    const handleFormSubmit = () => {

        const data = {
            firstName,
            lastName,
            email,
            password,
            countryCode: "+" + getCountryCallingCode(countryCode),
            phone: phoneNumber.replace("0", "").replace(" ", ""),
            industries: industry,
        }
        const toastId = toast.loading('Registering...');
        signUp(data).then((response) => {
            if (response?.err === false) {
                localStorage.setItem("token", response?.token);
                isAuth && isAuth()
                navigation('/home');
                toast.dismiss(toastId);
                toast.success("Account created successfully!");
                resetStates();
            } else {
                toast.error(response?.msg);
                toast.dismiss(toastId);
            }
        }).catch((err) => console.log(err));
    }

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value)
        if (firstName !== "") {
            setFirstNameError(false)
        }
    }

    const handleLastNameChange = (event) => {
        setLastName(event.target.value)
        if (lastName !== "") {
            setLastNameError(false)
        }
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        if (email !== "") {
            setEmailError(false)
        }
    }

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(formatPhoneNumber(value))

        if (phoneNumber !== "") {
            setPhoneNumberError(false)
        }
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
        if (password !== "") {
            setPasswordError(false)
        }
    }

    const handleConfirmPassword = () => {
        if (password !== confirmPassword) {
            setIsPasswordMatch(true)
        } else {
            setIsPasswordMatch(false)
        }
    }

    const handleIndustryChange = (event) => {
        setIndustry(event.target.value)
        if (industry !== "") {
            setIndustryError(false)
        }
    }

    const handleOrganizationNameChange = (event) => {
        setOrgnizationName(event.target.value)
        if (organizationName !== "") {
            setOrgnizationNameError(false)
        }
    }

    const handlePasswordShow = () => setShowPassword(!showPassword)

    const resetStates = () => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setPassword("")
        document.querySelector('form').reset()
    }

    const handleAgreement = () => {
        setAgreement(!isAgreement)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className={Styles.loginwrap}>
                <div className={Styles.loginForm}>
                    <div className={Styles.signin}>
                        <h3 className='text-white'>Please signup to continue</h3>
                        <Link to={'/'} className={"text-white"}>
                            <FiX className={Styles.fix} />
                        </Link>
                    </div>
                    <div className={Styles.checkbox}>
                        <div>
                            <div className={Styles.Privacy}>
                                <Checkbox checked={true}>   <span style={{ color: "white" }}>I’m a New User </span></Checkbox>
                            </div>
                        </div>
                        <div className={Styles.check}>
                            <div className={Styles.Privacy}>
                                <Checkbox checked={checkedS} onChange={onChangeS}>
                                    <span style={{ color: "white" }}>  I’m an existing user </span>
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                    <form autoComplete='off' id='login-form' onSubmit={handleFormValidation}>
                        <div className='form-group'>
                            <div className='form-control-wrap'>

                                <select className={Styles.form_control} onChange={(event) => setUserType(event.target.value)}>
                                    {userType.map((item, index) => <option value={item.value} key={index.toString()}>{item.label}</option>)}
                                </select>
                            </div>
                        </div>
                        {isUserType === "individual" && <>
                            <div className='form-group'>
                                <div className='form-control-wrap'>
                                    <input
                                        style={firstNameError ? errorStyle : {}}
                                        type='text'
                                        className={Styles.form_control}
                                        id='first-name'
                                        autoComplete='off'
                                        role='presentation'
                                        placeholder='First name'
                                        onChange={handleFirstNameChange}
                                        value={firstName}
                                    />
                                    {firstNameError && <small className='invalid-feedback d-block'>first name must be filled out.</small>}
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='form-control-wrap'>
                                    <input
                                        style={lastNameError ? errorStyle : {}}
                                        type='text'
                                        className={Styles.form_control}
                                        id='last-name'
                                        autoComplete='off'
                                        role='presentation'
                                        placeholder='Last name'
                                        onChange={handleLastNameChange}
                                        value={lastName}
                                    />
                                    {lastNameError && <small className='invalid-feedback d-block'>Last name must be filled out.</small>}
                                </div>
                            </div></>}
                        {isUserType === "organization" && <>
                            <div className='form-group'>
                                <div className='form-control-wrap'>
                                    <input
                                        style={organizationNameError ? errorStyle : {}}
                                        type='text'
                                        className={Styles.form_control}
                                        id='organization-name'
                                        autoComplete='off'
                                        role='presentation'
                                        placeholder='Organization name'
                                        onChange={handleOrganizationNameChange}
                                        value={organizationName}
                                    />
                                    {organizationNameError && <small className='invalid-feedback d-block'>Organization name must be filled out.</small>}
                                </div>
                            </div>
                        </>}

                        <div className='form-group'>
                            <input
                                style={emailError ? errorStyle : {}}
                                type='email'
                                className={Styles.form_control}
                                id='email'
                                autoComplete='off'
                                role='presentation'
                                placeholder='Email address'
                                onChange={handleEmailChange}
                                value={email} />
                            {emailError && <small className='invalid-feedback d-block'>Email must be filled out.</small>}
                        </div>
                        <div className='form-group'>
                            <PhoneInput
                                placeholder="Phone number"
                                className={Styles.form_control}
                                style={phoneNumberError ? errorStyle : {}}
                                defaultCountry='IN'
                                countryCallingCodeEditable
                                onCountryChange={(value) => setCountryCode(value)}
                                onChange={handlePhoneNumberChange} />
                            {phoneNumberError && <small className='invalid-feedback d-block'>Phone must be filled out.</small>}
                        </div>
                        <div className='form-group'>
                            <input
                                style={industryError ? errorStyle : {}}
                                type='text'
                                className={Styles.form_control}
                                id='industry'
                                autoComplete='off'
                                role='presentation'
                                placeholder='Enter Industry'
                                onChange={handleIndustryChange}
                                value={industry} />
                            {industryError && <small className='invalid-feedback d-block'>Industry must be filled out.</small>}
                        </div>
                        <div className='form-group'>
                            <a onClick={handlePasswordShow} className='form-icon form-icon-right passcode-switch'>
                                <em className={`passcode-icon icon icon-show ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
                            </a>
                            <input
                                role="presentation"
                                autoComplete="new-password"
                                style={passwordError ? errorStyle : {}}
                                type={showPassword ? "text" : 'password'}
                                className={Styles.form_control}
                                id='password'
                                onChange={handlePasswordChange}
                                placeholder='Password'
                                value={password}
                            />
                            {passwordError ? <small className='invalid-feedback d-block'>Password must be filled out.</small> : password !== "" && !passRegex.test(password) ? <small className='invalid-feedback d-block'>Minimum 8 characters,  at least 1 uppercase and lowercase letter , 1 number and 1 special character</small> : ""}
                        </div>
                        <div className='form-group'>
                            <a onClick={handlePasswordShow} className='form-icon form-icon-right passcode-switch'>
                                <em className={`passcode-icon icon icon-show ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
                            </a>
                            <input
                                role="presentation"
                                autoComplete="new-password"
                                style={passwordError ? errorStyle : {}}
                                type={showPassword ? "text" : 'password'}
                                className={Styles.form_control}
                                id='password'
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                onKeyUp={handleConfirmPassword}
                                placeholder='Confirm password'
                                value={confirmPassword} />
                            {isPasswordMatch && <small className='invalid-feedback d-block'>Password must be filled out.</small>}
                        </div>
                        <div className='form-group'>
                            <Checkbox onChange={handleAgreement} checked={isAgreement}>
                                <span style={{ color: "white" }}> Accept the <Link onClick={showModal}> Terms of Use and Privacy Policy.</Link> </span>
                            </Checkbox>
                            {isAgreementError && <small className='invalid-feedback d-block'>Should accept terms and conditions</small>}
                        </div>
                        <div className='form-group'>
                            <button disabled={disable} type='submit' className={Styles.btn_primary}>
                                Signup
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal title="Terms and Conditions" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} bodyStyle={{ height: '400px' }}  >
                <div className="h-[400px] overflow-auto scrollbar-hide">
                    <p>At {SiteName}, we are committed to protecting the privacy and security of your personal information.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vehicula purus at dui dictum ullamcorper. Morbi molestie libero efficitur, ultricies ante quis, laoreet urna. Duis ultricies massa auctor ultricies pretium. Cras faucibus ut ex quis gravida. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In velit mauris, pulvinar vel finibus ut, pulvinar eget justo. Donec pretium tellus ut eros vehicula, id faucibus dolor egestas. Sed justo mi, rhoncus sed vehicula id, sollicitudin vel tellus. Vestibulum sit amet purus eget lorem tincidunt laoreet. Integer ac interdum sem. Duis tincidunt efficitur mi, ut viverra risus pharetra ac.</p>
                    <p>Suspendisse gravida libero ac molestie sodales. Proin euismod mi turpis, et efficitur nibh rhoncus in. Donec tempor tincidunt lectus nec pulvinar. In rutrum tristique lorem, at vehicula ante maximus ut. Ut tempor iaculis sollicitudin. Nulla non ornare massa, non tempus mi. Phasellus convallis, odio vitae tincidunt vulputate, libero odio scelerisque elit, id gravida lorem quam et nunc. Quisque ex mauris, mollis ac vestibulum sit amet, iaculis quis justo. Aenean gravida augue sed laoreet bibendum. Nulla a tristique nisl. In ullamcorper lacinia consectetur.</p>
                    <p>Aliquam quis est mattis augue molestie tincidunt. Sed luctus augue urna, at dictum eros laoreet in. Cras nec mi metus. Fusce ac lorem orci. Praesent quam libero, dignissim bibendum lectus sed, consectetur rhoncus dui. Proin sagittis faucibus luctus. Aliquam varius dui in fringilla convallis. Aenean vitae vulputate nisi, sed ullamcorper quam. Nulla rhoncus efficitur lacus, id egestas dolor malesuada vel. Nulla vel fermentum velit, eget euismod neque. Fusce venenatis ut orci tincidunt imperdiet. Morbi bibendum eros pharetra nibh scelerisque auctor. Duis nec erat et justo dignissim sagittis.</p>
                </div>
            </Modal>
        </>
    )
}
