import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LOGO } from '../../../../utils/images/Images'
import { forgotPassword } from '../../../../services/api/api-services';
import { toast } from 'react-hot-toast';
import { errorStyle } from '../../../../utils/strings/Strings';
import Styles from './styles.module.scss'
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'
export default function ForgotPassword() {
    const navigation = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [phone, setPhone] = useState("")
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    const handleFormValidation = (event) => {
        event.preventDefault()
        if (email !== "") {
            setEmailError(false);
            handleFromSubmit();
        } else {
            setEmailError(true);
        }
    }


    const handleFromSubmit = () => {
        let body = {
            "email": email
        }
        setDisabled(true)
        const toastId = toast.loading('Sending...');
        forgotPassword(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId);
                toast.success(response?.msg);
                navigation(`/verify-otp?value=${email}`);
                setDisabled(false);
            } else {
                toast.dismiss(toastId);
                toast.error(response?.msg);
                setDisabled(false)
            }
        }).catch(err => console.log(err))
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        if (email !== "") {
            setEmailError("")
        }
    }

    return (
        <div className={Styles.loginwrap}>
            <div className={Styles.loginForm}>

                <div className={Styles.forgotPassword}>

                    <h3 >Forgot password</h3>

                    <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>

                </div>

                <form onSubmit={handleFormValidation}>
                    <div className='form-group'>
                        <div className='form-control-wrap'>
                            <input
                                type='text'
                                style={emailError ? errorStyle : {}}
                                className={Styles.form_control}
                                id='default-01'
                                onChange={handleEmailChange}
                                disabled={phone !== ""}
                                placeholder='Email address or Phone number' />
                            {emailError ? <small className='invalid-feedback d-block'>Please enter a valid phone number or email address.</small> : ""}
                        </div>
                    </div>
                    <div className='form-group'>
                        <button disabled={isDisabled} type='submit' className={Styles.btn_primary}>
                            Send OTP
                        </button>
                    </div>
                </form>
                <div className='form-note-s2 text-center pt-4'>
                    <Link to={'/login'}><strong>Return to login</strong></Link>
                </div>
            </div>
        </div>

    )
}
