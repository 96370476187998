import { Modal } from 'antd'
import React, { useState } from 'react'
import RJCalender from 'react-calendar'


export default function Calender({ onChange, isVisible, onClose, startDate, endDate }) {
  const [select, setSelect] = useState("")
  return (
    <Modal open={isVisible} onCancel={onClose}
      closeIcon={() => <div></div>}
      style={{

      }}
      wrapClassName='theme-modal-wrapper calender-footer'
      centered
      width={400}
      okButtonProps={{
        onClick: () => onClose()
      }}
    >
      <div style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 15
      }}>
        <button onClick={() => setSelect("start")} className={`date-btn ${select === 'start' && 'active'}`}>Start Date</button>
        <button onClick={() => setSelect("end")} className={`date-btn ${select === 'end' && 'active'}`}>  End Date</button>
      </div>
      {select === 'start' ? <RJCalender
        defaultValue={startDate}
        className={"rj-calender"}
        onChange={(value) => onChange("start", value)} value={startDate} /> : <RJCalender
        defaultValue={endDate}
        className={"rj-calender"}
        onChange={onChange} value={endDate} />}
    </Modal>
  )
}
