//BASE URL
export const HEADER_IN_JSON = {
    "Accept": "application/json",
    "Content-Type": "application/json; charset=utf-8",
}


export const HEADER_IN_JSON_IMAGE = {
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*",
}

const API = process.env.REACT_APP_BASE_URL;

export const GOOGLE_MAP_KEY = "AIzaSyD-SlQGUQRZgEcXPqVqcjlQ5yImFSHC9GU";

export const SIGN_UP = `${API}user/signup`
export const SIGN_IN = `${API}user/login`
export const FORGOT_PASSWORD = `${API}user/forgotPassword/`
export const RESET_PASSWORD = `${API}user/resetPassword/`
export const CHANGE_PASSWORD = `${API}user/changePassword/`
export const VERIFY_OTP = `${API}user/verifyOTP/`
export const GET_TEMPLATE = `${API}template/getAll/`
export const CREATE_SETUP_CARD = `${API}card/create`
export const UPDATE_USER = `${API}user/update/`
export const GET_USER_PROFILE_BY_ID = `${API}user/getById/`


export const CREATE_CARD_COLOR = `${API}card/updateColor`

export const GET_MY_CARDS = `${API}card/getByUserId/`

export const GET_TEMPLATE_BY_ID = `${API}template/getById/`
export const GET_PROFILE = `${API}user/getById/`
export const DELETE_CARD = `${API}card/deleteCard/`
export const UPDATE_CARD = `${API}card/update/`
export const GET_CARD_BY_ID = `${API}card/getById/`


export const GET_LOCATION_DATA_BY_USER_ID = `${API}cardView/getByUserId/`
export const GET_LOCATION_DATA_BY_CARD_ID = `${API}cardView/getByCardId/`
export const GET_COUNT_BY_CARD_ID = `${API}cardView/getByCardId/`

export const GET_TOTAL_COUNT = `${API}cardView/getCountByUserId/`


export const GET_GRAPH_BY_DATE = `${API}cardView/getViewCount`
export const GET_CARD_VIEW_BY_DATE = `${API}cardView/getViewCountCard`