import { useRef } from 'react';
import Styles from "./styles.module.scss";
import { Link } from 'react-router-dom'
import Locationcontact from '../../../assests/imges/locationcontact.png'
import Contactcontact from '../../../assests/imges/contactcontact.png'
import Emailcontact from '../../../assests/imges/emailcontact.png'
import Contactfacebook from '../../../assests/imges/contactfacebook.svg'
import Contactwhatsapp from '../../../assests/imges/contactwhatsapp.svg'
import Contactlinkdin from '../../../assests/imges/contactlinkdin.svg'
import Contactinst from '../../../assests/imges/contactinst.svg'
import emailjs from "@emailjs/browser";
import toast from 'react-hot-toast';
import NavbarZEEQR from '../navbar/NavbarZEEQR';
import FooterZEEQR from '../footer/FooterZEEQR';
import { SiteName } from '../../../utils/strings/Strings';

function Contact() {
    const notify = () => toast("We got your response!");
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        // emailjs
        //     .sendForm(
        //         "service_r7ry5ja",
        //         "template_ieyjzg5",
        //         form.current,
        //         "Lg17ksAcKHZsbQLwI"
        //     )
        //     .then(
        //         (result) => {
        //             console.log(result.text);
        //             notify()
        //             setTimeout(() => {
        //                 window.location.href = "/"
        //             }, 8000);

        //         },
        //         (error) => {
        //             console.log(error.text);
        //         }
        //     );
    }

    return (
        <div className={Styles.wrapper}>

            <section>
                <div className={Styles.header_bg}>
                    <div className={Styles.header1}>

                        <NavbarZEEQR page={'contact'} />
                        <div className={Styles.about_banner} >
                            <div className={Styles.innerPagebanner}>
                                <div className={Styles.inner_banner_text}>
                                    <h2 className={Styles.blog_banner_text1}>Contact</h2>
                                    <h2 className={Styles.blog_banner_text2}><a href="/home">Home</a> - Contact</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <div className={Styles.mainContainer}>
                <section className={Styles.contact_section}>
                    <div className={Styles.container}>
                        <div className={Styles.contact}>
                            <h2 className={Styles.contact_text}>
                                GET IN TOUCH WITH US
                            </h2>
                            <h2 className={Styles.contact_text1}>
                                We're Always Eager To Hear From You!
                            </h2>
                            <h2 className={Styles.contact_text2}>We’d be happy to receive your feedback on our products. It would be really
                                appreciable if you could share your experience with us. And we assure you that our service is always
                                available to enhance your experience. </h2>
                        </div>
                    </div>

                    <div className={Styles.container} style={{ marginTop: 50 }}>

                        <div className={Styles.contact_row}>
                            <div className={Styles.contact_column}>
                                <form ref={form} onSubmit={sendEmail} name="contactform" method="POST" data-netlify="true">
                                    <input type="text" name="name" required placeholder="Full Name" style={{ marginTop: "12px" }} />
                                    <input type="email" name="email" autocapitalize="none" required placeholder="Email" style={{ marginTop: "12px" }} />
                                    <input type="number" name="phone" required placeholder="Phone" style={{ marginTop: "12px" }} />
                                    <div className={Styles.textcontact}>

                                        <textarea name="message" placeholder="Message" style={{ marginTop: "12px" }} ></textarea>
                                    </div>
                                    <button className={Styles.btn} type="submit">Send</button>
                                </form>
                            </div>
                            <div className={Styles.contact_column}>
                                <div className={Styles.contact_bg}>
                                    <h1 className={Styles.contact_text3}>GET IN TOUCH</h1>
                                    <h1 className={Styles.contact_text4}>Contact Us</h1>
                                    <h1 className={Styles.contact_text5}>Why wait? Make your card now and stay updated.</h1>
                                    {/* <div className={Styles.rowcontact} >
                                    <div className={Styles.contact_column_text}  >
                                        <img src={Locationcontact} />

                                    </div>
                                    <div className={Styles.contact_column_text}  >
                                        <p className={Styles.contact_location} style={{ marginTop: "20px" }} >
                                            216 A Al Qiyadha Budling,
                                            <br></br>
                                            Dubai, United Arab Emirates.</p>

                                    </div>
                                </div> */}
                                    <div className={Styles.rowcontact} >
                                        <div className={Styles.contact_column_text} >
                                            <img src={Contactcontact} />

                                        </div>
                                        <div className={Styles.contact_column_text} >
                                            <p className={Styles.contact_location} style={{ marginTop: "20px" }} >
                                                +987 3211 4562</p>

                                        </div>

                                    </div>
                                    <div className={Styles.rowcontact} >

                                        <div className={Styles.contact_column_text} >
                                            <img src={Emailcontact} />

                                        </div>
                                        <div className={Styles.contact_column_text} >
                                            <p className={Styles.contact_location} style={{ marginTop: "20px" }}  >
                                                <Link to={"/"}>sales@cardconnect.ai
                                                </Link>
                                                <br></br>
                                                <Link to={"/"}> info@cardconnect.ai</Link>
                                                <br></br>

                                            </p>



                                        </div>
                                    </div>
                                    <div className={Styles.contact_icon} style={{ display: "flex", justifyContent: "end", gap: "10px" }} >
                                        <Link to={"https://www.facebook.com/"} target='_blank'><img src={Contactfacebook} /></Link>
                                        <Link to={"https://whatsapp.com"} target='_blank'> <img
                                            src={Contactwhatsapp} /></Link>
                                        <Link to={"https://www.instagram.com/"} target='_blank'> <img src={Contactlinkdin} /></Link>
                                        <Link to={"https://www.linkedin.com/"} target='_blank'><img src={Contactinst} /></Link>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>
                <section className={Styles.profiles1}>
                    <div className="" style={{ textAlign: 'center' }}>
                        <h2 className={Styles.small} style={{ marginBottom: "10px" }}>Professionals <span>.</span> Entrepreneurs <span>.</span> freelancers <span>. </span>
                            individuals </h2>
                        <p className={Styles.nfc_text}>{SiteName}  Smart Business cards are useful for anyone who convenes a meeting and
                            wishes to make a network.</p>
                    </div>
                </section>



            </div>
            <FooterZEEQR page={'contact'} />
        </div>
    )
}

export default Contact