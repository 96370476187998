import React from 'react'
import CardTemplate1 from './template1/CardTemplate';
import CardTemplate2 from './template2/CardTemplate';
import CardTemplate3 from './template3/CardTemplate';
import CardTemplate4 from './template4/CardTemplate';
import CardTemplate5 from './template5/CardTemplate';
import CardTemplate6 from './template6/CardTemplate';
import CardTemplate7 from './template7/CardTemplate';
import CardTemplate8 from './template8/CardTemplate';
import CardTemplate9 from './template9/CardTemplate';
/**
 * 
 * @param {{
 * data:{
        *firstName: string,
        *lastName: string,
        *prefix: string,
        *suffix: string,
        *designation: string,
        *department: string,     
        *company: string,
        *summary: string,
        *phones:Object,
        *emails: Object,
        *website: Object,
        *customLinks: Object,
        *socialMediaLinks: Object,
        *location: string, 
        *logoImagePreview: string,
        *userImagePreview:string,
        *primaryColor: string,
        *profileTextColor: string,
        *secondaryColor: string,
        *fontFamily: string,
        *titleFontSize: string,
        *isDownload: Boolean,
        *isLocation: Boolean,
        *previewCardId: string,
        *backgroundImage:string 
 * }
 * selectedTemplate: Number,
 * onClickShareButton: Function,
 * onClickAddToContactButton: Function,
 * }} props 
 */

export default function TemplateRoute({ selectedTemplate, data, onClickShareButton, onClickAddToContactButton, isMobileView, isDrawer }) {    
    switch (selectedTemplate) {
        case 1:
            return <CardTemplate1 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} isDrawer={isDrawer} />
        case 2:
            return <CardTemplate2 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} isDrawer={isDrawer} />
        case 3:
            return <CardTemplate3 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} isDrawer={isDrawer} />
        case 4:
            return <CardTemplate4 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} isDrawer={isDrawer} />
        case 5:
            return <CardTemplate5 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} isDrawer={isDrawer} />
        case 6:
            return <CardTemplate6 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} isDrawer={isDrawer} />
        case 7:
            return <CardTemplate7 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} isDrawer={isDrawer} />
        case 8:
            return <CardTemplate8 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} />
        case 9:
            return <CardTemplate9 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} />
        default:
            <CardTemplate1 data={data} onClickShareButton={onClickShareButton && onClickShareButton} onClickAddToContactButton={onClickAddToContactButton && onClickAddToContactButton} isMobileView={isMobileView} isDrawer={isDrawer} />
    }
}




