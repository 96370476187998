import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import NavbarZEEQR from '../../components/landing/navbar/NavbarZEEQR'
import Footer from '../../components/landing/footer/FooterZEEQR'
import { useDispatch, useSelector } from 'react-redux'
import { profile, selectProfile } from '../../redux/action/profileData'
import { getProfileById } from '../../services/api/api-services'
import ParseJwt from '../../services/parseJwt/parseJwt';

export default function HomeLayout({ isAuth }) {
    const [userData, setUserData] = useState();
    const profileData = useSelector(selectProfile)
    const dispatch = useDispatch()
    const decodedToken = () => {
        if (localStorage.getItem("token")) {
            const decoded = ParseJwt(localStorage.getItem("token"));
            setUserData(decoded?._id);
            handleUserProfile(decoded?._id);
        }

    };
    // handle user profile data in redux store;
    const handleUserProfile = (userId) => {
        getProfileById(userId?._id).then((response) => {
            if (response?.err === false) {
                dispatch(profile(response?.user))

            } else {
                // toast.success(response?.msg);
            }
        }).catch(err => console.log(err))
    }
    useEffect(() => {
        decodedToken();
    }, []);



    return (
        <div style={{ background: "#151515" }}>
            <NavbarZEEQR page={"selectCard"} isAuth={isAuth} />            
            <Outlet />            
            <Footer />
        </div>
    )
}
