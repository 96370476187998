import React, { useState } from 'react'
import Styles from "./styles.module.scss"

import Footerlogo from '../../../assests/imges/logo-white.png'
import Facebookicon from '../../../assests/imges/facebookicon.svg'
import Instaicon from '../../../assests/imges/instaicon.svg'
import Twittericon from '../../../assests/imges/twittericon.svg'
import Linkdinicon from '../../../assests/imges/linkdinicon.svg'
import Whatsappicon from '../../../assests/imges/whatsappicon.svg'
import Locattion_zeeqr from '../../../assests/imges/locattion_zeeqr.svg'
import Email_zeeqr from '../../../assests/imges/email_zeeqr.svg'
import Phone_zeeqr from '../../../assests/imges/phone_zeeqr.svg'
import Timer_zeeqr from '../../../assests/imges/timer_zeeqr.svg'
import Footerimg1 from '../../../assests/imges/footerimg1.png'
import Footerimg2 from '../../../assests/imges/footerimg2.png'
import Footerimg3 from '../../../assests/imges/footerimg3.png'
import Footerimg4 from '../../../assests/imges/footerimg4.png'
import Whatsappgif from '../../../assests/imges/whatsapp-gif.gif'

import Topbutton from '../../../assests/imges/topbutton.svg'
import { Link } from 'react-router-dom'
import Tagline from '../components/tagline/Tagline'


function Footer({ page }) {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>

            <footer className={Styles.footer}>

                <div className={Styles.container}>
                    <div className={Styles.footer_zeeqr}>
                        <div className={Styles.footercol}>
                            <div className={Styles.colfooter}>
                                <div className={Styles.footer_logo}>

                                    <img src={Footerlogo} width={180} />
                                    <Tagline />
                                    <p className={Styles.footer_logo_text} style={{ paddingTop: "25px" }}>We value you. Stay connected with us
                                        for the latest update from
                                        Card Connect</p>
                                    <div className={Styles.colfooter}>
                                        <div className={Styles.social_address}>
                                            <p className={Styles.social_address_p}>Get In Touch</p>
                                            <div className={Styles.social_address1} style={{ display: "flex" }}>
                                                <p className="text-white" style={{ marginTop: 8 }}>
                                                    <a href={"mailto:sales@cardconnect.ai"}>sales@cardconnect.ai</a>
                                                    <br></br>
                                                    <a href={"mailto:info@cardconnect.ai"}>info@cardconnect.ai</a></p>

                                            </div>
                                            {/* <div className={Styles.social_address1} style={{ display: "flex" }}>

                                                <img className=" " style={{ marginBottom: "20px" }} src={Timer_zeeqr} />
                                                <p className="text-white">Morning 09.00 AM to
                                                    <br></br>
                                                    Evening 06.00 PM</p>
                                            </div> */}
                                            <div className={Styles.social_address1} style={{ display: "flex" }}>


                                            </div>
                                        </div>
                                    </div>
                                    <div className={Styles.colfooter}>
                                        <div className={Styles.social_address}>
                                            <p className={Styles.social_address_p}>Call us</p>
                                            <div className={Styles.social_address1} style={{ marginTop: 8 }}>
                                                <p className="text-white"><a href="tel:+971505363704">+987 3211 4562</a></p>
                                                <p className="text-white"><a href="tel:+971505363704">9am to 6pm IST</a></p>
                                            </div>
                                            {/* <div className={Styles.social_address1} style={{ display: "flex" }}>

                                                <img className=" " style={{ marginBottom: "20px" }} src={Timer_zeeqr} />
                                                <p className="text-white">Morning 09.00 AM to
                                                    <br></br>
                                                    Evening 06.00 PM</p>
                                            </div> */}
                                            <div className={Styles.social_address1} style={{ display: "flex" }}>


                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className={Styles.social_icons} style={{ display: "flex" }}>
                                        <a href='https://www.facebook.com/' target="_blank">
                                            <img src={Facebookicon} /></a>
                                        <a href='https://www.instagram.com/' target="_blank"> <img src={Instaicon} /></a>
                                        <a href='https://twitter.com/' target="_blank"><img src={Twittericon} /></a>
                                        <a href='https://www.linkedin.com/' target="_blank"> <img
                                            src={Linkdinicon} /></a>
                                        <a href="https://whatsapp.com" target="_blank"><img
                                            src={Whatsappicon} /></a>
                                    </div> */}

                                </div>

                            </div>
                            <div className="col-sm-12 d-sm-none"></div>

                            <div className={Styles.colfooter}>
                                <div className={Styles.nav_footer}>
                                    <p className={Styles.quickHead}>Quick links</p>
                                    <div className={Styles.nav_footer_list}>
                                        <p><Link to={"/"} style={{ textDecoration: "none" }}>
                                            Home</Link></p>
                                        <p><Link to={"/about"} style={{ textDecoration: "none" }}>
                                            About Us</Link></p>
                                        <p><Link to={"/contact"} style={{ textDecoration: "none" }}>
                                            Contact
                                        </Link></p>
                                        <p><Link to={"/select-card"} style={{ textDecoration: "none" }}>Create your card</Link></p>
                                        {!localStorage.getItem("token") && <p><Link to={"/login"} style={{ textDecoration: "none" }}>Log-In</Link></p>}

                                    </div>
                                </div>
                            </div>
                            {/* <div className={Styles.colfooter}>
                                <div className={Styles.footer_gallery}>
                                    <p className="">Gallery</p>
                                    <div className={Styles.img_zeeqr} style={{ display: "flex", marginTop: "20px" }}>
                                        <img src={Footerimg1} />
                                        <img src={Footerimg2} />
                                    </div>
                                    <div className={Styles.img_zeeqr} style={{ display: "flex" }}>
                                        <img src={Footerimg3} />
                                        <img src={Footerimg4} />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className={Styles.copyright}>
                        <div className={Styles.copyright_text}>Copyright © 2023 Card Connect</div>
                        <div><svg xmlns="http://www.w3.org/2000/svg" width="156" height="6" viewBox="0 0 156 6" fill="none">
                            <path d="M80.8867 3L78 0.113249L75.1133 3L78 5.88675L80.8867 3ZM0 3.5L78 3.5V2.5L0 2.5L0 3.5Z" fill="#E6AC26" />
                            <path d="M75.1132 3L78 5.88675L80.8868 3L78 0.113249L75.1132 3ZM78 3.5L156 3.5V2.5L78 2.5V3.5Z" fill="#E6AC26" />
                        </svg></div>
                    </div>
                </div>

                <div className={Styles.top_btn}>
                    {/* <a href="https://whatsapp.com" target='_blank' className={Styles.chatWithUs}>
                        <span>CHAT WITH EXPERT</span>
                        <img src={Whatsappgif} />
                    </a>
                */}

                    <button className={Styles.myBtn1}><a onClick={scrollToTop} ><img src={Topbutton} /></a></button>

                </div>

            </footer>

        </>

    )
}

export default Footer