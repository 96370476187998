import React, { useEffect, useState } from 'react'
import { LOGO } from '../../../../utils/images/Images';
import { useDispatch, useSelector } from 'react-redux';
import { profile, selectProfile } from '../../../../redux/action/profileData';
import { getProfileById, updateUserProfile } from '../../../../services/api/api-services';
import { toast } from 'react-hot-toast';
import { UserProfile } from '../../../components/shared/Header';
import ChangePassword from '../setting/ChangePassword';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input';

export default function ViewProfile() {
    // const
    const profileData = useSelector(selectProfile);
    const dispatch = useDispatch()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("")
    const [isMounted, setIsMounted] = useState(false)
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [isDisabled, setDisabled] = useState(false)

    const [previewImage, setPreviewImage] = useState("");
    const [image, setImage] = useState("");

    const [showModal, setShowModal] = useState(false);
    const [userId, setUserId] = useState("")
    const [countryCode, setCountryCode] = useState("IN")
    const [phoneNumber, setPhoneNumber] = useState("")
    const handleFormValidation = (event) => {
        event.preventDefault()
        if (firstName === "" || lastName === "" || email === "" || phone === "") {
            if (firstName === "") {
                setFirstNameError(true)
            } else {
                setFirstNameError(false)
            }
            if (lastName === "") {
                setLastNameError(true)
            } else {
                setLastNameError(false)
            }
            if (email === "") {
                setEmailError(true)
            } else {
                setEmailError(false)
            }
            if (phone === "") {
                setPhoneError(true)
            } else {
                setPhoneError(false)
            }
            return
        } else {
            handleFormSubmit();
        }
    }
    const handleChangePassword = () => { setShowModal(!showModal) }

    const handleFormSubmit = () => {
        let formData = new FormData();
        formData.append("userId", profileData?._id)
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("image", image);
        if (profileData?.phone === undefined || profileData?.phone === null) {
            formData.append("phone", phone?.replace("0", "").replace(" ", ""))
            formData.append("countryCode", "+" + getCountryCallingCode(countryCode))
        }

        setDisabled(true)
        const toastId = toast.loading("Loading...")

        updateUserProfile(formData).then((response) => {
            if (response?.err === false) {
                toast.success(response?.msg);
                handleUserProfile(profileData?._id)
                toast.dismiss(toastId)
                setDisabled(false)
            } else {
                toast.dismiss(toastId)
                toast.error(response?.msg);
                setDisabled(false)
            }
        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        if (profileData && !isMounted) {
            setFirstName(profileData?.firstName)
            setLastName(profileData?.lastName)
            setEmail(profileData?.email);
            setPhone(profileData?.phone)
            setImage(profileData?.image);
            setPreviewImage(profileData?.image)
            setIsMounted(true);
            setUserId(profileData?._id)
            // setCountryCode(profileData?.countryCode)
        }
    }, [profileData])

    const handleImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event?.target?.files[0]);
            setPreviewImage(URL.createObjectURL(event?.target?.files[0]))
        }
    }

    const handleUserProfile = (userId) => {
        getProfileById(userId).then((response) => {
            if (response?.err === false) {
                dispatch(profile(response?.user))

            } else {
                toast.success(response?.msg);
            }
        }).catch(err => console.log(err))
    }

    const handlePhoneNumberChange = (value) => {
        setPhone(formatPhoneNumber(value))
        if (phoneNumber !== "") {
            setPhoneError(false)
        }
    }

    return (
        <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>

            <div className='nk-content-inner'>
                <div className='nk-content-body'>
                    <div className='components-preview  mx-auto'>
                        <div className='nk-block nk-block-lg'>
                            <div className='nk-block-head row'>
                                <div className='nk-block-head-content col-7'>
                                    <h3 className="nk-block-title page-title gradient-text">Account settings</h3>
                                </div>
                            </div>
                            <div className='card' style={{ border: "1px solid #333" }}>
                                <div className='card-inner'>
                                    <form className='row' onSubmit={handleFormValidation}>
                                        <div className='col-lg-6 col-xl-4'>
                                            <div className="form-group">
                                                <div className='changeImage'>
                                                    {previewImage ? <img src={previewImage} alt="" /> : <div className="user-avatar">
                                                        <span style={{ fontSize: 30 }}>
                                                            {profileData?.firstName?.charAt(0)}
                                                            {profileData?.lastName?.charAt(0)}
                                                        </span>
                                                    </div>}

                                                    <label htmlFor='file-input' className='editButton'>
                                                        <em className="icon ni ni-edit-alt" />
                                                        <input type='file' id='file-input' className='d-none' onChange={handleImage} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="fname">First name</label>
                                                <div className="form-control-wrap">
                                                    <input onChange={(event) => setFirstName(event.target.value)} type="text" className="form-control" id="fname" value={firstName} />
                                                    {firstNameError && <small className='invalid-feedback d-block'>First name must be filled out.</small>}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="lname">Last name</label>
                                                <div className="form-control-wrap">
                                                    <input type="text" onChange={(event) => setLastName(event.target.value)} className="form-control" id="lname" value={lastName} />
                                                    {lastNameError && <small className='invalid-feedback d-block'>Last name must be filled out.</small>}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="email">Email</label>
                                                <div className="form-control-wrap">
                                                    <input disabled type="text" className="form-control" id="email" value={email} />
                                                    {emailError && <small className='invalid-feedback d-block'>Email must be filled out.</small>}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className='form-label-group'>
                                                    <label className='form-label' htmlFor='password'>
                                                        Password
                                                    </label>
                                                    <p className='link color-theme link-sm' onClick={handleChangePassword}>Change Password?</p>
                                                </div>
                                                <div className="form-control-wrap">
                                                    <input disabled type="password" className="form-control" id="password" placeholder='*****************' />
                                                </div>
                                                <div></div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="phone">Phone</label>
                                                <div className="form-control-wrap">
                                                    {profileData?.phone && profileData?.countryCode ? <PhoneInput
                                                        placeholder="Phone number"
                                                        defaultCountry={'IN'}
                                                        countryCallingCodeEditable
                                                        className='form-control'
                                                        numberInputProps={{
                                                            style: {
                                                                background: "transparent"
                                                            }
                                                        }}
                                                        value={"+" + getCountryCallingCode(countryCode) + phone?.replace("0", "").replace(" ", "")}
                                                        style={{ pointerEvents: "none", backgroundColor: "rgba(111,111,111,0.2)" }}

                                                    /> : <PhoneInput
                                                        placeholder="Phone number"
                                                        defaultCountry='IN'
                                                        countryCallingCodeEditable
                                                        className='form-control'
                                                        onCountryChange={(value) => setCountryCode(value)}
                                                        onChange={handlePhoneNumberChange}
                                                    />}

                                                    {phoneError && <small className='invalid-feedback d-block'>Phone must be filled out.</small>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12"></div>
                                        <div className="col-lg-3 col-12 mt-5">
                                            <div className='btn-wrapper-gradient w-100' style={{ marginLeft: 'unset' }}>
                                                <button disabled={isDisabled} type='submit' className='_btn__ w-100'>Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChangePassword isShow={showModal} onClose={handleChangePassword} userId={userId} />
        </div>

    )
}
