/* eslint-disable jsx-a11y/alt-text */
import { useCallback } from 'react';
import axios from "axios";
import styles from './Qrcardpreview.module.scss';
import { useEffect, useRef, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { BiMobile } from 'react-icons/bi';
import { FaDesktop } from 'react-icons/fa6'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { LoaderIcon } from 'react-hot-toast';
import { HEADER_IN_JSON } from '../services/api/api-path';
import ShareModal from '../components/sharemodal/ShareModal';
import DeviceImage from './img/iPhone-11-Black.png'
import TemplateRoute from '../user/template/TemplateRoute';
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ICON_COLOR = ["#0973C6", "#09C679", "#E51E06", "#F89E1C", "#1CDAF8", "#1C33F8", "#A51CF8", "#F81CC9", "#09C69E", "#1CB5F8", "#148D08", "#89088D", "#8D0808"]

const getCardData = async (cardId) => {
    const respose = await axios(BASE_URL + "card/getById/" + cardId,);
    const data = respose.data;
    return data;
};

const GET_LOCATION = "https://ipapi.co/json";

const getLocationByIP = async () => {
    const respose = await axios(GET_LOCATION);
    const data = respose.data;
    return data;
};

const ViewChangeButton = ({ onClick, isMobile }) => {
    return <div className={styles.view_tab}>
        <div className={styles._tabs_}>
            <div className={styles.tabs__}>
                <button type='button' onClick={() => onClick("desktop")}>
                    <FaDesktop size={25} color={!isMobile ? '#4D3000' : "rgba(77, 48, 0 , 0.5)"} />
                </button>
                <button type='button' onClick={() => onClick("mobile")}>
                    <BiMobile size={25} color={isMobile ? '#4D3000' : "rgba(77, 48, 0 , 0.5)"} />
                </button>

            </div>
        </div>
    </div>
}

function PreviewCard(props) {
    const navigate = useNavigate()
    const { cardId } = useParams();
    const [cardData, setCardData] = useState([]);
    const [jsonEmails, setJsonEmails] = useState([]);
    const [jsonSocialMediaLinks, setJsonSocialMediaLinks] = useState([]);
    const [jsonPhones, setPhones] = useState([]);
    const [jsonWebsite, setWebsite] = useState([]);
    const [jsonCustomLinks, setCustomLinks] = useState([]);
    const [logoImage, setLogoImage] = useState("")
    const [profileImage, setProfileImage] = useState("");
    const [error, setError] = useState(false)
    const dataFetchedRef = useRef(false);
    const [isLoading, setLoading] = useState(true)
    const [noCardFound, setNoCardFound] = useState(false)
    const [isShare, setIsShare] = useState(false)
    const [shareId, setShareId] = useState([])
    const [isMobileView, setMobileView] = useState(false);


    const handleGetCardData = async (cardId) => {
        await axios(BASE_URL + "card/getById/" + cardId, {
            validateStatus: function (status) {
                return status < 500; // Resolve only if the status code is less than 500
            }
        }).then((res) => {
            let response = res.data
            if (response.err === false) {
                setPhones(eval(JSON.parse(response?.card?.phones)));
                setCardData(response?.card);
                setJsonEmails(eval(JSON.parse(response?.card?.emails)));
                setError(false)
                setLoading(false)
                setJsonSocialMediaLinks(eval(JSON.parse(response?.card?.socialLink)));
                setWebsite(eval(JSON.parse(response?.card?.webs)));
                setCustomLinks(eval(JSON.parse(response?.card?.customFields)));
                document.title = response.card.firstName + " " + response.card.lastName
                getLatLng();
            } else {
                setLoading(false)
                setError(true)
            }
        }).catch((error) => {
            if (error.response.status === 500) {
                setNoCardFound(true)
                setLoading(false)
            }
        });

    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        handleGetCardData(cardId);
    }, [cardData]);


    const socialMapping = (array) => {
        let data = ""
        for (let index = 0; index < array?.length; index++) {
            data += `X-SOCIALPROFILE;type=${array[index]?.title};:${array[index]?.link}\n`

        }
        return data

    }

    const emailMapping = (array) => {
        let data = ""
        for (let index = 0; index < array?.length; index++) {
            data += `EMAIL;type=${array[index]?.type};:${array[index]?.value}\n`

        }
        return data

    }

    const phoneMapping = (array) => {
        let data = ""
        for (let index = 0; index < array?.length; index++) {
            data += `TEL;type=${array[index]?.type};:${array[index]?.value}\n`
        }
        return data

    }





    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

    function get_url_extension(url) {
        if (url.split(/[#?]/)[0].split('.').pop().trim() === 'jpg') {
            return "JPEG"
        } else {
            return url.split(/[#?]/)[0].split('.').pop().trim();
        }
    }

    const generateUrlIntoBase64 = useCallback(async (url) => {
        return await fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((res) => {
                    reader.onloadend = () => {
                        res(getBase64StringFromDataURL(reader.result));
                    }
                })
            })
    }, [])

    const convertBase64Logo = useCallback(async (url) => {
        await generateUrlIntoBase64(url).then((res) => {
            return setLogoImage(`LOGO;ENCODING=b;TYPE=${get_url_extension(url)?.toUpperCase()?.toString()}:${res?.replace(/^data:image\/[a-z]+;base64,/, "")}`);
        }
        ).catch(err => console.log(err))
    }, [generateUrlIntoBase64])

    const convertBase64profilePhoto = useCallback(async (url) => {
        await generateUrlIntoBase64(url).then((res) => {
            return setProfileImage(`PHOTO;ENCODING=b;TYPE=${get_url_extension(url)?.toUpperCase()?.toString()}:${res?.replace(/^data:image\/[a-z]+;base64,/, "")}`);
        }
        ).catch(err => console.log(err))
    }, [generateUrlIntoBase64])


    useEffect(() => {
        if (cardData?.logo) {
            convertBase64Logo(cardData?.logo?.replace('http://', 'https://'))
        }
        if (cardData?.profilePhoto) {
            convertBase64profilePhoto(cardData?.profilePhoto?.replace('http://', 'https://'))
        }
        return () => { }
    }, [cardData?.logo, cardData?.profilePhoto, convertBase64Logo, convertBase64profilePhoto])

    const generateVcard = () => {
        let vCard = `BEGIN:VCARD    
VERSION:3.0
N;CHARSET=UTF-8:${cardData?.firstName} ${cardData?.lastName} 
FN;CHARSET=UTF-8:${cardData?.prefix} ${cardData?.firstName} ${cardData.lastName};${cardData?.suffix}
NICKNAME;CHARSET=UTF-8:${cardData?.firstName} ${cardData?.lastName}
${logoImage}
${profileImage}
${jsonEmails.map((item, index) => `EMAIL;CHARSET=UTF-8;TYPE=${item?.type},INTERNET:${item?.value}`)}
${jsonPhones?.map((item, index) => `TEL;TYPE=${item?.type},CELL:${item?.value}`)}
LABEL;CHARSET=UTF-8;TYPE=HOME:${cardData?.location}
ADR;CHARSET=UTF-8;TYPE=HOME:;;${cardData?.location}
ROLE;CHARSET=UTF-8:${cardData?.designation}
ORG;CHARSET=UTF-8:${cardData?.company}
${emailMapping(jsonEmails)}
${phoneMapping(jsonPhones)}
${socialMapping(jsonSocialMediaLinks)}
REV:2023-05-18T08:30:47.500Z
END:VCARD`;
        // console.log(vCard);
        downloadToFile(vCard, "vcard.vcf", "text/vcard");
    };

    function downloadToFile(content, filename, contentType) {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    }

    const swiperRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();
    }, []);

    const increaseCount = (location) => {
        const body = {
            cardId: cardId,
            longitude: location?.latitude,
            latitude: location?.longitude,
            city: location?.city,
            region: location?.country_name,
            state: location?.region
        };
        axios.post(`${BASE_URL}cardView/addView/`, body, {
            headers: HEADER_IN_JSON,
            validateStatus() {
                return true;
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => console.log(err));
    };

    const getLatLng = () => {
        getLocationByIP().then((response) => {
            if (response) {
                increaseCount(response);
            }
        });
    };
    const cardUrl = window.location.href;

    const handleShareClick = () => {
        if (navigator.share) {
            // Web Share API is supported, use it
            navigator.share({
                title: 'Card Title',
                url: cardUrl,
            })
                .then(() => {
                    console.log('Shared successfully');
                })
                .catch(error => {
                    console.error('Error sharing:', error);
                });
        } else {
            // Fallback: Generate a "mailto" link for email sharing
            const subject = 'Check out this card';
            const body = `I wanted to share this card with you: ${cardUrl}`;
            const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
        }
    };

    const handleShareLink = (_id) => {
        setIsShare(true)
        setShareId(_id);
        // console.log(_id);
        // toast.success("Link copy successfully")
        // navigator.clipboard.writeText(PREVIEW_CARD_URL + _id)
    }

    const handleDeviceView = (device) => {
        if (device === 'desktop') {
            setMobileView(false)
        } else {
            setMobileView(true)
        }
    }
    const getTemplateId = (value) => {
        if (value.trim() === "undefined") {
            return 1
        } else if (value.trim() === "") {
            return 1
        } else if (value === undefined) {
            return 1
        } else {
            return parseInt(value)
        }
    }
    if (isLoading) {
        return <div className='loader-container'>
            <LoaderIcon style={{ width: 50, height: 50 }} />
        </div>
    } else if (noCardFound) {
        return <div className='no-card-found '>
            <p >No card found</p>
            <Link className='btn btn-primary' to={'/'}>Goto home</Link>
        </div>
    } else {
        return (
            <div className={styles.previewContainerParent}>
                <div className={styles.previewCardContainer} style={{}}>
                    <ViewChangeButton onClick={handleDeviceView} isMobile={isMobileView} />
                    <div className={isMobileView ? styles.mobileFullContainer : styles.FullContainer}>
                        {isMobileView && <div className={styles.DeviceImage} style={{ backgroundImage: `url(${DeviceImage})` }} ></div>}
                        <div className={isMobileView ? styles.mobileTemplateWrapper : styles.TemplateWapper}>
                            {!isMobileView && <div className={styles.blur} style={{ backgroundImage: `url(${cardData?.logo})` }} ></div>}
                            <div className={isMobileView ? styles.zoom : styles.containerHeight}>
                                <TemplateRoute data={{
                                    userImagePreview: cardData?.profilePhoto,
                                    logoImagePreview: cardData?.logo,
                                    firstName: cardData?.firstName,
                                    lastName: cardData?.lastName,
                                    prefix: cardData?.prefix,
                                    suffix: cardData?.suffix,
                                    company: cardData?.company,
                                    department: cardData?.department,
                                    designation: cardData?.designation,
                                    summary: cardData?.summery,
                                    phones: jsonPhones,
                                    emails: jsonEmails,
                                    website: jsonWebsite,
                                    customLinks: jsonCustomLinks,
                                    location: cardData?.location,
                                    socialMediaLinks: jsonSocialMediaLinks,
                                    primaryColor: cardData?.primaryColor,
                                    secondaryColor: cardData?.secondaryColor,
                                    profileTextColor: cardData?.profileTextColor,
                                    fontFamily: cardData?.font,
                                    titleFontSize: cardData?.fontSize,
                                    isDownload: cardData?.isDownload,
                                    isLocation: cardData?.isLocation,
                                    backgroundImage: cardData?.backgroundImg
                                }} selectedTemplate={getTemplateId(cardData?.pronouns)}
                                    onClickAddToContactButton={generateVcard}
                                    onClickShareButton={() => handleShareLink(cardData)}
                                    isMobileView={isMobileView}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.poweredText}>Powered by cardconnect</div>   */}
                </div>
                <ShareModal isShow={isShare} onClose={() => setIsShare(!isShare)} shareId={shareId} />
                {/* <div className='background-image'/>
                
                <div className='card-wrapper-preview'>
                    <div className='device-wrapper'>
                        {isMobileView && <div className='device-image' style={{ backgroundImage: `url(${DeviceImage})` }} ></div>}
                        <div className={`scale-06 ${isMobileView && 'scale-07'}`} >
                            <TemplateRoute data={{
                                userImagePreview: cardData?.profilePhoto,
                                logoImagePreview: cardData?.logo,
                                firstName: cardData?.firstName,
                                lastName: cardData?.lastName,
                                prefix: cardData?.prefix,
                                suffix: cardData?.suffix,
                                company: cardData?.company,
                                department: cardData?.department,
                                designation: cardData?.designation,
                                summary: cardData?.summery,
                                phones: jsonPhones,
                                emails: jsonEmails,
                                website: jsonWebsite,
                                customLinks: jsonCustomLinks,
                                location: cardData?.location,
                                socialMediaLinks: jsonSocialMediaLinks,
                                primaryColor: cardData?.primaryColor,
                                secondaryColor: cardData?.secondaryColor,
                                profileTextColor: cardData?.profileTextColor,
                                fontFamily: cardData?.font,
                                titleFontSize: cardData?.fontSize,
                                isDownload: cardData?.isDownload,
                                isLocation: cardData?.isLocation,
                                backgroundImage: cardData?.backgroundImg
                            }} selectedTemplate={cardData?.pronouns !== "undefined" ? parseInt(cardData?.pronouns) : 1}
                                onClickAddToContactButton={generateVcard}
                                onClickShareButton={() => handleShareLink(cardData)}
                                isMobileView={isMobileView}
                            />                            
                        </div>
                        {isMobileView && <div className='mobile-view' />}                      
                    </div>                                       
                    <div className='powered-text'>Powered by cardconnect</div>                    
                </div>
 */}
            </div>
        );
    }


}

export default PreviewCard;