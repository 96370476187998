import React, { useEffect } from 'react'
import Styles from './styles.module.scss'
import { EmailIcon, GloabIcon, NavigationIcon, PhoneIcon, ShareIcon, ShareIcon2 } from '../components/Icons';
import SocialMediaSlider from '../components/social-media-slider/SocialMediaSlider';
import { AVATAR } from '../../../utils/images/Images';
import { isValidLink } from '../components/isValidUrl';

export default function CardTemplate({ data, onClickShareButton, onClickAddToContactButton, isDrawer }) {
   

    useEffect(() => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
     
        document.getElementById("cardWrapper").style.setProperty('--vh', `${vh}px`);
    }, [])    

    return (
        <div className={Styles.CardTemplate5}>
            <div className={isDrawer ? Styles.CardWrapperDrawer : Styles.CardWrapper} id='cardWrapper' >
                <div className={Styles.CardContainer}>
                    <div className={Styles.TopBackground}>
                        <div className={Styles.overlay} />
                        {/* <img src='https://www.epicstuff.com/cdn/shop/collections/MARVEL_1920x450.jpg?v=1521013922' /> */}

                        <button className={Styles.ShareIcon} onClick={onClickShareButton && onClickShareButton} >
                            <ShareIcon2 />
                        </button>
                    </div>
                    <div className={Styles.CardBody}
                    // style={{ backgroundColor: data?.secondaryColor }}
                    >
                        <div className={Styles.CardProfileImage}>
                            <img className={Styles.ProfileImage} src={data?.userImagePreview ? data?.userImagePreview : AVATAR} />
                        </div>
                        <div className={Styles.CardContent}>
                            <div className={Styles.CardContentTop}>
                                <div style={{ width: "100%", paddingRight: 35 }}>
                                    <div className={Styles.CardText_P} style={{ fontSize: data?.titleFontSize + "px", color: data?.profileTextColor }}>{data?.firstName || data?.lastName ? (data?.prefix + " " + data?.firstName + " " + data?.lastName + " " + data?.suffix) : "Card Connect"}</div>
                                    <div className={Styles.CardText_p2} style={{ color: data?.primaryColor }}>{data?.designation ? data?.designation : "Connecting People"} {" "}
                                        <span className={Styles.CardText_p3}>@{data?.company ? data?.company : "Company Name"}</span></div>
                                </div>
                                <div>
                                    <SocialMediaSlider
                                        isTruncate
                                        data={data}
                                    />
                                </div>
                            </div>
                            <div className={Styles.CardScrollWrapper}>
                                <div className={Styles.CardScrollContainer}>
                                    <div className={Styles.AboutContainer}>
                                        <p style={{ color: data?.primaryColor }}>{data?.summary}</p>
                                    </div>
                                    <div className={Styles.CardLinksContainer}>
                                        {data?.customLinks?.length > 0 && data?.customLinks[0].value !== "" && <div>
                                            {data.customLinks.map((i, index) => <a target='_blank' href={isValidLink(i.value)} key={index.toString()} className={Styles.CardLinkRow} rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                    <GloabIcon />
                                                </div>
                                                <p>{isValidLink(i.value)}</p>
                                            </a>)}
                                        </div>}
                                        {data?.website?.length > 0 && data?.website[0].value !== "" && <div>
                                            {data.website.map((i, index) => <a target='_blank' href={isValidLink(i.value)} key={index.toString()} className={Styles.CardLinkRow} rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                    <GloabIcon />
                                                </div>
                                                <p>{isValidLink(i.value)}</p>
                                            </a>)}
                                        </div>}
                                        {data?.emails?.length > 0 && data?.emails[0].value !== "" && <div >
                                            {data.emails.map((i, index) => <a target='_blank' href={"mailto:" + i.value} key={index.toString()} className={Styles.CardLinkRow} rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                    <EmailIcon />
                                                </div>
                                                <p>{i.value}</p>

                                            </a>)}
                                        </div>}
                                        {data?.location && <div>
                                            <a target='_blank' href={"https://maps.google.com/?q=" + data?.location.replaceAll(",", "")} className={Styles.CardLinkRow} rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                    <NavigationIcon />
                                                </div>
                                                <p>{data?.location}</p>
                                            </a>
                                        </div>
                                        }

                                        {data?.phones?.length > 0 && data?.phones[0].value !== "" && <div>
                                            {data.phones.map((i, index) => <a target='_blank' href={"tel:" + i.value} key={index.toString()} className={Styles.CardLinkRow} rel="noreferrer">
                                                <div className={Styles.Icon}>
                                                    <PhoneIcon />
                                                </div>
                                                <p>{i.value}</p>
                                            </a>)}

                                        </div>}
                                        {/* <div>
                                            <SocialMediaSlider />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={Styles.SaveButton}>
                    <button onClick={onClickAddToContactButton && onClickAddToContactButton} style={{ color: data?.profileTextColor }}>Save Card</button>
                </div>
            </div>
        </div>
    )
}
