import React from 'react';
import Styles from "./styles.module.scss";
import NavbarZEEQR from '../navbar/NavbarZEEQR';
import FooterZEEQR from '../footer/FooterZEEQR';
import { Button } from 'bootstrap';
import PriceCard from './PriceCard';

function Pricing(props) {
    const pricingData = [
        {
          name: "Basic Plan",
          price: "$9.99",
          features: ["Feature 1", "Feature 2", "Feature 3"],
        },
        {
          name: "Standard Plan",
          price: "$19.99",
          features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"],
        },
        {
          name: "Premium Plan",
          price: "$29.99",
          features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"],
        },
        {
          name: "Pro Plan",
          price: "$39.99",
          features: [
            "Feature 1",
            "Feature 2",
            "Feature 3",
            "Feature 4",
            "Feature 5",
            "Feature 6",
          ],
        },
      ];
      
    return (
      <div>
           <section>
                <div className={Styles.header_bg}>
                    <div className={Styles.header1}>

                        <NavbarZEEQR page={'contact'} />
                        <div className={Styles.about_banner} >
                            <div className={Styles.innerPagebanner}>
                                <div className={Styles.inner_banner_text}>
                                    <h2 className={Styles.blog_banner_text1}>Pricing</h2>
                                    <h2 className={Styles.blog_banner_text2}><a href="/home">Home</a> - Pricing</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            
            <section className={Styles.pricing_section}>
            <div className={Styles.pricing_container}>
                <div className={Styles.pricing_content_wrapper}>
                    <div className={Styles.pricing_top_container}>
                        <div className={Styles.top_container_left}>
                            <h1>pricing</h1>
                            <p>No contracts, No surprise fees</p>
                        </div>
                        <div className={Styles.top_container_right}>
                            <div>
                                <button>Monthly</button>
                            </div>
                        </div>
                    </div>
                    <div className={Styles.pricing_bottom_container}>
                        <div className={Styles.pricing_card_wrapper}>
                            {
                                pricingData.map((item,index)=>
                                    <PriceCard data={item} index={index}/>
                                    
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <FooterZEEQR page={'pricing'} />
            
      </div>
    );
}

export default Pricing;