import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import MultipleInputs from '../../components/multipleInputs/MultipleInputs'
import { useDispatch, useSelector } from 'react-redux'
import { selectVCardData, vCardDataAdditional, vCardDataDesign, vCardData as vCardSetup } from '../../redux/action/cardData'
import { useNavigate, useParams } from 'react-router-dom';
import { socialMediaMetaData } from '../../utils/socialmedia/socialmedita'
import FileInput from '../components/fileinput/FileInput'
import ParseJwt from '../../services/parseJwt/parseJwt'
import { AVATAR } from '../../utils/images/Images'
import { createCardSetup, updateCard } from '../../services/api/api-services';
import { toast } from 'react-hot-toast'
import GooglePlaceAutoComplete from '../../components/google-place-autocomplete'
import { errorStyle } from '../../utils/strings/Strings'
import RoyalHeadingStrip from '../../assests/imges/vector/heading-royal-strip.svg'
import SuitcateIcon from '../../assests/imges/vector/lock-icon.svg'
import PhoneIcon from '../../assests/imges/vector/phone-icon.svg'
import UsersIcon from '../../assests/imges/vector/user-icon.svg'

const CardDetails = forwardRef((props, ref,) => {

    const dispatch = useDispatch();
    // redux store data
    const vCardData = useSelector(selectVCardData);

    const navigate = useNavigate()

    // cardId for update card
    const { cardId } = useParams()

    // Initial state
    const initialPhones = vCardData?.phones?.length > 0 ? vCardData?.phones : []
    const initialEmails = vCardData?.emails?.length > 0 ? vCardData?.emails : []
    const initialWebsite = vCardData?.website?.length > 0 ? vCardData?.website : []
    const initialCustomLinks = vCardData?.customLinks?.length > 0 ? vCardData?.customLinks : []


    let [phoneNumber, setPhoneNumber] = useState(initialPhones);
    let [emailAddress, setEmailAddress] = useState(initialEmails);
    let [website, setWebsite] = useState(initialWebsite);
    let [customLinks, setCustomLinks] = useState(initialCustomLinks);

    const [userImagePreview, setUserImagePreview] = useState(vCardData?.userImagePreview);
    const [logoImagePreview, setLogoImagePreview] = useState(vCardData?.logoImagePreview);
    const [firstName, setFirstName] = useState(vCardData?.firstName);
    const [lastName, setLastName] = useState(vCardData?.lastName);
    const [prefix, setPrefix] = useState(vCardData?.prefix);
    const [suffix, setSuffix] = useState(vCardData?.suffix);
    const [designation, setDesignation] = useState(vCardData?.designation);
    const [department, setDepartment] = useState(vCardData?.department);
    const [pronouns, setPronouns] = useState(vCardData?.pronouns);
    const [company, setCompany] = useState(vCardData?.company);
    const [summary, setSummary] = useState(vCardData?.summary);

    const [logo, setLogo] = useState(vCardData?.logoImagePreview);
    const [userImage, setUserImage] = useState(vCardData?.userImagePreview);


    const [location, setLocation] = useState(vCardData?.location);
    const [socialMediaLinks, setSocialMediaLinks] = useState(vCardData?.socialMediaLinks)
    const [userId, setUserId] = useState();
    const { layoutId } = useParams()
    const [primaryColor, setPrimaryColor] = useState(vCardData?.primaryColor);
    const [profileTextColor, setProfileTextColor] = useState(vCardData?.profileTextColor);
    const [secondaryColor, setSecondaryColor] = useState(vCardData?.secondaryColor)

    const [fontFamily, setFontFamily] = useState(vCardData?.fontFamily)
    const [titleFontSize, setTitleFontSize] = useState(vCardData?.titleFontSize)

    const [isDownload, setDownload] = useState(vCardData?.isDownload);
    const [isLocation, setIsLocation] = useState(vCardData?.isLocation)

    const [isDisable, setDisable] = useState(false);

    const [firstNameError, setFirstNameError] = useState(false)
    const [companyError, setCompanyError] = useState(false)

    const isInclude = (title) => socialMediaLinks.find(x => x.title === title);


    const getUserId = async () => {
        if (localStorage.getItem('token')) {
            let decodedToken = await ParseJwt(JSON.stringify(localStorage.getItem('token')));
            setUserId(decodedToken?._id._id)
            return decodedToken?._id._id
        }
        else {
            toast.error("Please login first.")
            navigate('/login');
            return false
        }

    }

    const onChangeUserImage = (event) => {
        let image = URL.createObjectURL(event.target.files[0]);
        setUserImage(event.target.files[0]);
        setUserImagePreview(image)
    }

    const onChangeCompanyLogo = (event) => {
        if (event.target.files && event.target.files[0]) {
            let image = URL.createObjectURL(event.target.files[0]);
            setLogo(event.target.files[0]);
            setLogoImagePreview(image)
        }
    }

    const handleAddSocialMedia = (data, index) => {
        setSocialMediaLinks([...socialMediaLinks, {
            logo: data?.logo,
            title: data?.title,
            link: "",
            index: index,
            svg: data?.svg
        }])
    }

    const handleSocialMediaLinks = (event, index) => {
        const { name, value } = event.target
        // console.log(event.target);
        // const onChangeValue = [...socialMediaLinks];
        // onChangeValue[index][name] = value;
        // setSocialMediaLinks(onChangeValue)        
        const newState = socialMediaLinks.map((obj, i) => {
            if (index === i && name === "link") {
                return { ...obj, link: value, };
            }
            return obj;
        });
        setSocialMediaLinks(newState);
    }

    const handleSocialMediaLinkDelete = (index) => {
        const deleteVal = [...socialMediaLinks];

        deleteVal.splice(index, 1);
        setSocialMediaLinks(deleteVal)
    }

    const handleDownloadButton = () => {
        setDownload(!isDownload);
    }

    const handleLocationButton = () => {
        setIsLocation(!isLocation)
    }

    const getSocialLogo = (title) => socialMediaMetaData.find(x => x.title === title)
    
    
    // submit new card data 

    const handleSubmitForm = (userId) => {
        let body = new FormData();
        body.append("userId", userId);
        body.append("templateId", "645f354b6c0b1cb982d8195b")
        body.append("firstName", vCardData?.firstName);
        body.append("lastName", vCardData?.lastName);
        body.append("prefix", vCardData?.prefix);
        body.append("suffix", vCardData?.suffix);
        body.append("designation", vCardData?.designation);
        body.append("department", vCardData?.department);
        body.append("pronouns", vCardData?.pronouns);
        body.append("company", vCardData?.company);
        body.append("summery", vCardData?.summary);
        body.append("location", vCardData?.location);
        body.append("profilePhoto", userImage);
        body.append("logo", logo);
        body.append("phones", JSON.stringify(vCardData?.phones));
        body.append("emails", JSON.stringify(vCardData?.emails));
        body.append("webs", JSON.stringify(vCardData?.website));
        body.append("customFields", JSON.stringify(vCardData?.customLinks));
        body.append("socialLink", JSON.stringify(vCardData?.socialMediaLinks));
        body.append("primaryColor", vCardData?.primaryColor);
        body.append("secondaryColor", vCardData?.secondaryColor);
        body.append("profileTextColor", vCardData?.profileTextColor);
        body.append("font", vCardData?.fontFamily);
        body.append("fontSize", vCardData?.titleFontSize);
        body.append("isDownload", vCardData?.isDownload);
        body.append("isLocation", vCardData?.isLocation);


        const toastId = toast.loading("Creating card....");

        createCardSetup(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId)
                toast.success(response?.msg);
                resetState()
                setDisable(false)
            } else {
                toast.dismiss(toastId)
                toast.error(response?.msg)
                setDisable(false)
            }

        }).catch((error) => console.log(error));

    }
    
    //reset all state and variable

    const resetState = () => {
        dispatch(vCardSetup({
            firstName: "",
            lastName: "",
            prefix: "",
            suffix: "",
            designation: "",
            department: "",
            pronouns: "",
            company: "",
            summary: "",
            location: "",
            logoImagePreview: "",
            userImagePreview: "",
            phones: [],
            emails: [],
            website: [],
            customLinks: [],
            socialLinks: [],
        }));
        dispatch(vCardDataDesign({ primaryColor: "", profileTextColor: "", secondaryColor: "", fontFamily: "", titleFontSize: "" }))
        dispatch(vCardDataAdditional({ isDownload: false, isLocation: false }));

        setPhoneNumber([]);
        setEmailAddress([]);
        setWebsite([]);
        setCustomLinks([]);
        setUserImagePreview("");
        setLogoImagePreview("");
        setFirstName("");
        setLastName("");
        setPrefix("");
        setSuffix("");
        setDesignation("");
        setDepartment("");
        setPronouns("");
        setCompany("");
        setSummary("");
        setLogo("");
        setUserImage("");
        setLocation("");
        setSocialMediaLinks([]);
        setPrimaryColor("");
        setProfileTextColor("");
        setSecondaryColor("");
        setFontFamily("");
        setTitleFontSize("");
        setDownload(false);
        setIsLocation(false);
        navigate("/my-card")
    }

    useEffect(() => {
        dispatch(vCardSetup({
            firstName,
            lastName,
            prefix,
            suffix,
            designation,
            department,
            pronouns,
            company,
            summary,
            location,
            logoImagePreview,
            userImagePreview,
            phones: phoneNumber,
            emails: emailAddress,
            website: website,
            customLinks: customLinks,
            socialLinks: socialMediaLinks,
        }));
        dispatch(vCardDataDesign({ primaryColor, profileTextColor, secondaryColor, fontFamily, titleFontSize }))
        dispatch(vCardDataAdditional({ isDownload: isDownload, isLocation: isLocation }));
    }, [firstName, lastName, prefix, suffix, designation, department, pronouns, company, summary, phoneNumber, emailAddress, website, customLinks, location, socialMediaLinks, userImage, logo, props?.dataLoaded, primaryColor, profileTextColor, secondaryColor, fontFamily, titleFontSize, dispatch, logoImagePreview, userImagePreview, isDownload, isLocation])

    //validate submit data
    const handleSubmitButton = () => {
        if (firstName === "" || company === "") {
            if (firstName === "") {
                setFirstNameError(true);
                scrollToError("firstName")

            } else {
                setFirstNameError(false)
            }

            if (company === "") {
                setCompanyError(true)
                scrollToError("CompanyName")
            }
            else {
                setCompanyError(false)
            }

        } else {
            getUserId().then(userId => {
                if (userId) {
                    setDisable(true)
                    if (cardId !== undefined) {
                        handleSubmitUpdateData(userId);
                        console.log("update");
                    } else {
                        handleSubmitForm(userId)
                        console.log("create");
                    }

                }
            });
            setCompanyError(false)
            setFirstNameError(false)
        }

    }

    //update card details
    const handleSubmitUpdateData = useCallback((userId, tId) => {

        let body = new FormData();
        body.append("cardId", cardId);
        body.append("firstName", vCardData?.firstName);
        body.append("lastName", vCardData?.lastName);
        body.append("prefix", vCardData?.prefix);
        body.append("suffix", vCardData?.suffix);
        body.append("designation", vCardData?.designation);
        body.append("department", vCardData?.department);
        body.append("pronouns", tId);
        body.append("company", vCardData?.company);
        body.append("summery", vCardData?.summary);
        body.append("location", vCardData?.location);
        body.append("profilePhoto", userImage);
        body.append("logo", logo);
        body.append("phones", JSON.stringify(vCardData?.phones));
        body.append("emails", JSON.stringify(vCardData?.emails));
        body.append("webs", JSON.stringify(vCardData?.website));
        body.append("customFields", JSON.stringify(vCardData?.customLinks));
        body.append("socialLink", JSON.stringify(vCardData?.socialMediaLinks));
        body.append("primaryColor", vCardData?.primaryColor);
        body.append("secondaryColor", vCardData?.secondaryColor);
        body.append("profileTextColor", vCardData?.profileTextColor);
        body.append("font", vCardData?.fontFamily);
        body.append("fontSize", vCardData?.titleFontSize);
        body.append("isDownload", vCardData?.isDownload);
        body.append("isLocation", vCardData?.isLocation);
        const toastId = toast.loading("Updating....");

        //return

        updateCard(body).then((response) => {
            if (response?.err === false) {
                toast.dismiss(toastId)
                toast.success(response?.msg)
                setDisable(false)
                navigate('/my-card')
            } else {
                toast.dismiss(toastId)
                toast.error(response?.msg)
                setDisable(false)
            }

        }).catch((error) => console.log(error));



    }, [cardId, logo, navigate, userImage, vCardData?.company, vCardData?.customLinks, vCardData?.department, vCardData?.designation, vCardData?.emails, vCardData?.firstName, vCardData?.fontFamily, vCardData?.isDownload, vCardData?.isLocation, vCardData?.lastName, vCardData?.location, vCardData?.phones, vCardData?.prefix, vCardData?.primaryColor, vCardData?.profileTextColor, vCardData?.secondaryColor, vCardData?.socialMediaLinks, vCardData?.suffix, vCardData?.summary, vCardData?.titleFontSize, vCardData?.website])

    //set entered location
    const handleLocation = (event) => {
        setLocation(event?.address ? event?.address : "")
    }

    //font size change
    const handleFontSizeChange = (type) => {
        if (type === 'plus') {
            setTitleFontSize(Number(titleFontSize) + 1)
        } else {
            if (titleFontSize > 1) {
                setTitleFontSize(Number(titleFontSize) - 1)
            }
        }
    }

    // scroll to the error element
    function scrollToError(contentId) {
        const element = document.getElementById(contentId);
        const offset = (window.innerHeight) / 2;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    // call back function use in createCard.js
    useImperativeHandle(ref, () => ({
        handleSubmitButton(tId) {
            if (firstName === "" || company === "") {
                if (firstName === "") {
                    setFirstNameError(true)
                    scrollToError("firstName");
                } else {
                    setFirstNameError(false)
                }

                if (company === "") {
                    setCompanyError(true)
                    scrollToError("companyName");
                }
                else {
                    setCompanyError(false)
                }

            } else {
                getUserId().then(userId => {
                    if (userId) {
                        setDisable(true)
                        if (cardId !== undefined) {
                            handleSubmitUpdateData(userId, tId);
                            console.log("update");
                        } else {
                            handleSubmitForm(userId, tId)
                            console.log("create");
                        }

                    }
                });
                setCompanyError(false)
                setFirstNameError(false)
            }

        }

    }));


    return (
        <div className='container'>
            <div className='row gy-4'>
                <div className='con-sm-12'>
                    <img src={RoyalHeadingStrip} style={{ marginBottom: 3 }} alt='' />
                    <h2 className='h4 color-theme'>Personal Details</h2>
                </div>
                <div className="col-sm-12 mb-12">
                    <div className="form-group text-right position-relative">
                        <label className="form-label" htmlFor="company-logo">
                            Cover Photo
                        </label>
                        <FileInput inputName={'logo'}
                            onChange={onChangeCompanyLogo}
                            isPreview value={() => console.log()}
                            resetForm={isDisable}
                        />
                        <label className="user-image" htmlFor="file-input">
                            <img src={userImagePreview ? userImagePreview : AVATAR} />
                            <input
                                onChange={onChangeUserImage}
                                type="file"
                                className="d-none"
                                id="file-input"
                                name="userImage"
                            />
                            <span className="user-image-pen">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
                                    <path d="M18.3011 2.31205H15.643L14.336 0.412922C14.266 0.31123 14.1711 0.227843 14.0599 0.170147C13.9487 0.11245 13.8245 0.0822267 13.6983 0.0821533H7.56134C7.43517 0.0822267 7.31097 0.11245 7.19974 0.170147C7.08851 0.227843 6.99369 0.31123 6.92366 0.412922L5.61572 2.31205H2.9586C2.34824 2.31205 1.76287 2.54699 1.33128 2.96517C0.899692 3.38336 0.657227 3.95054 0.657227 4.54195V14.9481C0.657227 15.5395 0.899692 16.1067 1.33128 16.5249C1.76287 16.9431 2.34824 17.178 2.9586 17.178H18.3011C18.9114 17.178 19.4968 16.9431 19.9284 16.5249C20.36 16.1067 20.6024 15.5395 20.6024 14.9481V4.54195C20.6024 3.95054 20.36 3.38336 19.9284 2.96517C19.4968 2.54699 18.9114 2.31205 18.3011 2.31205ZM14.0819 9.3734C14.0819 10.0349 13.8794 10.6816 13.5001 11.2317C13.1208 11.7818 12.5817 12.2105 11.9509 12.4636C11.3201 12.7168 10.626 12.783 9.95637 12.654C9.28673 12.5249 8.67164 12.2063 8.18886 11.7386C7.70608 11.2708 7.3773 10.6748 7.2441 10.0259C7.11091 9.37711 7.17927 8.70457 7.44055 8.09338C7.70182 7.48219 8.14428 6.95979 8.71197 6.59226C9.27966 6.22472 9.94708 6.02855 10.6298 6.02855C11.5454 6.02855 12.4234 6.38095 13.0708 7.00823C13.7182 7.63551 14.0819 8.48629 14.0819 9.3734Z" fill="white" />
                                </svg>
                            </span>
                        </label>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group">
                        <label className="form-label" htmlFor="prefix">
                            Prefix
                        </label>
                        <div className="form-control-wrap">
                            <select name="prefix" value={prefix} className='form-select' onChange={(event) => setPrefix(event.target.value)}>
                                <option selected>Prefix</option>
                                <option value={"Mr."}>Mr.</option>
                                <option value={"Ms."}>Ms.</option>
                                <option value={"Mrs."}>Mrs.</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4" id='firstName'>
                    <div className="form-group">
                        <label className="form-label" htmlFor="first-name">
                            First name <span className="text-danger">*</span>
                        </label>
                        <div className="form-control-wrap">
                            <input
                                onChange={(event) => setFirstName(event.target.value)}
                                name="firstName"
                                value={firstName}
                                type="text"
                                className="form-control"
                                id="first-name"
                                placeholder="First name"
                                style={firstNameError ? errorStyle : {}}
                            />
                        </div>
                        {firstNameError && <small className='invalid-feedback d-block'>First name should filed out.</small>}
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                        <label className="form-label" htmlFor="last-name">
                            Last name
                        </label>
                        <div className="form-control-wrap">
                            <input
                                onChange={(event) => setLastName(event.target.value)}
                                name="lastName"
                                value={lastName}
                                type="text"
                                className="form-control"
                                id="last-name"
                                placeholder="Last name"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group">
                        <label className="form-label" htmlFor="suffix">
                            Suffix
                        </label>
                        <div className="form-control-wrap">
                            <input
                                onChange={(event) => setSuffix(event.target.value)}
                                name="suffix"
                                value={suffix}
                                type="text"
                                className="form-control"
                                id="suffix"
                                placeholder="Suffix"
                            />
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 mt-5'>
                    <div className='card-form-section-title'>
                        <img src={SuitcateIcon} alt="" />
                        <h2 className='color-theme'>Work Details</h2>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="designation">
                            Designation
                        </label>
                        <div className="form-control-wrap">
                            <input
                                onChange={(event) => setDesignation(event.target.value)}
                                name="designation"
                                type="text"
                                value={designation}
                                className="form-control"
                                id="designation"
                                placeholder="Designation"
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="col-sm-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="department">
                            Department
                        </label>
                        <div className="form-control-wrap">
                            <input
                                onChange={(event) => setDepartment(event.target.value)}
                                name="department"
                                type="text"
                                value={department}
                                className="form-control"
                                id="department"
                                placeholder="Department"
                            />
                        </div>
                    </div>
                </div> */}
                <div className="col-sm-6" id='companyName'>
                    <div className="form-group">
                        <label className="form-label" htmlFor="company">
                            Company<span className="text-danger">*</span>
                        </label>
                        <div className="form-control-wrap">
                            <input
                                onChange={(event) => setCompany(event.target.value)}
                                name="company"
                                value={company}
                                type="text"
                                className="form-control"
                                id="company"
                                placeholder="Company"
                                style={companyError ? errorStyle : {}}
                            />
                        </div>
                        {companyError && <small className='invalid-feedback d-block'>Comapny name should filed out.</small>}
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label className="form-label" htmlFor="summary">
                            Summary
                        </label>
                        <div className="form-control-wrap">
                            <textarea
                                onChange={(event) => setSummary(event.target.value)}
                                name="summary"
                                value={summary}
                                type="text"
                                className="form-control"
                                id="summary"
                                placeholder="Summary"
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="col-sm-12">
                    <div className="form-group">
                        <label className="form-label" htmlFor="company-logo">
                            Background Image
                        </label>
                        <FileInput inputName={'logo'}
                            onChange={onChangeCompanyLogo}
                            isPreview value={() => console.log()}
                            resetForm={isDisable}
                        />
                    </div>
                </div> */}
                <div className="col-sm-12 mt-5">
                    <div className='card-form-section-title'>
                        <img src={PhoneIcon} alt="" />
                        <h2 className='color-theme'>Contact Details</h2>
                    </div>
                </div>
                <MultipleInputs
                    isPhoneInput
                    data={phoneNumber?.length > 0 ? phoneNumber : [{
                        value: "",
                        type: "work"
                    }]}
                    dataObject={{
                        value: "",
                        type: "work"
                    }}
                    setData={(data) => {
                        setPhoneNumber(data)
                    }}
                    name={"value"}
                    label={"Phone"}
                />
                <MultipleInputs
                    data={emailAddress?.length > 0 ? emailAddress : [{
                        value: "",
                        type: "work"
                    }]}
                    dataObject={{
                        value: "",
                        type: "work"
                    }}
                    setData={(data) => setEmailAddress(data)}
                    name={"value"}
                    label={"Email address"}
                />
                <MultipleInputs
                    data={website?.length > 0 ? website : [{
                        value: "",
                        type: "work"
                    }]}
                    dataObject={{
                        value: "",
                        type: "work"
                    }}
                    setData={(data) => setWebsite(data)}
                    name={"value"}
                    label={"Website"}
                />
                <MultipleInputs
                    data={customLinks?.length > 0 ? customLinks : [{
                        value: "",
                        type: "work"
                    }]}
                    dataObject={{
                        value: "",
                        type: "work"
                    }}
                    setData={(data) => setCustomLinks(data)}
                    name={"value"}
                    label={"Custom Links"}
                    isMultiple
                />
                <div className="col-sm-12">
                    <label className="form-label" htmlFor="custom-field">Location</label>

                    <div className="form-group">
                        <div className="form-control-wrap">
                            <GooglePlaceAutoComplete onChange={handleLocation}
                                value={location}
                            />
                            {/* <input value={location} type="text" className="form-control" id="custom-field" name="location" placeholder="Location" onChange={} /> */}
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 mt-5">
                    <div className='card-form-section-title mb-3'>
                        <img src={UsersIcon} alt="" />
                        <h2 className='color-theme'>Social Links</h2>
                    </div>
                    {/* <p>Add links to let people connect with you.</p> */}
                    <div className="social-links-container">
                        {socialMediaMetaData?.map((item, index) => (
                            <button disabled={isInclude(item?.title)?.title === item?.title} className={`social-links`} key={index.toString()}
                                onClick={() => handleAddSocialMedia(item, index)}
                                style={{ borderColor: isInclude(item?.title)?.title === item?.title ? "#0000FF  " : "" }}
                            >
                                <img src={item.logo} style={{ width: 45, height: 45 }} />
                                {isInclude(item?.title)?.title === item?.title && <div className='social-link-overlay'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
                                        <rect x="1" y="1" width="43" height="42" rx="12" fill="#FFC832" fill-opacity="0.29" stroke="#F6CD66" stroke-width="2" />
                                        <path d="M19.9074 29.8167L12 21.9074L14.6352 19.2722L19.9074 24.5426L30.4481 14L33.0852 16.637L19.9074 29.813V29.8167Z" fill="#1F7D00" />
                                    </svg>
                                </div>}
                            </button>
                        ))}
                    </div>
                    {socialMediaLinks.map((item, index) => (
                        <>

                            <div className="row g-2 mb-3">
                                <div className="col-sm-6">
                                    <div className="d-flex align-center">
                                        <div className='social-links mb-0'>
                                            <img src={getSocialLogo(item.title).logo} className=" " style={{ width: 45, height: 45 }} />
                                        </div>
                                        <div className="form-group input-group-append">
                                            <div>
                                                <label className="form-label" htmlFor={item?.title} style={{ fontSize: 13 }}>{item?.title}</label>
                                                <input
                                                    onChange={(event) => handleSocialMediaLinks(event, index)}
                                                    name={'link'}
                                                    defaultValue={item?.link}
                                                    type="text" className="form-control me-2" id={'link'} placeholder={item?.title} />
                                            </div>
                                            <button onClick={() => handleSocialMediaLinkDelete(index)} style={{ marginTop: 24, marginLeft: 12 }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                    <circle cx="13" cy="13" r="13" fill="#6C1313" />
                                                    <path d="M9.88301 17.6783C9.78059 17.7807 9.65899 17.862 9.52516 17.9174C9.39133 17.9728 9.2479 18.0014 9.10304 18.0014C8.95819 18.0014 8.81475 17.9728 8.68093 17.9174C8.5471 17.862 8.4255 17.7807 8.32307 17.6783C8.22065 17.5759 8.1394 17.4543 8.08396 17.3204C8.02853 17.1866 8 17.0432 8 16.8983C8 16.7535 8.02853 16.61 8.08396 16.4762C8.1394 16.3424 8.22065 16.2208 8.32307 16.1184L16.1184 8.32418C16.2207 8.22176 16.3422 8.14049 16.476 8.08503C16.6098 8.02957 16.7531 8.001 16.8979 8.00095C17.0427 8.00089 17.1861 8.02937 17.3199 8.08473C17.4537 8.1401 17.5753 8.22128 17.6777 8.32363C17.7802 8.42598 17.8614 8.54751 17.9169 8.68127C17.9724 8.81503 18.0009 8.95841 18.001 9.10321C18.001 9.24801 17.9726 9.39141 17.9172 9.52521C17.8618 9.65901 17.7806 9.78059 17.6783 9.88302L9.88301 17.6783Z" fill="#FF2222" />
                                                    <path d="M8.32307 9.88301C8.11621 9.67615 8 9.39559 8 9.10304C8 8.8105 8.11621 8.52993 8.32307 8.32307C8.52993 8.11621 8.8105 8 9.10304 8C9.39559 8 9.67615 8.11621 9.88301 8.32307L17.6772 16.1184C17.7796 16.2207 17.8609 16.3422 17.9163 16.476C17.9718 16.6098 18.0004 16.7531 18.0004 16.8979C18.0005 17.0427 17.972 17.1861 17.9166 17.3199C17.8613 17.4537 17.7801 17.5753 17.6777 17.6777C17.5754 17.7802 17.4539 17.8614 17.3201 17.9169C17.1863 17.9724 17.043 18.0009 16.8982 18.001C16.7534 18.001 16.61 17.9726 16.4762 17.9172C16.3424 17.8618 16.2208 17.7806 16.1184 17.6783L8.32418 9.88301H8.32307Z" fill="#FF2222" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
                {/* <div className='con-sm-12 mt-5'>
                    <div className='card-form-section-title'>
                        <img src={PaintIcon} alt="" />
                        <h2 className='color-theme'>Select Colors</h2>
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className="form-group">
                        <label className="form-label" htmlFor='primary-color'>
                            Primary Color
                        </label>
                        <div className="color-picker-form-input">
                            <label htmlFor='primary-color'>
                                <ColorPicker
                                    presets={[
                                        {
                                            label: 'Recommended',
                                            colors: [
                                                '#000000',
                                                '#000000E0',
                                                '#000000A6',
                                                '#00000073',
                                                '#00000040',
                                                '#00000026',
                                                '#0000001A',
                                                '#00000012',
                                                '#0000000A',
                                                '#00000005',
                                                '#F5222D',
                                                '#FA8C16',
                                                '#FADB14',
                                                '#8BBB11',
                                                '#52C41A',
                                                '#13A8A8',
                                                '#1677FF',
                                                '#2F54EB',
                                                '#722ED1',
                                                '#EB2F96',
                                                '#F5222D4D',
                                                '#FA8C164D',
                                                '#FADB144D',
                                                '#8BBB114D',
                                                '#52C41A4D',
                                                '#13A8A84D',
                                                '#1677FF4D',
                                                '#2F54EB4D',
                                                '#722ED14D',
                                                '#EB2F964D',
                                            ],
                                        },
                                        // {
                                        //   label: 'Recent',
                                        //   colors: [],
                                        // },
                                    ]}
                                    value={primaryColor ? primaryColor : '#fff'}
                                    onChange={(value) => setPrimaryColor(value.toHexString())}
                                    className='form-control'
                                    placement="bottomRight"
                                    rootClassName='color-picker-root'
                                />
                                <div className="color-label">{primaryColor ? primaryColor : "#ffffff"}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className="form-group">
                        <label className="form-label" htmlFor='profile-text-color'>
                            Profile text color
                        </label>
                        <div className="color-picker-form-input">
                            <label htmlFor='profile-text-color'>
                                <ColorPicker
                                    presets={[
                                        {
                                            label: 'Recommended',
                                            colors: [
                                                '#000000',
                                                '#000000E0',
                                                '#000000A6',
                                                '#00000073',
                                                '#00000040',
                                                '#00000026',
                                                '#0000001A',
                                                '#00000012',
                                                '#0000000A',
                                                '#00000005',
                                                '#F5222D',
                                                '#FA8C16',
                                                '#FADB14',
                                                '#8BBB11',
                                                '#52C41A',
                                                '#13A8A8',
                                                '#1677FF',
                                                '#2F54EB',
                                                '#722ED1',
                                                '#EB2F96',
                                                '#F5222D4D',
                                                '#FA8C164D',
                                                '#FADB144D',
                                                '#8BBB114D',
                                                '#52C41A4D',
                                                '#13A8A84D',
                                                '#1677FF4D',
                                                '#2F54EB4D',
                                                '#722ED14D',
                                                '#EB2F964D',
                                            ],
                                        },
                                        // {
                                        //   label: 'Recent',
                                        //   colors: [],
                                        // },
                                    ]}
                                    value={profileTextColor ? profileTextColor : '#fff'}
                                    onChange={(value) => setProfileTextColor(value.toHexString())}
                                    className='form-control'
                                    placement="bottomRight"
                                    rootClassName='color-picker-root'
                                />
                                <div className="color-label">{profileTextColor ? profileTextColor : "#ffffff"}</div>
                            </label>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-sm-5">
                    <div className="form-group">
                        <label className="form-label" htmlFor='secondary-color'>
                            Secondary color
                        </label>
                        <div className="color-picker-form-input">
                            <label htmlFor='secondary-color'>
                                <div className="form-control me-2">{secondaryColor}</div>
                                <ColorPicker
                                    presets={[
                                        {
                                            label: 'Recommended',
                                            colors: [
                                                '#000000',
                                                '#000000E0',
                                                '#000000A6',
                                                '#00000073',
                                                '#00000040',
                                                '#00000026',
                                                '#0000001A',
                                                '#00000012',
                                                '#0000000A',
                                                '#00000005',
                                                '#F5222D',
                                                '#FA8C16',
                                                '#FADB14',
                                                '#8BBB11',
                                                '#52C41A',
                                                '#13A8A8',
                                                '#1677FF',
                                                '#2F54EB',
                                                '#722ED1',
                                                '#EB2F96',
                                                '#F5222D4D',
                                                '#FA8C164D',
                                                '#FADB144D',
                                                '#8BBB114D',
                                                '#52C41A4D',
                                                '#13A8A84D',
                                                '#1677FF4D',
                                                '#2F54EB4D',
                                                '#722ED14D',
                                                '#EB2F964D',
                                            ],
                                        },
                                        // {
                                        //   label: 'Recent',
                                        //   colors: [],
                                        // },
                                    ]}
                                    value={secondaryColor ? secondaryColor : '#fff'}
                                    onChange={(value) => setSecondaryColor(value.toHexString())}
                                    className='h-[40px] w-[40px]'
                                    placement="bottomRight"
                                />
                            </label>
                        </div>
                    </div>
                </div> */}
                <div className="col-sm-5">
                    <div className="form-group">
                        <label className="form-label" htmlFor='primary-color'>
                            Title font size
                        </label>
                        <div className='form-control-wrap flex'>
                            <div className='count-button'>
                                <button type='button' onClick={() => handleFontSizeChange("minus")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M2.48865 9.10359C2.1961 9.10359 1.91554 8.98738 1.70868 8.78052C1.50182 8.57366 1.38561 8.29309 1.38561 8.00055C1.38561 7.708 1.50182 7.42744 1.70868 7.22058C1.91554 7.01372 2.1961 6.89751 2.48865 6.89751L13.5121 6.89829C13.6569 6.89823 13.8003 6.92671 13.9341 6.98207C14.0679 7.03744 14.1894 7.11862 14.2919 7.22097C14.3943 7.32332 14.4756 7.44485 14.531 7.57861C14.5865 7.71237 14.6151 7.85575 14.6151 8.00055C14.6152 8.14535 14.5867 8.28875 14.5313 8.42255C14.476 8.55635 14.3948 8.67793 14.2924 8.78036C14.1901 8.88278 14.0685 8.96405 13.9348 9.01951C13.801 9.07497 13.6576 9.10354 13.5128 9.10359L2.48943 9.10281L2.48865 9.10359Z" fill="#ECB024" />
                                    </svg>
                                </button>
                                <div className='count-input'>{titleFontSize ? titleFontSize : 16}</div>
                                <button type='button' onClick={() => handleFontSizeChange("plus")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M9.1041 13.5127C9.1041 13.6575 9.07557 13.8009 9.02014 13.9348C8.9647 14.0686 8.88345 14.1902 8.78103 14.2926C8.6786 14.395 8.557 14.4763 8.42317 14.5317C8.28935 14.5872 8.14591 14.6157 8.00106 14.6157C7.8562 14.6157 7.71277 14.5872 7.57894 14.5317C7.44511 14.4763 7.32351 14.395 7.22109 14.2926C7.11866 14.1902 7.03741 14.0686 6.98198 13.9348C6.92655 13.8009 6.89801 13.6575 6.89801 13.5127L6.89879 2.48924C6.89874 2.34444 6.92721 2.20104 6.98258 2.06724C7.03795 1.93344 7.11912 1.81186 7.22148 1.70943C7.32383 1.607 7.44536 1.52574 7.57912 1.47028C7.71288 1.41482 7.85625 1.38625 8.00106 1.3862C8.14586 1.38614 8.28925 1.41462 8.42305 1.46998C8.55685 1.52535 8.67844 1.60653 8.78086 1.70888C8.88329 1.81124 8.96455 1.93276 9.02002 2.06652C9.07548 2.20028 9.10405 2.34366 9.1041 2.48846L9.1041 13.5127Z" fill="#ECB024" />
                                        <path d="M2.48865 9.10359C2.1961 9.10359 1.91554 8.98738 1.70868 8.78052C1.50182 8.57366 1.38561 8.29309 1.38561 8.00055C1.38561 7.708 1.50182 7.42744 1.70868 7.22058C1.91554 7.01372 2.1961 6.89751 2.48865 6.89751L13.5121 6.89829C13.6569 6.89823 13.8003 6.92671 13.9341 6.98207C14.0679 7.03744 14.1894 7.11862 14.2919 7.22097C14.3943 7.32332 14.4756 7.44485 14.531 7.57861C14.5865 7.71237 14.6151 7.85575 14.6151 8.00055C14.6152 8.14535 14.5867 8.28875 14.5313 8.42255C14.476 8.55635 14.3948 8.67793 14.2924 8.78036C14.1901 8.88278 14.0685 8.96405 13.9348 9.01951C13.801 9.07497 13.6576 9.10354 13.5128 9.10359L2.48943 9.10281L2.48865 9.10359Z" fill="#ECB024" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='con-sm-12'>
                    <h2 className='h2'>Advance Settings</h2>
                </div>
                <div className="col-sm-12">
                    <h6>Download on scan</h6>
                    <small>Directly download contact on scan.</small><br />
                    <div className="custom-control custom-switch mt-2">
                        <input checked={isDownload} onChange={handleDownloadButton} type="checkbox" className="custom-control-input " id="download-scan" />
                        <label className="custom-control-label" htmlFor="download-scan"></label><small>Enable</small>
                    </div>
                </div> */}
                {/* <div className="col-sm-7">
                    <h6>GPS location</h6>
                    <small>Track location of viewers.</small><br />
                    <div className="custom-control custom-switch mt-2">
                        <input checked={isLocation} onChange={handleLocationButton} type="checkbox" className="custom-control-input " id="gps-location" />
                        <label className="custom-control-label" htmlFor="gps-location"></label><small>Enable</small>
                    </div>
                </div> */}
                <div className='save_button_container justify-start mt-5 d-md-none d-block'>
                    <button className='cancel-button mb-5' onClick={() => navigate('/my-card')}>
                        <div>Cancel</div>
                    </button>
                    <button className='save-button mb-5 ml-6' onClick={handleSubmitButton}>
                        <div>Save</div>
                    </button>
                </div>
            </div>
        </div>
    )



});

export default CardDetails

