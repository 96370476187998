import React from 'react'

import Template1 from '../../assests/imges/template/card1.png'
import Template2 from '../../assests/imges/template/card2.png'
import Template3 from '../../assests/imges/template/card3.png'
import Template4 from '../../assests/imges/template/card4.png'
import Template5 from '../../assests/imges/template/card5.png'
import Template6 from '../../assests/imges/template/card6.png'
import Template7 from '../../assests/imges/template/card7.png'
import Template8 from '../../assests/imges/template/card7.png'

import Placeholder1 from '../../assests/imges/template/placeholder-1.png'
import Placeholder2 from '../../assests/imges/template/placeholder-2.png'
import Placeholder3 from '../../assests/imges/template/placeholder-3.png'
import Placeholder4 from '../../assests/imges/template/placeholder-4.png'
import Placeholder5 from '../../assests/imges/template/placeholder-5.png'
import Placeholder6 from '../../assests/imges/template/placeholder-6.png'
import Placeholder7 from '../../assests/imges/template/placeholder-6.png'
import Placeholder8 from '../../assests/imges/template/placeholder-4.png'
import Placeholder9 from '../../assests/imges/template/placeholder-9.png'



import Styles from './styles.module.scss'
import { CheckmarkIcon } from 'react-hot-toast'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules'
import { BiSolidChevronLeftCircle, BiSolidChevronRightCircle } from 'react-icons/bi'

const TData = [
    {
        id: 1,
        image: Template1,
    },
    // {
    //     id: 2,
    //     image: Template2,
    // },
    // {
    //     id: 3,
    //     image: Template3,
    // },

    {
        id: 5,
        image: Template5,
    },
    {
        id: 6,
        image: Template6,
    },
    {
        id: 7,
        image: Template7,
    },
    {
        id: 4,
        image: Template4,
    },
    {
        id: 4,
        image: Template8,
    },
]
const PData = [
    {
        id: 1,
        image: Placeholder1,
    },
    {
        id: 5,
        image: Placeholder5,
    },
    {
        id: 6,
        image: Placeholder6,
    },
    {
        id: 7,
        image: Placeholder2,
    },
    {
        id: 4,
        image: Placeholder4,
    },
    {
        id: 8,
        image: Placeholder8,
    },
    // {
    //     id: 9,
    //     image: Placeholder9,
    // },
]


export default function TemplateImage({ isSelectedCard, setSelectedCard }) {
    const handleSelectedCard = (cardId) => {setSelectedCard && setSelectedCard(cardId)}
    return (
        <div className={Styles.TemplateContainer}>
            <Swiper
                spaceBetween={10}
                slidesPerView={2}
                className='card-swiper'
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    680: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    717: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                }}
                navigation

                onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                modules={[Navigation]}


            >

                {PData?.map((item, index) => {
                    return <SwiperSlide>       
                        <div className={Styles.TemplateImage} key={index.toString()} onClick={() => handleSelectedCard(item?.id)}>
                            <img src={item?.image} alt={new Date()} />
                            {isSelectedCard === item?.id && <div className={Styles.CheckMark}>
                                <div className={Styles.CheckMarkIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                                        <path d="M17.2258 28.5397L9.31836 20.6304L11.9535 17.9952L17.2258 23.2656L27.7665 12.723L30.4035 15.3601L17.2258 28.536V28.5397Z" fill="#1F7D00" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 20.5C0 9.17841 9.17841 0 20.5 0C31.8216 0 41 9.17841 41 20.5C41 31.8216 31.8216 41 20.5 41C9.17841 41 0 31.8216 0 20.5ZM20.5 37.2727C18.2974 37.2727 16.1163 36.8389 14.0814 35.996C12.0464 35.1531 10.1974 33.9176 8.63989 32.3601C7.0824 30.8026 5.84693 28.9536 5.00402 26.9186C4.16111 24.8837 3.72727 22.7026 3.72727 20.5C3.72727 18.2974 4.16111 16.1163 5.00402 14.0814C5.84693 12.0464 7.0824 10.1974 8.63989 8.63989C10.1974 7.0824 12.0464 5.84693 14.0814 5.00402C16.1163 4.16111 18.2974 3.72727 20.5 3.72727C24.9484 3.72727 29.2146 5.49439 32.3601 8.63989C35.5056 11.7854 37.2727 16.0516 37.2727 20.5C37.2727 24.9484 35.5056 29.2146 32.3601 32.3601C29.2146 35.5056 24.9484 37.2727 20.5 37.2727Z" fill="#1F7D00" />
                                    </svg>
                                </div>
                            </div>}

                        </div>
                    </SwiperSlide>
                })}
            </Swiper>
        </div>
    )
}
