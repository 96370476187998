import React from 'react'
import Styles from '../landing/contact/styles.module.scss'
import NavbarZEEQR from '../landing/navbar/NavbarZEEQR'
import Footer from '../landing/footer/FooterZEEQR'
import { Link } from 'react-router-dom'
export default function Page404() {
    return (
        <div>
            <section>
                <div className={Styles.header_bg}>
                    <div className={Styles.header1}>

                        <NavbarZEEQR page={'contact'} />
                        <div className={Styles.about_banner} >
                            <div className={Styles.innerPagebanner}>
                                <div className={Styles.inner_banner_text}>
                                    <h2 className={Styles.blog_banner_text1}>404</h2>
                                    <h2 className={Styles.blog_banner_text2}><a href="/home">Home</a> - 404</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <section>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '60vh', maxWidth: 300, margin: '0 auto' }}>
                    <h3 className='mb-5'>Page not found</h3>
                    <button className={Styles._btn_}>
                        <Link to="/">Go to Home</Link>
                    </button>
                </div>
            </section>
            <Footer page={'contact'} />

        </div>
    )
}
