import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { errorStyle } from '../../../../utils/strings/Strings';
import { signIn } from './../../../../services/api/api-services';
import toast from 'react-hot-toast';
import { Checkbox } from "antd";
import { FiX } from "react-icons/fi";
import Styles from './styles.module.scss'
export default function Login({ isAuth }) {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [disable, setDisable] = useState(false)
    const [checkedS, setCheckedS] = useState(false);
    const navigate = useNavigate()
    const [loginError, setLoginError] = useState("");
    const [loginTypeError, setLoginTypeError] = useState(false);
    const [loginType, setLoginType] = useState("user");

    const onChangeS = (e) => {
        setCheckedS(e.target.checked);
        navigate("/signup");
    };

    const handleFormValidation = (event) => {
        event.preventDefault()
        if (email === "" || password === "" || loginType === "") {
            if (email === "") {
                setEmailError(true)
            } else {
                setEmailError(false)
            }
            if (password === "") {
                setPasswordError(true)
            } else {
                setPasswordError(false)
            }
            if (loginType === "") {
                setLoginTypeError(true)
            } else {
                setLoginTypeError(false)
            }
        } else {
            handleSubmitForm()
        }
    }

    const handleSubmitForm = (loginData) => {
        const data = { email, password }
        setDisable(true)
        const toastId = toast.loading('Logging...');
        signIn(data).then((response) => {
            if (response.err === false) {
                localStorage.setItem("token", response?.token);
                isAuth && isAuth()
                toast.dismiss(toastId);
                toast.success("Logging successfully!");
                setDisable(false)
            } else {
                setDisable(false)
                toast.error(response?.msg);
                toast.dismiss(toastId);
            }
        }).catch((err) => {
            console.log(err);
        })

    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        if (email !== "") {
            setEmailError(false)
        }
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        if (password !== "") {
            setPasswordError(false)
        }
    }
    const handleLoginTypeChange = (event) => {
        setLoginType(event.target.value);
        if (loginType !== "") {
            setLoginTypeError(false)
        }
    }


    const handlePasswordShow = () => setShowPassword(!showPassword)

    return (


        <div className={Styles.loginwrap}>
            <div className={Styles.loginForm}>

                <div className={Styles.signin}>
                    <h3 className='text-white'>Please signing to continue</h3>
                    <Link to={'/'} className={"text-white"}>
                        <FiX className={Styles.fix} />
                    </Link>

                </div>

                <div className={Styles.checkbox}>
                    <div >
                        <Checkbox checked={checkedS} onChange={onChangeS}>
                            <span style={{ color: "white" }}>I’m a New User</span>
                        </Checkbox>
                    </div>
                    <div className={Styles.check}>
                        <Checkbox checked={true}>
                            {" "}
                            <span style={{ color: "white" }}>
                                I’m an existing user{" "}
                            </span>{" "}
                        </Checkbox>
                    </div>
                </div>

                <form autoComplete='off' id='login-form' onSubmit={handleFormValidation}>
                    {/* <div className='form-group'>
                        <div className='form-control-wrap'>
                            <select className={Styles.form_control} style={loginTypeError ? errorStyle : {}} onChange={handleLoginTypeChange}>
                                <option selected disabled>Login type</option>
                                <option value={"user"}>User</option>
                                <option value={"corporate"}>Corporate</option>
                            </select>
                            {loginTypeError && <small className='invalid-feedback d-block'>Select login type</small>}
                        </div>
                    </div> */}
                    <div className='form-group'>
                        <div className='form-control-wrap'>
                            <input
                                style={emailError ? errorStyle : {}}
                                type='text'
                                className={Styles.form_control}
                                id='email'
                                placeholder='Official Email or Phone number'
                                onChange={handleEmailChange}
                                value={email}
                                autoComplete='off'
                                role='presentation'
                                autoCapitalize='none'
                            />
                            {emailError && <small className='invalid-feedback d-block'>Email must be filled out.</small>}
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className='form-control-wrap'>
                            <a onClick={handlePasswordShow} className='form-icon form-icon-right passcode-switch'>
                                <em className={`passcode-icon icon icon-show ni ${showPassword ? "ni-eye" : "ni-eye-off"}`} />
                            </a>
                            <input
                                role="presentation"
                                autoComplete="new-password"
                                style={passwordError ? errorStyle : {}}
                                type={showPassword ? "text" : 'password'}
                                className={Styles.form_control}
                                id='password'
                                onChange={handlePasswordChange}
                                placeholder='Enter your password'
                                value={password} />
                            {passwordError && <small className='invalid-feedback d-block'>Password must be filled out.</small>}
                        </div>
                    </div>
                    <div className='form-group'>
                        <div className={Styles.buttonWrapper}>
                        <button disabled={disable} type='submit' className={Styles.btn_primary}>
                            Login
                            </button>
                        </div>
                    </div>
                </form>

                <div className={Styles.forgot}>
                    <div className={Styles.text_white}>
                        <Link to="/forgot-password">
                            Forgot Password?
                        </Link>
                    </div>
                </div>

            </div>
        </div>


    )
}
