import React, { useEffect, useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import Styles from './styles.module.scss'
import SocialMediaSlider from '../components/social-media-slider/SocialMediaSlider'
import { EmailIcon, GloabIcon, NavigationIcon, PhoneIcon, ShareIcon } from '../components/Icons'
import { AVATAR, CARD_BG } from '../../../utils/images/Images'
import { isValidLink } from '../components/isValidUrl'
export default function CardTemplate({ data, onClickShareButton, onClickAddToContactButton, isDrawer }) {
    const [isFlipped, setCardFlip] = useState(false)
    const handleCardFlip = () => setCardFlip(!isFlipped)
    useEffect(() => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;

        document.getElementById("cardWrapper").style.setProperty('--vh', `${vh}px`);
        document.getElementById("cardWrapperback").style.setProperty('--vh', `${vh}px`);
    }, [])
    return (
        <div className={Styles.CardTemplate7Wrapper} >
            <ReactCardFlip isFlipped={isFlipped} containerStyle={{ height: "100%" }}>
                <div className={Styles.CardBackSide} >
                    <div className={Styles.CardTemplate7}>
                        <div className={isDrawer ? Styles.CardWrapperDrawer : Styles.CardWrapper} id='cardWrapper' >
                            <div className={Styles.CardContainer} onClick={handleCardFlip} >
                                <div className={Styles.TopBackground} style={{ backgroundImage: `url(${data?.logoImagePreview ? data?.logoImagePreview : CARD_BG})` }}>

                                    <div className={Styles.overlay}
                                    // style={{ backgroundColor: data?.secondaryColor }}
                                    />
                                    <div className={Styles.CardProfileImage}>
                                        <img className={Styles.ProfileImage} src={data?.userImagePreview ? data?.userImagePreview : AVATAR} />
                                    </div>
                                    <button className={Styles.ShareIcon} onClick={onClickShareButton && onClickShareButton}>
                                        <ShareIcon />
                                    </button>
                                </div>
                                <div className={Styles.CardBody}>
                                    <div className={Styles.CardContent}>
                                        <div className={Styles.CardContentTop}>
                                            <div style={{ width: "100%", paddingRight: 35 }}>
                                                <div className={Styles.CardText_P} style={{ fontSize: data?.titleFontSize + "px", color: data?.profileTextColor }}>{data?.firstName || data?.lastName ? (data?.prefix + " " + data?.firstName + " " + data?.lastName + " " + data?.suffix) : "Card Connect"}</div>
                                                <div className={Styles.CardText_p2} style={{ color: data?.primaryColor }}>{data?.designation ? data?.designation : "Connecting People"}
                                                    <span className={Styles.CardText_p3}>@{data?.company ? data?.company : "Company Name"}</span></div>
                                            </div>
                                            {data?.phones?.length > 0 && data?.phones[0].value !== "" && <div>
                                                {data.phones.map((i, index) => <a target='_blank' href={"tel:" + i.value} key={index.toString()} rel="noreferrer">
                                                    <PhoneIcon />
                                                </a>)}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={Styles.SaveButton}>
                                <button onClick={onClickAddToContactButton && onClickAddToContactButton} style={{ color: data?.profileTextColor }}>Save Card</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={Styles.CardTemplate7}>
                    <div className={isDrawer ? Styles.CardWrapperDrawer : Styles.CardWrapper} id='cardWrapperback'>
                        <div className={Styles.CardContainer} >
                            <div className={Styles.TopBackground}>
                                <div className={Styles.overlay}
                                // style={{ backgroundColor: data?.secondaryColor }}
                                />
                                <img src={data?.logoImagePreview} />

                                <button className={Styles.ShareIcon} onClick={onClickShareButton && onClickShareButton}>
                                    <ShareIcon />
                                </button>
                            </div>
                            <div className={Styles.CardBody} onClick={handleCardFlip}>
                                <div className={Styles.CardProfileImage}>
                                    <img className={Styles.ProfileImage} src={data?.userImagePreview ? data?.userImagePreview : AVATAR} />
                                </div>
                                <div className={Styles.CardContent}>
                                    <div className={Styles.CardContentTop}>
                                        <div style={{ width: "100%", paddingRight: 35 }}>
                                            <div className={Styles.CardText_P} style={{ fontSize: data?.titleFontSize + "px", color: data?.profileTextColor }}>{data?.firstName || data?.lastName ? (data?.prefix + " " + data?.firstName + " " + data?.lastName + " " + data?.suffix) : "Card Connect"}</div>
                                            <div className={Styles.CardText_p2} style={{ color: data?.primaryColor }}>{data?.designation ? data?.designation : "Connecting People"}
                                                <span className={Styles.CardText_p3}>@{data?.company ? data?.company : "Company Name"}</span></div>
                                        </div>
                                        {data?.phones?.length > 0 && data?.phones[0].value !== "" && <div>
                                            {data.phones.map((i, index) => <a target='_blank' href={"tel:" + i.value} key={index.toString()} rel="noreferrer">
                                                <PhoneIcon />
                                            </a>)}

                                        </div>}
                                    </div>
                                    <div className={Styles.CardScrollWrapper}>
                                        <div className={Styles.CardScrollContainer}>
                                            {data?.location && <a className={Styles.AddressContainer} target='_blank' href={"https://maps.google.com/?q=" + data?.location.replaceAll(",", "")} rel="noreferrer">
                                                <div>
                                                    <NavigationIcon />
                                                </div>

                                                <p>{data?.location}</p>
                                            </a>
                                            }
                                            <div className={Styles.AboutContainer}>
                                                <p style={{ color: data?.primaryColor }}>{data?.summary}</p>
                                            </div>

                                            <div className={Styles.CardLinksContainer}>
                                                {data?.customLinks?.length > 0 && data?.customLinks[0].value !== "" && <div >
                                                    {data.customLinks.map((i, index) => <a target='_blank' href={isValidLink(i.value)} key={index.toString()} rel="noreferrer" className={Styles.CardLinkRow}>
                                                        <GloabIcon />
                                                        <p>{i.value}</p>
                                                    </a>)}
                                                </div>}
                                                {data?.website?.length > 0 && data?.website[0].value !== "" && <div>
                                                    {data.website.map((i, index) => <a target='_blank' href={isValidLink(i.value)} key={index.toString()} rel="noreferrer" className={Styles.CardLinkRow}>
                                                        <GloabIcon />
                                                        <p>{i.value}</p>
                                                    </a>)}
                                                </div>}
                                                {data?.emails?.length > 0 && data?.emails[0].value !== "" && <div>
                                                    {data.emails.map((i, index) => <a target='_blank' href={"mailto:" + i.value} key={index.toString()} rel="noreferrer" className={Styles.CardLinkRow}>
                                                        <EmailIcon />
                                                        <p>{i.value}</p>
                                                    </a>)}
                                                </div>}
                                            </div>
                                            <div>
                                                <SocialMediaSlider data={data} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={Styles.SaveButton}>
                            <button onClick={onClickAddToContactButton && onClickAddToContactButton} style={{ color: data?.profileTextColor }}>Save Card</button>
                        </div>
                    </div>
                </div>
            </ReactCardFlip>
        </div>
    )
}
