import { createSlice } from "@reduxjs/toolkit";


export const ProfileSlice = createSlice({
    name: "profile",
    initialState: {
        breadcrumb: null
    },
    reducers: {
        profile: (state, action) => {
            state.profile = action.payload;
        },
    },
})



export const { profile } = ProfileSlice.actions;

export const selectProfile = (state) => state.profile.profile;

export default ProfileSlice.reducer;

