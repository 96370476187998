export const SOCIAL_MEDIA = {
    Facebook: 1,
    Instagram: 2,
    Twitter: 3,
    LinkedIn: 4,
    TikTok: 5,
    SnapChat: 6,
    Whatsapp: 7,
    Telegram: 8,
    Vimeo: 9,
    Youtube: 10,
    Wistia: 11,
    Twitch: 12,
    Discord: 13,
    Pinterest: 14,
    Yelp: 15,
    Paypal: 16,
    Venmo: 17,
    CashApp: 18,
    Calendly: 19,
    Shopify: 20,
    Github: 21,
    Dribbble: 22,
    Behance: 23,
    CustomUrl: 24,
    Wikipedia: 25,
    Paytm: 26,
    Upi: 27,
    Chatgpt: 28,
    Google: 29,




}


export const socialMediaNamesArray = ['facebook', 'instagram', 'twitter', 'linkedin', 'tiktok', 'snapchat', 'whatsapp', 'telegram', 'vimeo', 'youtube', 'wistia', 'twitch', 'discord', 'pinterest', 'yelp', 'paypal', 'venmo', 'cashapp', 'calendly', 'shopify', 'github', 'dribbble', 'behance', 'custom_url', 'Wikipedia', 'Paytm', 'Upi', 'Chatgpt', 'Google']


export const socialMediaMetaData = [
    {
        id: 1,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/wgu1uyyzwjh7krg6oo4z.png",
        title: 'Facebook',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-facebook-4_tj2psc.svg"
    },
    {
        id: 2,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/yseji32pybmfcygiqhes.png",
        title: 'Instagram',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-instagram-11_edvxho.svg"
    },
    {
        id: 3,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1693314179/twitter-new_iopewa.png",
        title: 'Twitter',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1693302567/twitter_cblijy.png"
    },
    {
        id: 4,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/zpty4iyd35hpns5ciwn6.png",
        title: 'LinkedIn',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-linkedin-3_yidwlg.svg"
    },
    {
        id: 5,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/rzctarbbiajqhvytciqq.png",
        title: 'TikTok',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/tiktok_ax4kmq.svg"
    },
    {
        id: 6,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/dnbpdtucsaucr6c8jvri.png",
        title: 'Snapchat',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-snapchat-1_fwetqm.svg"
    },
    {
        id: 7,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/vth5rytxkvc39foxv59e.png",
        title: 'Whatsapp',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966477/iconmonstr-whatsapp-1_jvetgp.svg"
    },
    {
        id: 8,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/onvjx5ole5iuzdvjkmwd.png",
        title: 'Telegram',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966476/iconmonstr-telegram-1_wkoade.svg"
    },
    {
        id: 9,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/frp7yf43efx0mqblcyok.png",
        title: 'Vimeo',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966477/iconmonstr-vimeo-1_rh9osz.svg"
    },
    {
        id: 10,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/rcebhhnu2xfrh13pad70.png",
        title: 'Youtube',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966477/iconmonstr-youtube-6_oogiig.svg"
    },
    {
        id: 11,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/h4vbtwntsnmtneqk0gnb.png",
        title: 'Wistia',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/wistia_mrkhyw.svg"
    },
    {
        id: 12,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/hurwb0wbtfnbvrvp3rb5.png",
        title: 'Twitch',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966476/iconmonstr-twitch-4_ehjtpw.svg"
    },
    {
        id: 13,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309311/xo8jcqdzioe6cbdu0rcn.png",
        title: 'Discord',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-discord-1_ryapt6.svg"
    },
    {
        id: 14,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/x8v7fkpldsw9yh2vsoax.png",
        title: 'Pinterest',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-pinterest-1_wtcsj5.svg"
    },
    {
        id: 15,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/jvvycr4859etuydzl6gx.png",
        title: 'Yelp',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/yelp_adkmnb.svg"
    },
    {
        id: 16,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/on0dfbufj2nbzzwinmda.png",
        title: 'Paypal',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966475/iconmonstr-paypal-1_xfd3oz.svg"
    },
    {
        id: 17,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/pgjzkcf3sjd5uqrswdbh.png",
        title: 'Venmo',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/logo-venmo_lettae.svg"

    },
    {
        id: 18,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/ksoulgpvqkgenbnvybgt.png",
        title: 'CashApp',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/cashapp_zhcsba.svg"
    },
    // {
    //     id: 19,
    //     logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/jhitxe1h7ztuqk2a2yu3.png",
    //     title: 'Calendly'
    // },
    {
        id: 20,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/iwis3c9mg1essjd5t1v8.png",
        title: 'Shopify',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967469/shopify_lzhh1q.svg"
    },
    {
        id: 21,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/zniuwkqpi65lxtsr8ykj.png",
        title: 'Github',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-github-1_jdkvqn.svg"
    },
    {
        id: 22,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/yt9o9hxfroryqdhgar8i.png",
        title: 'Dribbble',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-dribbble-1_czmdq0.svg"
    },
    {
        id: 23,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/tueqxmfwg0qxyg37yf8w.png",
        title: 'Behance',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692966473/iconmonstr-behance-4_fe9hw7.svg"
    },
    {
        id: 24,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1684309312/n3irrmistg4nnkhgrrqg.png",
        title: 'Custom URL',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1692967597/globe_oxav40.svg"
    },
    {
        id: 25,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1693999783/wikipidea_djqzwa.png",
        title: 'Wikipedia',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1693302567/wikipedia_buuj20.png"
    },
    {
        id: 26,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1693999783/paytm_m7pf1g.png",
        title: 'Paytm',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1693307375/paytm_vtraqr.png"
    },
    {
        id: 27,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1693999783/Upi_iq4ntq.png",
        title: 'BHIM UPI',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1693307375/bhim-upi_jrotcf.png"
    },
    {
        id: 28,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1693999783/chat-gpt_zdf6wi.png",
        title: 'ChatGPT',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1693302568/ChatGPT_mf6qer.png"
    },
    {
        id: 29,
        logo: "https://res.cloudinary.com/cardscanner/image/upload/v1693999783/google_remrqh.png",
        title: 'Google',
        svg: "https://res.cloudinary.com/cardscanner/image/upload/v1693302567/google_rxwgvj.png"
    },

]

// export const socialMediaMetaData = [
//     {
//         id: 1,
//         logo: require('../../assets/images/vcard-plus-social-icons/facebook_icon.png'),
//         title: 'Facebook'
//     },
//     {
//         id: 2,
//         logo: require('../../assets/images/vcard-plus-social-icons/instagram_icon.png'),
//         title: 'Instagram'
//     },
//     {
//         id: 3,
//         logo: require('../../assets/images/vcard-plus-social-icons/twitter_icon.png'),
//         title: 'Twitter'
//     },
//     {
//         id: 4,
//         logo: require('../../assets/images/vcard-plus-social-icons/linkedin_icon.png'),
//         title: 'LinkedIn'
//     },
//     {
//         id: 5,
//         logo: require('../../assets/images/vcard-plus-social-icons/tiktok_icon.png'),
//         title: 'TikTok'
//     },
//     {
//         id: 6,
//         logo: require('../../assets/images/vcard-plus-social-icons/snapchat_icon.png'),
//         title: 'Snapchat'
//     },
//     {
//         id: 7,
//         logo: require('../../assets/images/vcard-plus-social-icons/whatsapp_icon.png'),
//         title: 'Whatsapp'
//     },
//     {
//         id: 8,
//         logo: require('../../assets/images/vcard-plus-social-icons/telegram.png'),
//         title: 'Telegram'
//     },
//     {
//         id: 9,
//         logo: require('../../assets/images/vcard-plus-social-icons/Vimeo.png'),
//         title: 'Vimeo'
//     },
//     {
//         id: 10,
//         logo: require('../../assets/images/vcard-plus-social-icons/youtube_icon.png'),
//         title: 'Youtube'
//     },
//     {
//         id: 11,
//         logo: require('../../assets/images/vcard-plus-social-icons/Vistea.png'),
//         title: 'Wistia'
//     },
//     {
//         id: 12,
//         logo: require('../../assets/images/vcard-plus-social-icons/Twitch.png'),
//         title: 'Twitch'
//     },
//     {
//         id: 13,
//         logo: require('../../assets/images/vcard-plus-social-icons/Discord.png'),
//         title: 'Discord'
//     },
//     {
//         id: 14,
//         logo: require('../../assets/images/vcard-plus-social-icons/pinterest_icon.png'),
//         title: 'Pinterest'
//     },
//     {
//         id: 15,
//         logo: require('../../assets/images/vcard-plus-social-icons/Yelp.png'),
//         title: 'Yelp'
//     },
//     {
//         id: 16,
//         logo: require('../../assets/images/vcard-plus-social-icons/Paypal.png'),
//         title: 'Paypal'
//     },
//     {
//         id: 17,
//         logo: require('../../assets/images/vcard-plus-social-icons/Venmo.png'),
//         title: 'Venmo'
//     },
//     {
//         id: 18,
//         logo: require('../../assets/images/vcard-plus-social-icons/Cashapp.png'),
//         title: 'CashApp'
//     },
//     {
//         id: 19,
//         logo: require('../../assets/images/vcard-plus-social-icons/Calendly.png'),
//         title: 'Calendly'
//     },
//     {
//         id: 20,
//         logo: require('../../assets/images/vcard-plus-social-icons/shopify.png'),
//         title: 'Shopify'
//     },
//     {
//         id: 21,
//         logo: require('../../assets/images/vcard-plus-social-icons/Github.png'),
//         title: 'Github'
//     },
//     {
//         id: 22,
//         logo: require('../../assets/images/vcard-plus-social-icons/Dribbble.png'),
//         title: 'Dribbble'
//     },
//     {
//         id: 23,
//         logo: require('../../assets/images/vcard-plus-social-icons/Behance.png'),
//         title: 'Behance'
//     },
//     {
//         id: 24,
//         logo: require('../../assets/images/vcard-plus-social-icons/URL.png'),
//         title: 'Custom URL'
//     },
// ]
