import React, { useState } from 'react';
import Styles from './styles.module.scss'
import { AiOutlineCheckCircle } from 'react-icons/ai'
function PriceCard({ data, index }) {
    const { name, price, features } = data;
    const [selectedCard, setSelectedCard] = useState(2);

    const handleSelectCard = (index) => {
        setSelectedCard(index);
    };
    // console.log(selectedCard);
    // console.log(index);

    return (
        <div className={selectedCard === index ? Styles.selected_price_card_container : Styles.price_card_container}>
            <div className={Styles.price_card_content_wrapper}>
                <div className={Styles.card_top_container}>
                    <h2>{name}</h2>
                    <div><span className={Styles.price_text}>$19</span><span>/Month</span></div>
                    <p>Increase follwer for small business value for money</p>
                </div>
                <div className={Styles.card_bottom_container}>
                    {
                        features.map((item) =>
                            <div className={Styles.card_items}><AiOutlineCheckCircle className={selectedCard === index ? Styles.selected_card_icons : Styles.card_icons} size={18} /><h2>{item}</h2></div>
                        )
                    }

                    <div className={selectedCard === index ? Styles.selected_card_btn : Styles.card_btn}>
                        <button onClick={() => handleSelectCard(index)}>Choose Plan</button>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default PriceCard;