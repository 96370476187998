import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'

export default class AuthLayout extends Component {
    render() {
        return (
            <div className="nk-app-root">
                <div className="nk-main ">
                    <div className='nk-wrap nk-wrap-nosidebar'>
                        <div className='nk-content'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
