import { forwardRef, useEffect, useState } from 'react';
import 'react-phone-number-input/style.css'
import { getCountries } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input';
import labels from 'react-phone-number-input/locale/en'
import PhoneInput from 'react-phone-number-input';


export default function MultipleInputs({ data, setData, dataObject, label, name, isMultiple, isPhoneInput }) {
    const [error, setError] = useState("")
    const handleMultiple = (event, index) => {
        let { name, value } = event.target;
        // let jasper = Object.assign({}, ...data);
        // let onChangeValue = [...data];
        // jasper[index][name] = value;
        // onChangeValue[index][name] = value;

        const newState = data.map((obj, i) => {


            if (index === i && name !== "type") {
                return { ...obj, value: value, };
            }
            if (index === i && name === "type") {
                return { ...obj, type: value, };
            }
            return obj;
        });
        setData(newState)

    }

    const handleAdd = () => {
        setData((prev) => [...prev, dataObject])
    }
    const handleDelete = (i) => {
        if (data.length === 1) {
            setError("At least one field must be visible or the text needs to be removed")
            return
        }
        const deleteVal = [...data];

        deleteVal.splice(i, 1);
        setData(deleteVal)
    }

    return (
        <div className={isMultiple ? "col-sm-12" : "col-sm-6"}>
            <label className="form-label" htmlFor={label}>{label}</label>
            {data?.map((item, index) => (isPhoneInput ? <PhoneInput
                defaultCountry='IN'
                value={item?.value}
                className='phone-input-class'
                onChange={(value) => handleMultiple({ target: { name, value, } }, index)}
                countrySelectProps={{
                    arrowComponent: () => <div className='ml-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="5" viewBox="0 0 9 3" fill="none">
                            <path d="M8.29739 0L4.60573 3L0.914062 0H8.29739Z" fill="#FFB94E" />
                        </svg>
                    </div>
                }}
            /> :
                <div div className={`row g-2 ${isMultiple && 'mb-2'} align-items-center`} key={index.toString()} >
                    <div className={isMultiple ? "col-11" : "col-sm-12"}>
                        <div className="form-group">
                            <div className="form-control-wrap">
                                <input
                                    onChange={(event) => handleMultiple(event, index)}
                                    name={name}
                                    autocapitalize="none"
                                    value={item?.value}
                                    type="text" className="form-control"
                                    id={label} placeholder={label} />
                            </div>
                        </div>
                    </div>
                    {isMultiple && <div className="col-1">
                        <div className="form-group input-group-append">
                            {/* <select name="type" className="form-control me-2" onChange={(event) => handleMultiple(event, index)}>
                                <option value={"work"}>Work</option>
                                <option value={"office"}>Office</option>
                                <option value={"other"}>Other</option>
                            </select> */}
                            <button onClick={() => handleDelete(index)} disabled={data?.length === 1} style={{ opacity: data?.length === 1 ? 0.5 : 1 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                    <circle cx="13" cy="13" r="13" fill="#6C1313" />
                                    <path d="M9.88301 17.6783C9.78059 17.7807 9.65899 17.862 9.52516 17.9174C9.39133 17.9728 9.2479 18.0014 9.10304 18.0014C8.95819 18.0014 8.81475 17.9728 8.68093 17.9174C8.5471 17.862 8.4255 17.7807 8.32307 17.6783C8.22065 17.5759 8.1394 17.4543 8.08396 17.3204C8.02853 17.1866 8 17.0432 8 16.8983C8 16.7535 8.02853 16.61 8.08396 16.4762C8.1394 16.3424 8.22065 16.2208 8.32307 16.1184L16.1184 8.32418C16.2207 8.22176 16.3422 8.14049 16.476 8.08503C16.6098 8.02957 16.7531 8.001 16.8979 8.00095C17.0427 8.00089 17.1861 8.02937 17.3199 8.08473C17.4537 8.1401 17.5753 8.22128 17.6777 8.32363C17.7802 8.42598 17.8614 8.54751 17.9169 8.68127C17.9724 8.81503 18.0009 8.95841 18.001 9.10321C18.001 9.24801 17.9726 9.39141 17.9172 9.52521C17.8618 9.65901 17.7806 9.78059 17.6783 9.88302L9.88301 17.6783Z" fill="#FF2222" />
                                    <path d="M8.32307 9.88301C8.11621 9.67615 8 9.39559 8 9.10304C8 8.8105 8.11621 8.52993 8.32307 8.32307C8.52993 8.11621 8.8105 8 9.10304 8C9.39559 8 9.67615 8.11621 9.88301 8.32307L17.6772 16.1184C17.7796 16.2207 17.8609 16.3422 17.9163 16.476C17.9718 16.6098 18.0004 16.7531 18.0004 16.8979C18.0005 17.0427 17.972 17.1861 17.9166 17.3199C17.8613 17.4537 17.7801 17.5753 17.6777 17.6777C17.5754 17.7802 17.4539 17.8614 17.3201 17.9169C17.1863 17.9724 17.043 18.0009 16.8982 18.001C16.7534 18.001 16.61 17.9726 16.4762 17.9172C16.3424 17.8618 16.2208 17.7806 16.1184 17.6783L8.32418 9.88301H8.32307Z" fill="#FF2222" />
                                </svg>
                            </button>
                        </div>
                    </div>}
                    {error && <div className='col-lg-12'>
                        <small className='invalid-feedback d-block'>{error}</small>
                    </div>}                    
                </div>
            ))
            }

            {isMultiple && <div className='row mt-2'>
                <div className="col-sm-12">
                    <button className="simple-button" onClick={handleAdd}>
                        <em className='icon ni ni-plus' style={{ fontSize: 13 }} />&nbsp;Add More
                    </button>
                </div>
            </div>
            }
        </div >
    )
}
