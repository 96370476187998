import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';
import MarkerIcon from './marker.svg'
import { GOOGLE_MAP_KEY } from '../../../services/api/api-path';
class GoogleMapContainer extends Component {


    onMapClicked = (event) => {
        console.log(this.props.markers);
    }



    render() {
        function removeDuplicatesAndCountOccurrences(arr) {
            const countMap = {};
            const uniqueArray = [];

            // Count occurrences and create a unique array
            for (const item of arr) {
                const key = JSON.stringify(item);
                countMap[key] = (countMap[key] || 0) + 1;
                // if (countMap[key] === 1) {
                //     uniqueArray.push(item);
                // }
            }

            // Print occurrences
            for (const key in countMap) {
                const item = JSON.parse(key);
                item.count = countMap[key]
                uniqueArray.push(item)
            }

            return uniqueArray;
        }

        const uniqueArray = removeDuplicatesAndCountOccurrences(this.props.markers);
        const MARKER_SIZE = (count) => Math.min(80, 52 * count)
        return (
            <Map google={this.props.google} zoom={4}
                containerStyle={{
                    width: '100%',
                    height: 591,
                    position: 'relative'
                }}
                onClick={this.onMapClicked}
                onTilesloaded={(event) => console.log(event)}
                centerAroundCurrentLocation={true}
                center={this.props.markers[this.props.markers.length - 1]}
                isFractionalZoomEnabled
            >
                {uniqueArray?.map((item, index) => {
                    return <Marker
                        position={item}
                        onClick={this.onMarkerClick}
                        icon={{
                            url: MarkerIcon,
                            scaledSize: { width: MARKER_SIZE(item.count), height: MARKER_SIZE(item.count) }                            
                        }}                        
                        label={{
                            text: item?.count > 1 && item?.count.toString(),
                            className: "marker-label",                            
                        }}  
                        
                        key={index.toString()}
                        name={'Current location'} />                                                
                })}               
            </Map>
        )
    }
}


export default GoogleApiWrapper({
    apiKey: GOOGLE_MAP_KEY
})(GoogleMapContainer)